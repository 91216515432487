import React from 'react';
import { Location, Title, Text, Sticky } from './style';

const Evento = () => {
    return (
        <Sticky>
            <div>
            <Title>
                Mercado del conocimiento y No-Conocimiento Útil. Licencia # 10
            </Title>
            <Location>
                <b>Barrancabermeja</b>
                <br/>
                2 de diciembre de 2021
                <br/>
                (4:00 a 9:15 p.m.)
            </Location>
            <Text>
                Evento presencial y virtual.
                Espacio teatral Corporación 
                A VER TEATRO.
                Carrera 11 con calle 52 esquina
                Barrio Olaya Herrera.
            </Text>
            </div>
        </Sticky>
    )
}

export default Evento;