import styled from "styled-components";

export const Intro = styled.p`

`;

export const Title = styled.h3`
text-transform:uppercase;
font-weight: 500;
font-size: 1.56em;
width: 70%
`;

export const Column = styled.div`
`;

export const SectionContainer = styled.section`
font-family: 'Source Sans Pro';
`;


export const Imagen = styled.img`
max-width: 100%;
height:auto;
`;

export const Grid = styled.div`
display: grid;
grid-gap: 3vw;
img{
    max-width: 100%;
}
li{
    margin: 10px 0;
}
@media (max-width: 430px) {
    grid-template-columns: 1fr; 
}
@media (min-width: 430px) {
    grid-template-columns: auto auto;
}
`;
