let arr = [
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "21",
      "bookedOn": "26/11/2021 12:06:34",
      "email": "wendy.plata@unipaz.edu.co",
      "name": "Wendy Julieth Plata Diaz",
      "region": "Barrancabermeja",
      "tel": "3143716529",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "18",
      "bookedOn": "25/11/2021, 3:55:13 p. m.",
      "email": "hernando_jose14@hotmail.com",
      "name": "Hernando José Caraballo Ramirez",
      "region": "Barrancabermeja",
      "tel": "3223991697",
      "timeBooked": " 19:20"
    },
    "email": "ameisel@uninorte.edu.co / ameiselroca@gmail.com\n\nagranger@uninorte.edu.co",
    "expert": "Adolfo Meisel Roca",
    "id": 7801835583788816,
    "phone": "315 7317025",
    "profile": "Economista. Rector de la Universidad del Norte de Barranquilla",
    "region": "Caribe e insular",
    "socialMedia": "x",
    "table": 12,
    "theme": "Las desigualdades económicas entre las regiones de Colombia son muy grandes y se acentúan con el paso del tiempo. Diversos factores hacen pensar que por sí solo el mercado no se encargará de eliminar esa situación para que se dé una convergencia en la prosperidad material de las diferentes regiones del territorio nacional. Por tal motivo se necesitan políticas públicas encaminadas a reducir las desigualdades económicas en Colombia. En parte, esas políticas no existen debido a que las regiones más rezagadas, como las costas Pacífica y Caribe, no han tenido mucha injerencia en la orientación de la política económica nacional. Me interesa conversar con ustedes al respecto de esta situación.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "¿Por qué se necesita una política económica regional en Colombia?",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340214\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340214').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340214&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "19",
      "bookedOn": "29/11/2021 15:03:19",
      "email": "karenguevararojas@gmail.com",
      "name": "Karen Julieth Guevara Rojas",
      "region": "Bógota",
      "tel": "3134004984",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "37",
      "bookedOn": "25/11/2021 20:39:35",
      "email": "Marcohrdez@hotmail.com",
      "name": "Marco Tulio Hernandez Espinosa",
      "region": "Barrancabermeja ",
      "tel": "3016265793",
      "timeBooked": " 16:40"
    },
    "email": "elbmx08@hotmail.com",
    "expert": "Alan Martínez*",
    "id": 6524070221929585,
    "phone": "300 8130462",
    "profile": "Deportista de BMX. Gestor social en el Carmen de Bolívar, miembro y directivo de la plataforma juvenil del mismo municipio",
    "region": "Caribe e insular",
    "socialMedia": "",
    "table": 3,
    "theme": "El BMX ha sido una herramienta de construcción de paz que nos ha ayudado a salir adelante, contribuyendo a superar el conflicto armado y permitiendo que los jóvenes no seamos estigmatizados: nos ha ofrecido la oportunidad de cambiar nuestra historia. Les contaré cómo el Carmen Bike Co nació en el 2015 en las calles del Carmen de Bolívar a través de soluciones colectivas. Por ser un deporte nuevo existen dificultades a la hora de practicarlo: no se tienen instructores ni escenarios deportivos adecuados. Aún así, con pocas herramientas y con nuestras propias manos, comenzamos a construir pistas de BMX. A pesar de que hemos avanzado en erigir los lugares soñados para la práctica, re-apropiándonos de determinados espacios, hemos sido desplazados de los terrenos porque “aparecen” sus propietarios. ¿Se suben a pedalear conmigo para compartir este sueño?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "El BMX y los deportes extremos como constructores de ciudadanías, paz y territorio",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34025\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34025').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34025&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "20",
      "bookedOn": "26/11/2021 20:45:17",
      "email": "lina-marcela3@hotmail.com",
      "name": "Lina Marcela ",
      "region": "Santander",
      "tel": "3212247091",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "19",
      "bookedOn": "27/11/2021 00:23:01",
      "email": "villa0515@hotmail.com",
      "name": "José Samuel Ariza Villa ",
      "region": "Barrancabermeja",
      "tel": "3024546182",
      "timeBooked": " 16:40"
    },
    "email": "pontona@gmail.com",
    "expert": "Amparo Pontón*",
    "id": 2728755476719242,
    "phone": "310 2305260",
    "profile": "Periodista. Selfie-columnista",
    "region": "Caribe e insular",
    "socialMedia": "x",
    "table": 12,
    "theme": "La comunidad raizal de las islas de Providencia y Santa Catalina nunca ha sido objeto de mirada por parte de los medios de comunicación del continente. De hecho, la prensa escrita, la radio y la televisión han estado ausentes en estas islas.\nEl huracán Iota, que arrasó el territorio insular el 16 de noviembre del 2020, logró que esa comunidad olvidada se volcara sobre las redes sociales y empezara a ejercer lo que hoy se conoce como periodismo de servicio, cuyo único fin es mantener a la población al tanto de los pormenores de su futuro inmediato de tal forma que se puedan tomar decisiones comunitarias y, con ellas, asegurar la sobrevivencia de la etnia y de su diario vivir. Conversaremos sobre mi experiencia como periodista de servicio y sus posibilidades de réplica. Les espero con sus ideas, inquietudes y preguntas.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "La voz de los invisibles: periodismo de servicio",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340214\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340214').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340214&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "mdunen@gmail.com",
    "expert": "Dunen Muelas Izquierdo",
    "id": 1150346565941070,
    "phone": "318 2575411",
    "profile": "Politóloga. Investigadora y lideresa indígena arahuaca, perteneciente a la Red Colombiana de Mujeres Indígenas en la Academia –RedColMIA–",
    "region": "Caribe e insular",
    "socialMedia": "",
    "table": 2,
    "theme": "A partir de las trayectorias de dos mujeres indígenas del pueblo Arhuaco y Kankuamo presentaré la manera en la que la migración esta asociada al trabajo de cuidado. Abordaré la migración en dos momentos: hacia adentro y hacia fuera. La migración ‘hacia adentro’ tiene que ver con todo el proceso de etnización y relaciones de género al interior de sus comunidades durante su niñez. Y la migración ‘hacia fuera’ permite comprender el momento en el que migran hacia las ciudades por razones del conflicto armado y su relación con el trabajo doméstico. La reconstrucción de sus historias nos permite entender el cuidado desde sus voces y la forma en que superan todas las situaciones que ponen en riesgo sus derechos. Sus relatos revelan una poderosa agencia a través de la cual logran subvertir las relaciones de poder, a partir de sus conocimientos ancestrales.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Mujeres arahuacas en migración",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34024\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34024').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34024&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "edilmaloperenaplata@hotmail.com",
    "expert": "Edilma Loperena Plata",
    "id": 5010445255153806,
    "phone": "300 5096028",
    "profile": "Trabaja en la empresa indígena Dusakawi EPSI. Lidera procesos con mujeres Wiwa, delegada por los cabildos gobernadores para auditorías internas",
    "region": "Caribe e insular",
    "socialMedia": "",
    "table": 5,
    "theme": "Me interesa hablar sobre la necesidad de Dusakawi EPSI, un modelo de atención en salud para las poblaciones indígenas de una parte de la Sierra Nevada y la Serranía del Perijá que conjuga la medicina occidental y la tradicional. La institución nace a raíz del difícil acceso que tienen estas poblaciones a los centros hospitalarios, el mal manejo de los recursos y la deficiencia en la prestación de servicios de salud por parte del Estado. Estas dificultades de accesibilidad se acentúan en muchos grupos de mujeres, pues existen problemáticas relacionadas con las violencias basadas en género. Por ello, la especificidad de este enfoque en salud indígena se hace necesaria ya que muchas tradiciones y saberes ancestrales se heredan de forma matrilineal. Compartiré con ustedes cómo estamos liderando los procesos de mujeres dentro de la Organización Wiwa Yugumaiun Bunkuanarrua Tayrona –OWYBT–.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La inequidad en los procesos de salud de las mujeres indígenas",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34027\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34027').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34027&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "53",
      "bookedOn": "25/11/2021 16:18:27",
      "email": "claudia.alvarezleon@campusucc.edu.co",
      "name": "Claudia Patricia Alvatrez Leon",
      "region": "Barrancabermeja",
      "tel": "3016906614",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "31",
      "bookedOn": "25/11/2021 16:07:25",
      "email": "lays0311@hotmail.com",
      "name": "LAYS YELISA POTES",
      "region": "CORREGIMIENTO EL LLANITO",
      "tel": "3138167708",
      "timeBooked": " 16:40"
    },
    "email": "myawad@gmail.com",
    "expert": "Myriam Awad*",
    "id": 2461076946708167,
    "phone": "313 5725419",
    "profile": "Comunicadora social, experta en desarrollo territorial y formulación participativa de proyectos. Asociación Tierra de Esperanza y la Corporación de Investigación y Acción Social y Económica –CIASE–",
    "region": "Caribe e insular",
    "socialMedia": "x\nEstaré atenta a la llamada de las personas de Atarraya.",
    "table": 14,
    "theme": "He trabajado con diversas comunidades en el Caribe colombiano para construir y articular agendas propias para la paz. A pesar de los esfuerzos que se hacen desde los territorios, muchas veces estas propuestas no son escuchadas desde las instituciones estatales que desconocen el territorio, no reconocen como válidos los saberes tradicionales y propios, y mantienen relaciones de clientelismo con los intereses económicos y políticos regionales. En estas dinámicas les puedo contar cómo las mujeres se han estructurado para tener una voz dentro de las organizaciones sociales y una incidencia a partir de apuestas propias que responden a la garantía de sus derechos. ¿Se suman a escuchar y ver cómo se ha construido esperanza desde los territorios que no son centro?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Las mujeres y las organizaciones de base para la construcción de agendas  de paz",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340216\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340216').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340216&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "63",
      "bookedOn": "11/26/2021, 9:05:14 AM",
      "email": "momacia58@gmail.com",
      "name": "Monica Macia",
      "region": "Boyaca",
      "tel": "9197446989",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "19",
      "bookedOn": "27/11/2021 00:23:38",
      "email": "villa0515@hotmail.com",
      "name": "José Samuel Ariza Villa ",
      "region": "Barrancabermeja",
      "tel": "3024546182",
      "timeBooked": " 20:40"
    },
    "email": "ramaca56@gmail.com\nramaca41@hotmail.com",
    "expert": "Padre Rafael Castillo Torres",
    "id": 7153596974159009,
    "phone": "313 5335410",
    "profile": "Director Ejecutivo de la Corporación Desarrollo y Paz del Canal del Dique y la Zona Costera",
    "region": "Caribe e insular",
    "socialMedia": "",
    "table": 14,
    "theme": "El compromiso de la corporación ha estado enfocado en el bienestar de la población y el desarrollo rural del Canal del Dique y la Zona Costera. Así nuestros proyectos han estado orientados a brindar el apoyo que lleve a la prosperidad socioeconómica de sus habitantes y a consolidar una comunidad deliberativa que participe, incida y decida sobre sus proyectos. El río Magdalena hace parte de la vida de este territorio, así que nos hemos sumado al Manifiesto a Colombia sobre la Verdad del Río Grande de la Magdalena. Contaré el proceso de diálogo amplio, participativo, interinstitucional y organizacional que juntó las voces de las comunidades ribereñas. En el proceso de construcción pudimos contemplar en silencio a este río que nos da vida y nos habla. Esperamos que el río deje de ser un cementerio que oculta evidencias.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "En el Canal del Dique el río Magdalena también nos habla y nos da vida",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340216\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340216').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340216&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "18",
      "bookedOn": "25/11/2021, 4:01:18 p. m.",
      "email": "hernando_jose14@hotmail.com",
      "name": "Hernando Caraballo",
      "region": "Santander",
      "tel": "3223991697",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "possoparra@gmail.com",
    "expert": "Rafael Posso Parra*",
    "id": 8827051696871118,
    "phone": 3007639252,
    "profile": "Compositor, dibujante y campesino. Sobreviviente de la masacre a Las Brisas y defensor de los derechos humanos",
    "region": "Caribe e insular",
    "socialMedia": "x\n\nRedes sociales. \n\nFacebook: Rafael Gustavo Posso Parra \n\nTwitter: @Rafaelpossop \n\nIntagram: possoparra",
    "table": 8,
    "theme": "Explicaré cómo la guerra en la subregión de los Montes de María ha vulnerado la figura del campesino: las inequidades en el agro se relacionan no solo con la falta de infraestructura vial y con los desplazamientos, sino también con las modificaciones de las formas de agricultura tradicional a partir de la introducción de monocultivos tales como la teca, el eucalipto y la conocida palma africana. La lucha por las memorias se debate en el campo de lo simbólico, pues el conflicto ha quebrantado sus expresiones culturales con la imposición de la ley del olvido y el silencio. ¿Quieren saber cómo las acciones de reconciliación, en donde el arte ha sido preponderante para seguir viviendo, se construyen a través de canales de expresión y catarsis para los que no han tenido voz?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "El cuerpo del campesino no debe ser un lienzo para plasmar el terror",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340210\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340210').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340210&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "19",
      "bookedOn": "27/11/2021 00:22:19",
      "email": "villa0515@hotmail.com",
      "name": "José Samuel Ariza Villa ",
      "region": "Barrancabermeja",
      "tel": "3024546182",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "19",
      "bookedOn": "29/11/2021, 3:19:37 p. m.",
      "email": "karen.moralesg@unipaz.edu.co",
      "name": "Karen Nikoll Morales Gonzalez ",
      "region": "Barrancabermeja",
      "tel": "3118059540",
      "timeBooked": " 16:40"
    },
    "email": "cabildocorp@gmail.com",
    "expert": "Rafael Ramos Caraballo*",
    "id": 5684505068240105,
    "phone": 3123849784,
    "profile": "Director de la Corporación Cultural Cabildo. La Boquilla, Bolívar",
    "region": "Caribe e insular",
    "socialMedia": "",
    "table": 19,
    "theme": "La Corporación Cultural Cabildo es una organización que trabaja la cultura para el desarrollo social, haciendo incidencia en comunidades étnicas afros, indígenas y campesinas a través de los activos culturales de las comunidades. Los proyectos que lidera fortalecen y generan capacidades individuales y colectivas en las comunidades, apoyando los nuevos liderazgos, consejos comunitarios, la etnoeducación, organizaciones de mujeres, autoridades tradicionales y las formas de organización política, social y  comunitaria. Compartiré con ustedes nuestros proyectos más reconocidos, que buscan generar oportunidades y cerrar brechas: el Mercado Cultural del Caribe, rueda negocios y formación para la calificación y competitividad de las industrias culturales y creativas del Caribe; las Escuelas de Saberes y Derechos Culturales y la Escuela Tambores de Cabildo de la Boquilla.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Tamboras para la vida",
    "url": ""
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "37",
      "bookedOn": "29/11/2021 16:23:21",
      "email": "nayibepedrazac@gmail.com",
      "name": "Nayibe Pedraza",
      "region": "Barrancabermeja",
      "tel": "3175031446",
      "timeBooked": " 16:40"
    },
    "email": "soramonte@gmail.com \ncolectivomontes@gmail.com",
    "expert": "Soraya Bayuelo*",
    "id": 592179090025541,
    "phone": 3218244661,
    "profile": "Comunicadora social y periodista. Gestora cultural del Colectivo de Comunicaciones Línea 21 de los Montes de María. Premio Nacional de Paz 2003",
    "region": "Caribe e insular",
    "socialMedia": "",
    "table": 16,
    "theme": "Los medios de comunicación han conformado un monopolio desde intereses económicos y políticos particulares, brindando una sola versión de las identidades, los territorios y los conflictos. Los medios comunitarios como radios, noticieros y los espacios culturales audiovisuales se convierten en canales que permiten una narración propia creando otros espacios de participación. Veo la importancia de construir región a través de lo comunitario, indispensable para el cambio social, la reestructuración del tejido social y la construcción de memoria no oficial y paz. ¿Quieren que les cuente qué hacemos desde el colectivo en mis bellos Montes de María?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Los medios comunitarios para la construcción de paz y el cambio social",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340218\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340218').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340218&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "30",
      "bookedOn": "28/11/2021 16:20:24",
      "email": "efrain.carvajal@outlook.com",
      "name": "Efrain Yesidt Carvajal Carvajal",
      "region": "Barrancabermeja",
      "tel": "3183124560",
      "timeBooked": " 20:40"
    },
    "email": "ivan.osuna@javerianacali.edu.co",
    "expert": "Iván Osuna Motta",
    "id": 6508695544807611,
    "phone": "317 2277375",
    "profile": "Profesor de la Facultad de Creación y Hábitat. Director de la Maestría en Hábitat Sustentable. Pontificia Universidad Javeriana de Cali",
    "region": "Costa Pacífica",
    "socialMedia": "",
    "table": 12,
    "theme": "En el marco del concurso internacional Solar Decathlon Latin America and Caribbean 2019, el equipo MINGA realizó una investigación para definir un modelo sostenible de ocupación del territorio para zonas afectadas por el cambio en el nivel del mar, derivado del cambio climático que enfrenta el planeta. Este proyecto fue desarrollado por un grupo de estudiantes y profesores de tres universidades de Cali y Florianópolis (Brasil).\nQueremos compartir con ustedes la viabilidad de un proyecto de urbanismo resiliente, concebido para el clima futuro en una ciudad costera del trópico cálido-húmedo. Los resultados de nuestra investigación demostraron que se puede crear un urbanismo climático, resiliente al clima, que garantice la permanencia de los habitantes originales de las zonas costeras, mitigando los riesgos por inundación y garantizando el arraigo cultural de sus habitantes, aun en escenarios de aumento en el nivel del mar.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "MINGA: modelo replicable de renovación urbana sostenible para zonas anfibias",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340214\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340214').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340214&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "36",
      "bookedOn": "29/11/2021, 5:15:12 p. m.",
      "email": "oscarcb1@gmail.com",
      "name": "Óscar Campo",
      "region": "Bogota",
      "tel": "3108043848",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "casamemoriatumaco@gmail.com",
    "expert": "Johanna Olaya",
    "id": 5040798892838629,
    "phone": 3216536415,
    "profile": "Lideresa comunitaria. Coordinadora de la línea pedagógica de la Casa de la Memoria del Pacífico nariñense",
    "region": "Costa Pacífica",
    "socialMedia": "",
    "table": 16,
    "theme": "Quiero explicar las problemáticas de Tumaco y del Pacífico nariñense a partir del concepto de violencia cultural estructural como principal factor de las brechas de inequidad. Al ver que la presencia del Estado ha sido fallida a partir de su abandono, pues no hay un interés por el mejoramiento de los servicios básicos e infraestructuras, ni tampoco una voluntad eficiente en políticas de reparación, se hace necesaria la construcción de lugares en donde la memoria del conflicto armado tenga un lugar para la no repetición y la dignificación de las víctimas, así como la creación de herramientas activas de formación a través de talleres, grupos de teatro y cátedras de paz. ¿Se suman a viajar al Pacífico Sur de Colombia conversando?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "La Casa de la Memoria del Pacífico nariñense y las pedagogías para la paz",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340218\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340218').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340218&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "30",
      "bookedOn": "28/11/2021 16:27:46",
      "email": "efrain.carvajal@outlook.com",
      "name": "Efrain Yesidt Carvajal Carvajal",
      "region": "Barrancabermeja",
      "tel": "3183124560",
      "timeBooked": " 16:40"
    },
    "email": "ekovioaaa@hotmail.com",
    "expert": "Antonio José Andrade Arriaga*",
    "id": 804685484037989,
    "phone": 3108086069,
    "profile": "Arquitecto y planiﬁcador de desarrollo rural, especialista en gestión pública para el desarrollo integral interétnico en el Chocó y el Pacíﬁco",
    "region": "Costa Pacífica",
    "socialMedia": "",
    "table": 5,
    "theme": "Es necesario entender el marco histórico a través del cual el Pacífico ha quedado relegado de las políticas centralistas de Colombia y cómo se ha planeado su desarrollo en beneficio de las arcas de unos pocos, que viven lejos de los escenarios de explotación de los recursos y que no habitan los territorios. Planteo, desde mi experiencia, una búsqueda de otros futuros con un enfoque étnico y rural. Históricamente se nos ha puesto en un sistema que nos empobrece en medio de un escenario completamente abundante: hay agua, tierras fértiles y biodiversidad. Pero nos hemos visto expuestos al dictamen de la cultura fácil y del “yo soy más vivo”. Es necesario entonces definir otras opciones frente al futuro y proveer los medios necesarios para alcanzarlo desde y para las comunidades. ¿Qué opciones se les ocurre?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Desarrollo integral y planificación de las comunidades en el Pacífico",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34027\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34027').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34027&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "30",
      "bookedOn": "25/11/2021, 4:01:34 p. m.",
      "email": "pepacostamo@unal.edu.co",
      "name": "Pedro Pablo Acosta Morales",
      "region": "Bogota",
      "tel": "3013951433",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "24",
      "bookedOn": "25/11/2021 08:21:25",
      "email": "olaffo42@gmail.com",
      "name": "Olaff Jasso",
      "region": "México",
      "tel": "5549330157",
      "timeBooked": " 20:40"
    },
    "email": "ebanno2007@yahoo.es",
    "expert": "Marta Inés Cuero Olave",
    "id": 794486643584357,
    "phone": 3117987729,
    "profile": "Activista fundadora del Proceso de Comunidades Negras –PCN–. Coordinadora de la biblioteca pública comunitaria Palenque Regional el Congal. Custodia del archivo semillero NADELAM de Buenaventura",
    "region": "Costa Pacífica",
    "socialMedia": "Fan page: Palenque El Congal PCN\n\nhttps://m.facebook.com/Palenque-El-Congal-PCN-2090504321165961/",
    "table": 16,
    "theme": "Quiero proponerles un ejercicio: naveguemos teniendo como brújula la importancia que representa la construcción de archivos para la defensa de la memoria y de los territorios de las comunidades negras, no solo en Buenaventura sino a lo largo del Pacífico. Los Archivos Navegantes de la Memoria de Buenaventura nacen como una propuesta de gestión de archivos locales para la paz, a partir de intereses propios y tertulias que giraban alrededor de la historia de las movilizaciones sociales en el territorio, con expresiones que vienen desde el paro cívico de 1968 hasta nuestros días, consolidando archivos personales e hitos aún no documentados. Por otra parte, les contaré cómo el archivo viene trabajando en la defensa del territorio dentro del marco del conflicto armado, pues titulaciones que se hicieron a comunidades a partir de la ley 70 de 1993 se encuentran hoy en día en jaque.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Territorio y titulación: Archivos Navegantes de la Memoria",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340218\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340218').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340218&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "64",
      "bookedOn": "26/11/2021 07:09:13",
      "email": "ubencel@gmail.com",
      "name": "Ubencel Duque Rojas",
      "region": "Barrancabermeja",
      "tel": "3105793090",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "rimoro19@gmail.com\nrimoro14@yahoo.com",
    "expert": "Richard Moreno",
    "id": 3321401897015527,
    "phone": "310 8128492",
    "profile": "Líder comunitario nacido en Tanguí, Chocó. Coordinador del Consejo Nacional de Paz Afrocolombiano –CONPA–",
    "region": "Costa Pacífica",
    "socialMedia": "",
    "table": 2,
    "theme": "Desde su fundación, la misión del CONPA ha sido la de persistir e insistir en la construcción de la paz territorial e interétnica con justicia social. Consideramos que este es el camino idóneo para resolver la deuda histórica que el Estado colombiano tiene con gran parte de la sociedad colombiana, en especial con el pueblo negro, afrocolombiano, raizal y palenquero. Con la Organización Nacional Indígena de Colombia –ONIC– acordamos crear una instancia étnica: la Comisión Étnica para la Paz y la Defensa de los Derechos Territoriales, conformada por autoridades de pueblos indígenas y afrodescendientes. Compartiré con ustedes cómo esta comisión se ha convertido en la instancia nacional, autónoma, plural, decisoria, participativa, permanente y de autorrepresentación de los pueblos y organizaciones indígenas y afrodescendientes que trabajamos por la construcción de la paz. Me interesa mucho conocer su opinión al respecto.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La construcción de paz interétnica en los territorios",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34024\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34024').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34024&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "24",
      "bookedOn": "29/11/2021 17:37:28",
      "email": "olaffo42@gmail.com",
      "name": "Olaff Jasso",
      "region": "México",
      "tel": "5549330157",
      "timeBooked": " 18:00"
    },
    "email": "alopez02@gmail.com",
    "expert": "Alejandro López",
    "id": 4647409770454529,
    "phone": "300 3035354",
    "profile": "Director de la Fundación Confiar. Economista y magíster en historia",
    "region": "Antioquia y la región cafetera",
    "socialMedia": "x\n\nEstas son mis redes sociales: https://www.facebook.com/alejo.lopez.5688\nhttps://twitter.com/alejolo77",
    "table": 4,
    "theme": "Hablaré sobre cómo la concentración del poder económico, en las estructuras que generan inequidad e injusticia social, lleva a que las organizaciones populares creen mecanismos de apoyo financiero. Explicaré las posibilidades que brinda el cooperativismo para la redistribución de la riqueza y de las responsabilidades. La Fundación Confiar nació en 1972 con trabajadores de Sofasa, pasando de una cooperativa empresarial a una industrial, manteniendo un proyecto de economía popular y, a partir de los estratos que comúnmente han sido excluidos de los programas de crédito y financiación, teniendo como base social alrededor de 200 mil personas en 6 departamentos a lo largo del país. ¿Hablamos de cómo cooperativizar como apuesta de paz?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Las cooperativas: otros mundos son posibles",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34026\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34026').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34026&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "21",
      "bookedOn": "25/11/2021 2:04:31",
      "email": "lucardenasgu@unal.edu.co",
      "name": "Luna Cárdenas Guevara",
      "region": "Bogotá D.C.",
      "tel": "3142299711",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "31",
      "bookedOn": "29/11/2021, 6:12:32 p. m.",
      "email": "karina.andrea.lopez.romero@gmail.com",
      "name": "KARINA ANDREA LÓPEZ ROMERO",
      "region": "Barrancabermeja",
      "tel": "3208646376",
      "timeBooked": " 20:40"
    },
    "email": "camilo.acosta.ht@gmail.com",
    "expert": "Camilo Acosta / Lady Hunter Texas",
    "id": 2790518759957439,
    "phone": 3125965181,
    "profile": "Co-fundador de House of Tupamaras, gestora espacios de trabajo y de creación colectiva con enfoque de género y disidencia sexual",
    "region": "Antioquia y la región cafetera",
    "socialMedia": "x",
    "table": 19,
    "theme": "Este colectivo interdisciplinar busca, por medio del Voguing y la cultura Ballroom defender el derecho a la ciudad y a habitar la calle. Esta se compone de una serie de reglas sociales que conllevan la limitación y la segregación de algunxs ciudadanxs. En el caso particular de las personas LGBTIQ+, las instituciones, y la sociedad misma, reproducen espacios \"exclusivos”, “avalados” y \"autorizados\" para que dicho grupo habite y viva la ciudad. Esta conversación se dirige a cómo las fronteras sociales, invisibles en su mayoría, se sostienen a través de discursos morales, políticos y sexuales que limitan el derecho justo y libre de cualquier ciudadano a habitar la ciudad. Seguro ustedes tendrán opiniones al respecto y podremos hablar de ellas.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Divxs callejeras: toma del espacio público y el derecho a la ciudad",
    "url": ""
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "51",
      "bookedOn": "24/11/2021, 12:40:40 p. m.",
      "email": "negociosdelmark@gmail.com",
      "name": "Marquin Manuel Macias Matute",
      "region": "Santander",
      "tel": "3144301447",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "63",
      "bookedOn": "11/26/2021, 8:59:42 AM",
      "email": "momacia58@gmail.com",
      "name": "Monica Macia",
      "region": "Boyaca",
      "tel": "9197446989",
      "timeBooked": " 20:40"
    },
    "email": "enildaluzj@gmail.com",
    "expert": "Enilda Jiménez",
    "id": 5480399360175023,
    "phone": 3115158703,
    "profile": "Psicóloga social comunitaria. Representante legal de Surikí Nature. Trabajadora humanitaria",
    "region": "Antioquia y la región cafetera",
    "socialMedia": "x\n\nNos gustaría que la página de la Reserva Natural Surikí sea mencionada en las publicaciones que consideren convenientes.\nLas direcciones son las siguientes-\nFacebook; Surikí Nature Reserve y en Instagram SurikiNature",
    "table": 9,
    "theme": "Hablaré con ustedes de la historia de resiliencia de mi familia, de las herramientas y aprendizajes que nos ayudaron a sobreponernos a la violencia y a ser capaces de volver a nuestra tierra, luchar por nuestros derechos y sentirnos capaces de perdonar al hombre que confesó y aceptó la responsabilidad por el asesinato de mi padre.\nLes contaré de nuestro proceso de retorno a nuestra tierras y territorio, y sobre la experiencia de redescubrirnos y sanarnos a través de este. También les contaré sobre cómo nació nuestro sueño y nuestra empresa Surikí Nature, un proyecto agropecuario y de conservación en el que hoy trabajamos a través de la Reserva Natural Surikí.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Sanar para perdonar a través de la naturaleza y el territorio",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340211\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340211').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340211&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "22",
      "bookedOn": "29/11/2021 16:09:04",
      "email": "angie_natalia990810@hotmail.com",
      "name": "Angie Natalia Galván Ramos",
      "region": "Barrancabermeja ",
      "tel": "3046292571",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "fredyrendonh@gmail.com",
    "expert": "Fredy Rendón Herrera",
    "id": 3787309287318207,
    "phone": 3174337344,
    "profile": "Gestor de paz. Desmovilizado en 2006",
    "region": "Antioquia y la región cafetera",
    "socialMedia": "Quiero informarles que no tengo ninguna red social.",
    "table": 8,
    "theme": "Quiero destacar en nuestra conversación la inmensa distancia que encontré, durante mi participación en el conflicto armado, entre una Colombia que acontece en las oficinas, en la abundancia, en los grandes salones, en los centros financieros o comerciales, y la otra Colombia que acontece en las cuencas de los ríos a las que nunca llega el Estado, que más que encuentro es un desencuentro con un mundo de carencias y vacíos, y donde la justicia, sea penal, sea económica o social, la imparte y ejerce cualquiera que llegue con las armas o el dinero de su lado.\nConsidero que la equidad en las oportunidades y en las posibilidades debe ser la bandera de la justicia que permita que esa Colombia, abandonada hasta ahora por el Estado, se integre plenamente para que, por fin, empecemos, después de 200 años, a construir una nación. ¿Qué piensa usted?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Equidad como justicia: encuentros y desencuentros",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340210\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340210').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340210&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "30",
      "bookedOn": "25/11/2021 14:12:39",
      "email": "efrain.carvajal@outlook.com",
      "name": "Efrain Yesidt Carvajal Carvajal",
      "region": "Barrancabermeja",
      "tel": "3183124560",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "rperez304@gmail.com",
    "expert": "Rodrigo Pérez",
    "id": 8341908195838094,
    "phone": 3187161909,
    "profile": "Gestor de paz. Desmovilizado en 2005. Miembro fundador de la Fundación Aulas de Paz",
    "region": "Antioquia y la región cafetera",
    "socialMedia": "",
    "table": 8,
    "theme": "Puedo hablar con ustedes desde mi experiencia en el conflicto armado y como desmovilizado. Desde la Fundación Aulas de Paz hemos atendido a la población desmovilizada y a las víctimas del conflicto armado. En ese espacio buscamos la reconciliación, el perdón y que las voces de las víctimas se encuentren con quienes estuvieron en armas y les causaron tanto daño.\nLa atención del Estado hacia la población desmovilizada ha sido precaria. Las personas desmovilizadas se encuentran en una situación de absoluto desamparo. Las instituciones a las que les correspondía orientar su camino hacia la civilidad están ausentes. La suerte de las víctimas no es mejor. Las usan para que relaten hechos, pero la verdadera atención a sus vidas ha sido descuidada y están abandonadas en su precariedad en las regiones de nuestro país. ¿Nos sentamos a hablar?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Fundación Aulas de Paz: camino al andar hacia la reconciliación",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340210\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340210').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340210&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "30",
      "bookedOn": "25/11/2021, 4:34:06 p. m.",
      "email": "pepacostamo@unal.edu.co",
      "name": "Pedro Pablo Acosta",
      "region": "Bogota",
      "tel": "3013951433",
      "timeBooked": " 19:20"
    },
    "email": "yanvega61@gmail.com",
    "expert": "Yan Carlos Vega",
    "id": 2216421996516011,
    "phone": "313 780 36 76/ 3107268791",
    "profile": "Gestor de paz en la Cooperativa de Proyectos Productivos de Firmantes de Paz –COOPEMPRENDER– con el proyecto Conexión Digital en Ituango",
    "region": "Antioquia y la región cafetera",
    "socialMedia": "",
    "table": 17,
    "theme": "Trabajo como administrador de un proyecto de conexión digital en uno de los municipios más afectados por la guerra, tengo familiares reincorporados y he sido víctima directa del conflicto. Actualmente apoyo el proyecto para conectar digitalmente a todos los hogares del municipio y llevar esperanza de una mejor educación a todo el territorio municipal. Quiero compartir este proyecto que hemos hecho colectivamente y cómo lo hemos desarrollado. Les propongo un zoom territorial en Ituango, Antioquia y, de paso, les invito a ver todo lo que hacemos para construir comunidad. ¿Se animan?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Conexión, educación y conectividad en la ruralidad",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340220\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340220').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340220&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "33",
      "bookedOn": "29/11/2021 15:52:58",
      "email": "camiloc06@gmail.com",
      "name": "Camilo Cruz ",
      "region": "bucaramanga",
      "tel": "3124892358",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "31",
      "bookedOn": "29/11/2021 14:28:13",
      "email": "lays0311@hotmail.com",
      "name": "LAYS YELISA POTES",
      "region": "CORREGIMIENTO EL LLANITO",
      "tel": "3138167708",
      "timeBooked": " 19:20"
    },
    "email": "",
    "expert": "Alejandra Miller",
    "id": 557655503062383,
    "phone": "",
    "profile": "Comisionada. Comisión de la Verdad. Integrante del movimiento feminista Ruta Pacífica de las Mujeres",
    "region": "Surandina",
    "socialMedia": "x",
    "table": 2,
    "theme": "Como feminista he aprendido que para las mujeres de este país, en especial para la mujeres rurales, hay una historia de dolor muy profundo, pero también que tenemos una gran capacidad de transformación gracias a nuestros procesos de resistencia y a nuestra fuerza organizativa. Hemos padecido múltiples violencias y, no obstante, hemos salvado a nuestrxs hijxs de las garras de la guerra, hemos tomado decisiones en nuestras familias frente al desplazamiento, hemos reconstruido el tejido social y la memoria y hemos inventado formas de economía solidaria por una vida digna. En pocas palabras: nos hemos encargado del cuidado de la vida. De ahí que sé que sin las voces de las mujeres en toda su diversidad y de las personas LGBTQ+, y sin una escucha profunda de los procesos que ocurren en los territorios, es imposible pensar en otros futuros posibles.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Un enfoque feminista de la economía, la paz y la verdad",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34024\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34024').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34024&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "claudiaccharfuelan@gmail.com",
    "expert": "Claudia Fernanda Charfuelán Caipe",
    "id": 6559773874883082,
    "phone": "316 7829221",
    "profile": "Integrante del Colectivo “Qué decís: Mujeres Pastos Reviviendo la Memoria y la Lucha”, pertenecientes a los resguardos del Gran Cumbal y Guachucal",
    "region": "Surandina",
    "socialMedia": "",
    "table": 7,
    "theme": "Lo conversadito abordará temáticas sobre los saberes y prácticas propias transmitidas en la historia oral, permitiendo reconocer las luchas de existencia del pueblo Pasto, los procesos de los comuneros y el papel de mujeres como el de la Cacica Micaela. Las usurpaciones de la tierra datan de una larga historia a partir de la conquista española, con una continuidad en la configuración de los resguardos en 1991 y los intentos de abuso por parte de multinacionales mineras. Entendiendo que el tiempo tiene forma de un churo continuo, en el que hay una yuxtaposición entre pasado, presente y futuro, las acciones de recuperación fueron llevadas a cabo por los de adelante, los caciques antiguos, que pleitearon por las tierras para dejar algo a los que van atrás, a los y las renacientes del presente.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Mujeres en pie de lucha por la recuperación de la tierra en el Nudo de la Waka",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34029\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34029').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34029&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "39",
      "bookedOn": "11/23/2021, 6:17:52 PM",
      "email": "laura.cardona.produccion@gmail.com",
      "name": "Laura Cardona",
      "region": "Antioquia",
      "tel": "3208354142",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "65",
      "bookedOn": "29/11/2021 18:44:27",
      "email": "heraceunavez@gmail.com",
      "name": "Ediberto Acevedo Watusi",
      "region": "Barrancabermeja",
      "tel": "3142307463",
      "timeBooked": " 16:40"
    },
    "email": "edquifalla@gmail.com",
    "expert": "Edinson Quiñones*",
    "id": 1231476723601421,
    "phone": 3215632609,
    "profile": "Artista con Maestría en Artes Integradas con el Ambiente de la Universidad del Cauca",
    "region": "Surandina",
    "socialMedia": "x",
    "table": 10,
    "theme": "La palabra girará en torno a la necesidad del regreso a la madre tierra, pues el territorio es abundante en sí mismo. Propongo cohabitar y mingar fuera de las selvas de cemento, así como la necesidad de cambiar el lenguaje con el que nos relacionamos con la naturaleza, espacio de vida que no puede ser nombrado como recurso medioambiental. Planteo unas pedagogías de la realidad en las que aparece el problema de la tierra como herida colonial, de aquí la importancia del arte como proceso de sanación de las malas muertes en el territorio. Les contaré sobre el legado de Manuel Quintín Lame, el Territorio Chiquito y la recuperación de sus puntos energéticos. ¿Ustedes qué piensan si les digo que la dignidad se encuentra en la memoria, de la que no nos pueden despojar?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Kitecsa: “florecer”. El arte como práctica de sanación",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340212\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340212').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340212&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "37",
      "bookedOn": "29/11/2021 16:21:23",
      "email": "nayibepedrazac@gmail.com",
      "name": "Nayibe Pedraza",
      "region": "Barrancabermeja",
      "tel": "3175031446",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "32",
      "bookedOn": "28/11/2021 22:00:24",
      "email": "giraldo.laura.m@gmail.com",
      "name": "Laura Giraldo",
      "region": "Bogotá",
      "tel": "3103245030",
      "timeBooked": " 16:40"
    },
    "email": "mrmunoz@javerianacali.edu.co\n\ndiana.cardona@javerianacali.edu.co\n(mantener en copia siempre a la asistente)",
    "expert": "Manuel Ramiro Muñoz*",
    "id": 4119206291127967,
    "phone": "320 6751771",
    "profile": "Fundador y director del Instituto de Estudios Interculturales de la Pontificia Universidad Javeriana de Cali",
    "region": "Surandina",
    "socialMedia": "Estaremos atentos a la entrevista previa de forma remota.\nActualmente el profesor Manuel no cuenta con redes sociales.",
    "table": 2,
    "theme": "En Colombia viven millones de colombianos a extramuros de la sociedad y del Estado, sin las condiciones básicas para el ejercicio de sus derechos. Frente a esta realidad se han explorado múltiples vías, pero el resultado ha sido nefasto: los acuerdos políticos se incumplen, las leyes son papel mojado, los presupuestos se esfuman en la voracidad criminal de la corrupción y en la incapacidad del Estado para llegar de forma eficaz con soluciones a los territorios. \n¿Qué hacer? ¡Cambiar de estrategia! ¡No se puede seguir esperando soluciones desde arriba y desde afuera! Se trata, entonces, de construir desde los territorios y desde las comunidades. Se trata de avanzar en los procesos de construcción de autonomía económica, que significa escalar los procesos económicos con identidad territorial de ciclo completo. La equidad en los territorios depende de la autonomía económica y esto no es posible sin gobernanza propia en y desde los territorios.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Equidad desde la autonomía económica en el territorio con gobernanza propia",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34024\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34024').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34024&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "20 años",
      "bookedOn": "29/11/2021, 3:18:27 p. m.",
      "email": "michel.luna@unipaz.edu.co",
      "name": "Michel Natalia Luna Quintero",
      "region": "Barrancabermeja",
      "tel": "3133680690",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "mariacampo1@yahoo.com",
    "expert": "María Campo",
    "id": 6610714592139371,
    "phone": 3176454224,
    "profile": "Escuela Sociopolítica entre Mujeres de la Asociación Casa Cultural El Chontaduro de Cali",
    "region": "Surandina",
    "socialMedia": "x",
    "table": 16,
    "theme": "En marzo de 2020 el mundo estaba asistiendo a una pandemia que no tenía precedentes. Para las mujeres negras afrodescendientes que habitan el oriente de Cali, esta situación no era nueva, tanto así que fue llamada sindemia, en donde se entroncaban la Covid-19, la inequidad social, la violencia contra las mujeres y jóvenes y el racismo estructural. Este contexto nos llevó a pensar desde la Casa Cultural el Chontaduro en hacer más fuerte la agencia que siempre hemos tenido para habitar nuestras realidades. Vinculamos entonces la emergencia agudizada por la Covid-19 a la construcción de soberanía, autonomía y participación comunitaria en un trenzado de dignas resistencias y reXistencias, a través del canto, la danza, la uramba, la minga, los alabaos, el comadreo y comadrazgo para abrazarnos y sanarnos a nosotras mismas, a la comunidad, al territorio. Quiero compartir esta experiencia con ustedes.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Trenzándonos: políticas de la vida cotidiana de mujeres negras",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340218\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340218').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340218&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "64",
      "bookedOn": "26/11/2021 07:04:51",
      "email": "ubencel@gmail.com",
      "name": "Ubencel Duque Rojas",
      "region": "Barrancabermeja",
      "tel": "3105793090",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "58",
      "bookedOn": "26/11/2021 19:02:15",
      "email": "dacagra@gmail.com",
      "name": "Daniel Cañas",
      "region": "Barrancabermeja",
      "tel": "3202053792",
      "timeBooked": " 16:40"
    },
    "email": "Gerencia@sidocsa.com\nViviannearmitage@sidocsa.com",
    "expert": "Mauricie Armitage Cadavid*",
    "id": 8967081789109889,
    "phone": "Hija: Viviane 316 4381870",
    "profile": "Fundador de la Siderúrgica de Occidente y del Ingenio de Occidente",
    "region": "Surandina",
    "socialMedia": "x",
    "table": 17,
    "theme": "Maurice Armitage es uno de los colombianos que más ha trabajado por la equidad del país. Este empresario caleño, fundador del Grupo Sidoc, no solo reparte entre sus empleados las utilidades de su empresa Siderúrgica del Occidente, sino que a través de su fundación ha impulsado programas que ofrecen segundas oportunidades a jóvenes de zonas vulnerables como Siloé, Buenaventura y el norte del Cauca. Como alcalde de Cali ejecutó una ambiciosa apuesta social que incluyó la destinación del 60 % del presupuesto municipal en educación, la resocialización y contratación de jóvenes en condición de vulnerabilidad como gestores de paz y cultura ciudadana y la creación de 480 comedores comunitarios para alimentar diariamente a 57.000 personas en estado de pobreza extrema y absoluta. Actualmente continúa al frente de sus empresas con una convicción: que la creación de empleo es fundamental para redistribuir la riqueza y generar equidad. Compartirá estas experiencias con ustedes.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Creación de empleo: equidad y redistribución de la riqueza",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340220\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340220').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340220&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "63",
      "bookedOn": "11/26/2021, 8:56:05 AM",
      "email": "momacia58@gmail.com",
      "name": "Monica Macia",
      "region": "Boyaca ",
      "tel": "9197446989",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "56",
      "bookedOn": "28/11/2021 16:22:57",
      "email": "ivan.osuna@javerianacali.edu.co",
      "name": "Ivan Osuna",
      "region": "Valle del Cauca",
      "tel": "3172277375",
      "timeBooked": " 18:00"
    },
    "email": "pattyjojoa@gmail.com",
    "expert": "Patricia Jojoa Salazar",
    "id": 896332803396320,
    "phone": 3155724019,
    "profile": "Indígena Quillasinga del resguardo Refugio del Sol, de la Laguna de la Cocha. Autoridad tradicional, gobernadora segunda. Heredera del Planeta de la minga Asociativa Asoyarcocha y la Red de Reservas Naturales",
    "region": "Surandina",
    "socialMedia": "x\n\nLa res mas utilizada es facebook y son\nComunicaciones adc\nADC(asociacion para el desarrollo campesino)\nResguardo indigena quillasinga refugio del sol",
    "table": 14,
    "theme": "Mi hogar es la laguna de La Cocha, humedal reconocido dentro de la convención de Ramsar y que abre la puerta a la Amazonía colombiana. Este territorio nos vio nacer y crecer en medio de las montañas. Como reserva agroecológica proponemos acciones de cuidado con la naturaleza, protegiendo las semillas ancestrales, fundamentándonos en una alimentación sana y propia. Desde las comunidades de la vida reconocemos a los sabedores antiguos y al papel protagónico de las mujeres que, desde los afectos, permiten generar mejores condiciones de vida a partir de lo que el territorio y la familia ofrecen. Ellas son las encargadas de llevar el legado y lograr en la vida cotidiana una equidad de género. Mi propósito es compartir, desde la experiencia de vida, desde lo personal de la vida organizacional y comunitaria en el territorio, las luchas que durante años hemos dado para combatir las inequidades.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La Cocha: espejos de agua y autonomía",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340216\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340216').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340216&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "31",
      "bookedOn": "29/11/2021, 6:17:24 p. m.",
      "email": "karina.andrea.lopez.romero@gmail.com",
      "name": "KARINA ANDREA LÓPEZ ROMERO",
      "region": "Barrancabermeja",
      "tel": "3208646376",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "lilamujer@gmail.com / javale173@gmail.com",
    "expert": "Yaneth Valencia",
    "id": 4482839932531348,
    "phone": "301 2584009",
    "profile": "Mujer afro, lideresa comunitaria, trabajadora social. Fundadora de la Asociación y Casa Lila Mujer. Veinte años en resistencia y pedagogía por el reconocimiento y la aceptación del VIH en las mujeres del Valle del Cauca",
    "region": "Surandina",
    "socialMedia": "x",
    "table": 11,
    "theme": "Pondré sobre la mesa la problemática de las mujeres racializadas con síndrome de VIH y de cómo este fenómeno hace parte de las violencias sexuales y de género en relación a las formas de victimización en el marco del conflicto armado. Compartiré mi experiencia desde mi cuerpo, mi proceso de empoderamiento y apoyo a otras mujeres a partir de la conformación del trabajo en red. Aquí el acompañamiento, la juntanza y la sensibilidad se vuelven pilares ante el rechazo de una sociedad estigmatizadora e ignorante del funcionamiento del virus. Las redes son neofamilias, lugares de escucha, en donde se desarrollan acciones en torno a la economía solidaria y al re-descubrimiento de las capacidades laborales y artísticas, espacios en donde la importancia de la poesía y la oralidad radica en la catarsis del parir historias.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "El VIH desde la “re-existencia”",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340213\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340213').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340213&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "31",
      "bookedOn": "25/11/2021, 4:04:41 p. m.",
      "email": "karina.andrea.lopez.romero@gmail.com",
      "name": "KARINA ANDREA LÓPEZ ROMERO",
      "region": "Barrancabermeja",
      "tel": "3208646376",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "25",
      "bookedOn": "25/11/2021 16:18:55",
      "email": "lquinterof@unal.edu.co",
      "name": "Lina María Quintero Fonsecaa",
      "region": "Bogotá",
      "tel": "3194845955",
      "timeBooked": " 19:20"
    },
    "email": "dignidad.guerrero@gmail.com",
    "expert": "Alexis Guerrero",
    "id": 4564534374344355,
    "phone": "301 2976929",
    "profile": "Miembro fundador del Centro Cultural Horizonte de Barrancabermeja",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 6,
    "theme": "El Centro Cultural Horizonte tiene una larga historia desde que se creó en la Comuna 7 de Barrancabermeja. Compartiré el proceso de creación y los impactos que tuvo en la comunidad. Contaré porqué es una experiencia de construcción de paz y cómo logró crear la pizzería que permite que sus miembros vivan dignamente desde el arte y la gastronomía, creando condiciones de equidad en el marco de un colectivo. Desde estos puntos orientadores podremos profundizar en aspectos relacionados con el conflicto, la industria petrolera y su relación con el arte.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Centro Cultural Horizonte: una experiencia para el arte y la cultura sostenible",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34028\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34028').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34028&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "40 años",
      "bookedOn": "29/11/2021 10:33:14",
      "email": "gloesgho@hotmail.com",
      "name": "Gloria Esmeralda González Herrera",
      "region": "Barranquilla",
      "tel": "3012208902",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "19",
      "bookedOn": "27/11/2021, 10:52:18 a. m.",
      "email": "sofia.dmenco25@gmail.com",
      "name": "Ana Sofía Díaz Menco",
      "region": "Barrancabermeja",
      "tel": "3005368514",
      "timeBooked": " 18:00"
    },
    "email": "anyelduranmorales@gmail.com",
    "expert": "Anyel Durán",
    "id": 7040798846152798,
    "phone": 3122553313,
    "profile": "Mujer trans, presidenta de la Fundación Magdalena Diversa. Médica veterinaria y zootecnista. Trabaja con sectores LGBTIQ+, víctimas del conflicto armado, juventudes y negritudes",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 6,
    "theme": "Compartiré la relación que hay entre mis tránsitos corporales y mi participación con lo comunitario, y cómo en este proceso puedo considerarme semilla, alimentada y transformada por las personas con las que trabajo. Mi apoyo a los colectivos LGBTIQ+ se ha desarrollado en el territorio del Magdalena Medio en poblaciones como San Pablo, Cantagallo y Yondó, contextos donde estos colectivos son y han sido vulnerados. Allí el conflicto armado marcó los cuerpos como forma de control de los territorios, estigmatizando lo diverso y lo diferente. En esta situación de vulnerabilidad hemos creado redes de hermandad y hemos “redescubierto” identidades sexuales y de género, lo cual se ha traducido en proyectos de vida y posibilidades de profesionalización. Les contaré la fuerza que estas transformaciones nos han dado como colectivo.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Yo soy una semilla: tránsitos corporales y participación en lo comunitario",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34028\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34028').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34028&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "21",
      "bookedOn": "25/11/2021 15:55:50",
      "email": "valentina_lobo_iriarte@hotmail.com",
      "name": "Karen Valentina Lobo Iriarte",
      "region": "Barrancabermeja ",
      "tel": "3206206341",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "24",
      "bookedOn": "25/11/2021 15:57:04",
      "email": "jfperezav@unal.edu.co",
      "name": "Jefferson Fabian Perez Avila",
      "region": "casanare",
      "tel": "3143680056",
      "timeBooked": " 19:20"
    },
    "email": "lozaarenas@gmail.com",
    "expert": "César Loza Arenas",
    "id": 670698956003318,
    "phone": 3156763990,
    "profile": "Con veinticinco años de dirigencia sindical. Vicepresidente de la Unión Sindical Obrera –USO–",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 3,
    "theme": "La USO tiene un acumulado histórico en la defensa de los derechos laborales de lxs trabajadorxs y en la defensa de la paz, por ello saludó el Acuerdo de Paz con FARC y apoyó las negociaciones con ELN. La Unión Sindical Obrera le ha apostado al desarrollo regional y a la defensa económica, social y ambiental de los territorios,  propiciando  la instalación de una mesa nacional minero-energética en la cual se proteja la vida natural de los territorios y sus pobladores: nunca va a convalidar los recortes de salarios,  ni los despidos de trabajadorxs, ni la venta de Ecopetrol. Junto con otras organizaciones de la sociedad civil ha mantenido viva la voz para que el patrimonio público se preserve como activo valioso del Estado. Creemos que esta es una apuesta que requiere del compromiso de todxs lxs colombianxs. ¿No lo creen ustedes?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Por la defensa de los derechos laborales y la paz",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34025\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34025').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34025&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "29",
      "bookedOn": "26/11/2021, 7:55:31 p. m.",
      "email": "alejandro.ramirez.jaimes@gmail.com",
      "name": "Alejandro Ramírez Jaimes",
      "region": "Santander",
      "tel": "3152381739",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "71",
      "bookedOn": "28/11/2021, 12:21:03 p. m.",
      "email": "rocio.londonobotero@gmail.com",
      "name": "Rocio Londoño",
      "region": "Provincia",
      "tel": "3103293303",
      "timeBooked": " 20:40"
    },
    "email": "No tiene",
    "expert": "Daniel Ardila",
    "id": 1094533952414021,
    "phone": 3182605651,
    "profile": "Líder social. Hace parte de la comisión de quejas y reclamos del Sindicato Nacional de Trabajadores de la Industria Agropecuaria –SINTRAINAGRO– y de la Junta Nacional de la Organización Colombiana de Pensionados –OCP–",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 1,
    "theme": "Habiendo trabajado en toda la zona del Magdalena Medio y la Costa Caribe en diferentes actividades agropecuarias (pues fui pescador y trabajador en grandes superficies de arroz, ganadería y palma) puedo narrarles, a partir de mi propia vida, los cambios no solo en los paisajes de mi región con la ampliación del ferrocarril y la introducción de los monocultivos, sino también en los procesos de organización de nosotros, los trabajadores. Por mi experiencia dentro de SINTRAINAGRO puedo contarles cuál es la importancia de la conformación de dichas organizaciones en la defensa de los derechos laborales, las formas de educación sindical y las articulaciones nacionales e internacionales para la financiación de plantones como las huelgas. He aprendido a dialogar y negociar con diferentes actores dentro de la historia sindical y del conflicto armado. ¿Nos sentamos frente a frente a hacer memoria y dialogar?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Ires y venires del sindicalismo en Puerto Wilches",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34023\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34023').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34023&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "20",
      "bookedOn": "29/11/2021 16:22:46",
      "email": "yeimisnavarro13@gmail.com",
      "name": "Yeimis Daniela Navarro Hernández",
      "region": "Barrancabermeja ",
      "tel": "3023196141",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "gloriamparo.ofp@gmail.com / organizacionfemeninapopular@gmail.com",
    "expert": "Gloria Amparo Suárez",
    "id": 8020754376358325,
    "phone": 3102197339,
    "profile": "Integrante de la Organización Femenina Popular –OFP– por más de 30 años y defensora de derechos humanos",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 17,
    "theme": "Me interesa contarles el trabajo que ha venido desarrollando la OFP con las mujeres migrantes (fenómeno que se ha recrudecido a partir del 2019) y cómo se han integrado en los programas construidos desde la organización. La vulnerabilidad de estas mujeres se encuentra en la falta de redes de apoyo, de conocimiento de sus derechos, la dependencia económica de su opresor, la inestabilidad en su residencia, entre otros. Es así como desde la organización venimos articulando sus vidas en diferentes espacios de escucha y en distintas iniciativas productivas y núcleos de ahorro solidario y femenino. Les invito a conversar sobre el derecho a migrar.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Redes de apoyo para las mujeres migrantes en Barrancabermeja",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340220\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340220').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340220&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "22",
      "bookedOn": "25/11/2021, 15:30:59",
      "email": "Apricoa@unal.edu.co",
      "name": "Anngilys Rico",
      "region": "Bogotá ",
      "tel": "3024125921",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "41",
      "bookedOn": "25/11/2021 21:08:34",
      "email": "manuelfaustinoc@hotmail.com",
      "name": "Manuel Cardozo",
      "region": "Barrancabermeja",
      "tel": "3124157083",
      "timeBooked": " 19:20"
    },
    "email": "acvc.barrancabermeja@gmail.com",
    "expert": "Irene Ramírez",
    "id": 2708523549469849,
    "phone": 3102194759,
    "profile": "Presidenta de la Asociación Campesina del Valle del río Cimitarra –ACVC–",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 5,
    "theme": "Desde su fundación en 1996, la Asociación Campesina del Valle del río Cimitarra ha enfrentado las violencias de las que ha sido víctima la población campesina en los municipios a lo largo del río, ha sabido exigir los derechos a la tierra y a una vida digna, y se ha opuesto a que la guerra sea el destino del país. Es urgente que las Zonas de Reserva Campesina, reconocidas por la ley, se hagan una realidad en la vida cotidiana y que lleguen vías, educación y desarrollo social, económico y cultural a esta región. Compartiré esta historia de lucha contra las inequidades del campo, antes y después de la pandemia, y los análisis que recientemente hemos hecho sobre la situación minera ambiental en el territorio.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Las Zonas de Reserva Campesina: una apuesta por la vida digna, la paz y el territorio",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34027\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34027').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34027&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "41",
      "bookedOn": "26/11/2021, 7:54:20 p. m.",
      "email": "manuelfaustinoc@hotmail.com",
      "name": "Manuel Cardozo",
      "region": "Santander",
      "tel": "3124157083",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "41",
      "bookedOn": "29/11/2021 9:30:39 a. m.",
      "email": "yoli5980@hotmail.com",
      "name": "Yolima Salcedo",
      "region": "Barrancabermeja",
      "tel": "3144629816",
      "timeBooked": " 19:20"
    },
    "email": "mayuke6@hotmail.com",
    "expert": "Iván  Madero Vergel",
    "id": 7474044617901517,
    "phone": 3223711440,
    "profile": "Defensor de derechos humanos. Presidente de la Corporación Regional para la Defensa de los Derechos Humanos –CREDHOS–",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 11,
    "theme": "Esta conversación propone poner sobre la mesa la importancia de dar trascendencia a los liderazgos espontáneos, a través de una capacitación y profesionalización que busca dar continuidad a lo empírico, contemplando dichos liderazgos como una opción de vida. La prolongación de este tipo de acciones se puede lograr a través de la construcción e implementación de metodologías y herramientas siempre en relación con el territorio. Es así como los nuevos liderazgos tienen dentro de sus núcleos de defensa, y como línea transversal, la importancia de lo ambiental. Así mismo, propondré la importancia de la formación en lo político para superar las contraposiciones ideológicas dentro del movimiento, elemento esencial en coyunturas de procesos de paz, en donde es indispensable la construcción de estrategias de diálogo y reconciliación.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "La defensa de los derechos humanos y la profesionalización de los liderazgos",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340213\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340213').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340213&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "30",
      "bookedOn": "24/11/2021 15:59:38",
      "email": "efrain.carvajal@outlook.com",
      "name": "Efrain Yesidt Carvajal Carvajal",
      "region": "Barrancabermeja",
      "tel": "3183124560",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "24",
      "bookedOn": "25/11/2021 08:18:04",
      "email": "olaffo42@gmail.com",
      "name": "Olaff Jasso",
      "region": "México",
      "tel": "5549330157",
      "timeBooked": " 20:40"
    },
    "email": "jaime.ardila.gomez@gmail.com",
    "expert": "Jaime  Ardila",
    "id": 790255512047237,
    "phone": 3002652893,
    "profile": "Fundador de la Corporación Ecológica y Cultural Yariguíes",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 6,
    "theme": "Como defensor del territorio, he promovido la creación del Parque Natural Nacional Yariguíes y hago parte del Movimiento Social en Defensa de los ríos Sogamoso y Chucurí que ha demostrado, basado en investigaciones, la inviabilidad del proyecto hidroeléctrico Hidrosogamoso. Actualmente también hago parte de la mesa técnica en defensa del páramo de Santurbán. A partir de mi experiencia les puedo hablar sobre los peligros que conlleva la creación de megaproyectos para la vida de los campesinos, tanto para las formas de producción como para sus formas de pensamiento y sus expresiones culturales. El desplazamiento de especies y de humanos producto de estos megaproyectos deja huellas inquebrantables en los ámbitos ecológicos. ¿Quieren que les cuente cómo ha cambiado el paisaje y cómo el desvío de ciertas especies afectan los asentamientos humanos y sus formas de vida?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "El desplazamiento biocultural: la inviabilidad de los megaproyectos extractivistas",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34028\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34028').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34028&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "43",
      "bookedOn": "27/11/2021, 5:37:46 a. m.",
      "email": "carlosandrestoro@live.com",
      "name": "Carlos Andrés Toro Gutiérrez",
      "region": "Barrancabermeja",
      "tel": "3184079069",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "29",
      "bookedOn": "29/11/2021 8:19:07",
      "email": "lauraserranovecino@gmail.com",
      "name": "Laura Marcela Serrano Vecino",
      "region": "Barrancabermeja",
      "tel": "3023471160",
      "timeBooked": " 20:40"
    },
    "email": "jacoparina005@yahoo.es",
    "expert": "Jaime Corena Parra",
    "id": 7505518975436504,
    "phone": 3212893857,
    "profile": "Profesor universitario y educador comunitario",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 4,
    "theme": "A partir de mis múltiples experiencias de exilio pude ampliar mi concepción económica del cooperativismo. Con el destierro descubrí la importancia de la solidaridad y la emprender proyectos asociativos. En mi vida pasé del arraigo a una cultura laboral, cooperativa y fiestera, a desarraigos forzados. Al retonar a mi ciudad natal hace pocos años, ya adulto mayor, mi reto ha sido seguir pensando y actuando como cooperativista y docente con miras al desarrollo humano territorial.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "B",
    "title": "El cooperativismo y el desarrollo humano territorial",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34026\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34026').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34026&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "21",
      "bookedOn": "25/11/2021, 01:56:49",
      "email": "  aamayaq@unal.edu.co",
      "name": "Angie Viviana Amaya Quiroga ",
      "region": "DC",
      "tel": "3007168566",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "21",
      "bookedOn": "25/11/2021 2:17:35",
      "email": "lucardenasgu@unal.edu.co",
      "name": "Luna Cárdenas Guevara",
      "region": "Bogotá D.C.",
      "tel": "3142299711",
      "timeBooked": " 19:20"
    },
    "email": "No tiene",
    "expert": "Jorge Medina",
    "id": 6220105196915200,
    "phone": 3118659974,
    "profile": "Presidente de la Asociación de Pescadores Acuicultores y Afrodescendientes del corregimiento El Llanito –APLL– ( 2006 a 2008 / 2016 hasta la actualidad)",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 19,
    "theme": "En 1998 la APLL demandó a Ecopetrol por los desastres ecológicos causados al Caño San Silvestre. La acción popular logró que el complejo industrial de Barrancabermeja construyera una planta de tratamiento de aguas residuales, eliminando las piscinas de oxidación que se desbordaban y caían a los cuerpos de agua. También, desde la asociación, hemos liderado proyectos de fortalecimiento y producción pesquera, abono orgánico y plántulas para la recuperación de la ciénaga El Llanito. Hoy en día hacemos una apuesta por el ecoturismo como medio de rescate de la pesca artesanal, que tiende a desaparecer, y como estrategia de visibilización de las problemáticas del “acuatorio”, ya que los recursos que desde nuestro territorio se extraen y generan riqueza al país no se ven reflejados en la calidad de vida de los habitantes del El Llanito: no contamos con agua potable, ni alcantarillado, ni infraestructura de calidad. Quiero compartir con ustedes estas luchas y sueños.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Por el cuidado del agua y de la pesca artesanal",
    "url": ""
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "31",
      "bookedOn": "29/11/2021 14:34:21",
      "email": "lays0311@hotmail.com",
      "name": "LAYS YELISA POTES",
      "region": "CORREGIMIENTO EL LLANITO",
      "tel": "3138167708",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "22",
      "bookedOn": "25/11/2021 16:21:02",
      "email": "miguelgarciacardona@gmail.com",
      "name": "Miguel García",
      "region": "Bogotá",
      "tel": "3046031607",
      "timeBooked": " 18:00"
    },
    "email": "tripoli1974@yahoo.es",
    "expert": "Libia Esther Arciniegas Liñán",
    "id": 4985035524577952,
    "phone": 3014579729,
    "profile": "Presidenta de la Federación de Pescadores Artesanales y Ambientalistas del Departamento del Cesar",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 1,
    "theme": "La pesca artesanal es más que una actividad económica. Para ser pescador se necesita tener un conocimiento sobre el ambiente, la ecología de los bosques y el agua y la historia anfibia. Mi participación tiene como propósito dar a conocer a los participantes del Mercado del Conocimiento y No-Conocimiento Útil, cómo la pesca artesanal es un servicio no sólo de aprovisionamiento sino también de carácter cultural. Para ser pescador artesanal hay que conocer el movimiento de los vientos y el agua, el movimiento de los peces (comúnmente llamado “subienda” y \"bajanza\" o “desove”), hay que conocer qué plantas sirven de refugio y alimentación a las distintas especies de peces, y la ingeniería para construir artes y técnicas de pesca y navegación.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La pesca artesanal como servicio ecosistémico cultural",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34023\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34023').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34023&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "39",
      "bookedOn": "29/11/2021 8:02:23",
      "email": "gustafocles@gmail.com",
      "name": "Gustavo Andrés Valdés Acero",
      "region": "Colombia",
      "tel": "3168714955",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "No tiene",
    "expert": "Madejenny Mora",
    "id": 6568400521377683,
    "phone": "301 2976929",
    "profile": "Lideresa de la Comuna 7. Partícipe en la creación de la Ciudadela Educativa y de diversos proyectos productivos de mujeres",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 18,
    "theme": "Les contaré sobre la importancia de los procesos barriales de la Comuna 7 de Barrancabermeja y de cómo nos organizamos para apropiarnos del territorio. Lo anterior nos permitió buscar alternativas de futuro para nuestros jóvenes en medio del conflicto armado y la falta de oportunidades en un lugar donde las diferencias se hacen evidentes. Les explicaré cómo se articularon en este proceso las Juntas de Acción Comunal –JAC– y las madres comunitarias y cabeza de familia para construir un centro de pensamiento creativo como es Paloka y la Ciudadela Educativa ¿Sabían de estos procesos?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Si nos tiran piedras es porque estamos dando frutos: construcción de la Ciudadela Educativa",
    "url": ""
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "21",
      "bookedOn": "25/11/2021 2:19:20",
      "email": "lucardenasgu@unal.edu.co",
      "name": "Luna Cárdenas Guevara",
      "region": "Bogotá D.C.",
      "tel": "3142299711",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "43",
      "bookedOn": "24/11/2021 16:05:16",
      "email": "simanconga@gmail.com",
      "name": "IRENE CELIS ARIAS",
      "region": "BARRANCABERMEJA",
      "tel": "3005702090",
      "timeBooked": " 19:20"
    },
    "email": "mgualdronsilva@gmail.com",
    "expert": "Martha Isabel Gualdrón Silva",
    "id": 6794510354015992,
    "phone": 3007722769,
    "profile": "Bióloga. Ha trabajado más de 30 años con los ríos. Vinculada actualmente con la Fundación Humedales en la gestión participativa de la conservación",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 14,
    "theme": "El río Magdalena ha sido históricamente un canal de comunicación y un epicentro de intercambios. Su navegabilidad ha estado en la mente no solo de los pobladores aledaños sino también como proyecto nacional. Me gustaría compartir con ustedes las posibilidades de navegación en este gran afluente y las intervenciones invasivas que se han realizado desde Cormagdalena sin tener en cuenta que el río posee su propia inteligencia. Muchas veces las obras de encauzamiento causan más estragos de los previstos. No se tiene en cuenta el área de influencia real del río, afectando los asentamientos, las pescas, los ecosistemas de selva húmeda y llanuras inundables de sus máximos. Propongo entender al río Magdalena como un cuerpo con el que dialogamos y aprendemos; las decisiones sobre el mismo se deben tomar con su total reconocimiento, protegiendo a sus comunidades anfibias. Podríamos así recordar a los antiguos bogas y pensar en el futuro de los pobladores ribereños.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Imitar el río: la navegabilidad en el río Magdalena",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340216\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340216').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340216&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "43",
      "bookedOn": "27/11/2021, 5:42:22 a. m.",
      "email": "carlosandrestoro@live.com",
      "name": "Carlos Andrés Toro Gutiérrez",
      "region": "Barrancabermeja",
      "tel": "3184079069",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "18",
      "bookedOn": "26/11/2021 21:54:44",
      "email": "sondiegoaa@gmail.com",
      "name": "Diego Andrés Silva Meneses",
      "region": "Barrancabermeja ",
      "tel": "3133146884",
      "timeBooked": " 18:00"
    },
    "email": "oscarcb1@gmail.com",
    "expert": "Óscar Daniel Campo",
    "id": 8588737092115397,
    "phone": 3108043848,
    "profile": "Escritor y profesor de Creación Literaria de la Universidad Central. Miembro fundador del Colectivo Editorial y Cultural del Magdalena Medio",
    "region": "Magdalena Medio",
    "socialMedia": "",
    "table": 17,
    "theme": "Contar una historia significa darle forma a una manera de mirar, de habitar y de pensar el mundo. Como grupo social necesitamos diversificar las historias que se escriben y se publican en este país. ¿Cómo hacer para que contar historias deje de ser un privilegio de clase y se convierta en un derecho de todas y todos los ciudadanos? El reparto desigual entre los que tienen y los que no tienen se corresponde con una desigualdad de los relatos y de las escrituras. El Colectivo Editorial y Cultural del Magdalena Medio responde a esa necesidad de democratizar los relatos. ¿Qué cosas les gustaría contar o escuchar sobre el Magdalena Medio? Sigan, siéntense y conversemos sobre la desigualdad de los relatos.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La desigualdad de los relatos",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340220\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340220').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340220&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "41",
      "bookedOn": "26/11/2021, 8:14:39 p. m.",
      "email": "manuelfaustinoc@hotmail.com",
      "name": "Manuel Cardozo",
      "region": "santander",
      "tel": "3124157083",
      "timeBooked": " 20:40"
    },
    "email": "palapaztoralap@tutanota.com\n\ngmeza.landines@gmail.com\n(Mantener a Gisell asistente en copia).",
    "expert": "Pastor Lisandro Alape Lascarro",
    "id": 5328300922080140,
    "phone": 3223705521,
    "profile": "Representante de los firmantes de paz ante el Consejo Nacional de Reincorporación –CNR– Integrante de la dirección del partido Comunes",
    "region": "Magdalena Medio",
    "socialMedia": "Las redes sociales de Pastor Alape son:\ntwitter: Pastor_Alape\ninstagram: pastor_alape , reincorporacioncomunes",
    "table": 8,
    "theme": "Nací rodeado del río Magdalena y sus murmullos, navegué, nadé en sus aguas y me salvé de los torbellinos de su parca, conocí la gente que habita sus orillas entre esperanzas, violencias y desplazamientos. Las dimensiones socioculturales del río pueden dar claves sobre los fenómenos de violencia, pero también ubicar experiencias comunitarias: sus narrativas, melodías, cánticos, danzas, gastronomía y formas organizativas son contribuciones para la superación de la exclusión y la inequidad. Ecobúfalo campesino es un modelo de negocio comunitario que permitió a las familias una opción económica real. Mi intervención será sobre esta experiencia y mi relación con ella, primero como actor armado y posteriormente como firmante de paz. Mi interés es contribuir a visibilizar, desde mi propia experiencia, que la civilidad, la lucha por la paz y la vida digna, tienen más imaginación y potencia que la marchita guerra.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Ecobúfalo campesino: iniciativa de organización comunitaria para construir futuro",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340210\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340210').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340210&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "22",
      "bookedOn": "29/11/2021 16:32:43",
      "email": "mariajose.diaz@unipaz.edu.co",
      "name": "María José Díaz Ruiz ",
      "region": "Barrancabermeja ",
      "tel": "3104358865",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "patonalka@yahoo.es",
    "expert": "Patricia Conde",
    "id": 8262432463321154,
    "phone": 3105793090,
    "profile": "Coordinadora de la línea de género y educación del Programa de Desarrollo por la Paz del Magdalena Medio –PDPMM–",
    "region": "Magdalena Medio",
    "socialMedia": "x",
    "table": 9,
    "theme": "Partiendo de la importancia de la reducción de brechas entre hombres y mujeres, como elemento para la construcción de paz, amplío la pregunta en torno a la contribución económica del cuidado: ¿cómo se puede reconocer este tipo de trabajo de las mujeres dentro del PIB? Me gustaría hablar con ustedes sobre las problemáticas de la distribución de las tareas dentro del hogar y la deconstrucción de las masculinidades; el acceso por parte de las mujeres a la educación y profesionalización, y su presencia en escenarios de toma de decisiones. En este sentido hago énfasis en las diferencias entre las formas de hacer política y en los aportes a la movilización social a partir de elementos sensibles propios, conocimientos válidos que se ubican fuera de las formas empíricas tradicionales occidentales.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "La disminución de las brechas de género para la construcción de paz con justicia social",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340211\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340211').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340211&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "13",
      "bookedOn": "27/11/2021 09:24:33",
      "email": "milagrosmunosporras@gmail.com",
      "name": "Yenis Milagros Muñoz Porras",
      "region": "Barrancabermeja",
      "tel": "3024741037",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "beatrimalacara@gmail.com",
    "expert": "Beatriz Vanegas Athías",
    "id": 650924144161853,
    "phone": "",
    "profile": "Poeta, escritora, docente, columnista y editora",
    "region": "Nororiente",
    "socialMedia": "",
    "table": 5,
    "theme": "Cuerpos insurrectos: poética, transformación y germinaciones de mundos posibles\nDesde el 2102, la editorial independiente ‘Corazón de mango’, que toma su nombre de un verso del poeta Raúl Gómez Jattin –“Yo tengo para mi buen amigo (un corazón de mango)”, va a contracorriente del canon mercantil de publicación y distribución de los libros de literatura que se escriben en Colombia. En este país centralista la actividad editorial se concentra en Bogotá. Incluso las editoriales independientes radicadas en esa ciudad tienen mayor visibilización que las editoriales cuya actividad se gestan en ciudades pequeñas como Floridablanca. Quisiera compartir con ustedes los desafíos poéticos y políticos que significa apostar por las escrituras de mujeres y de literatura infantil en esta región, y cómo convergen en este proyecto mi vocación literaria y mi actividad docente: todo como una forma de guarecerse del dolor.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Corazón de mango: un apuesta editorial para guarecerse del dolor",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34027\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34027').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34027&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "31",
      "bookedOn": "29/11/2021, 6:25:05 p. m.",
      "email": "karina.andrea.lopez.romero@gmail.com",
      "name": "KARINA ANDREA LÓPEZ ROMERO",
      "region": "Barrancabermeja",
      "tel": "3208646376",
      "timeBooked": " 19:20"
    },
    "email": "danielameneses1984@gmail.com",
    "expert": "Frida Daniela Meneses Ramírez",
    "id": 2662789651394310,
    "phone": "320 3247227 (Whatsapp)\n\n‎314 6636607 (llamadas)",
    "profile": "Trabajadora social en formación. Representante de la Asociación de Mujeres Trans de Norte de Santander –ASOTRANSNOR– y funcionaria de la Alcaldía de San José de Cúcuta",
    "region": "Nororiente",
    "socialMedia": "Fundacion Asotransnor\nhttps://www.facebook.com/asotransnor/",
    "table": 18,
    "theme": "Quiero contarles cómo desde ASOTRANSNOR, organización que trabaja en pro de la defensa de los derechos humanos de la población trans del Norte de Santander, buscamos brindar espacios de gestión, orientación psicosocial y jurídica a dicha población por medio de la articulación con entidades públicas y privadas para el goce efectivo de los derechos de las personas trans, población estigmatizada y discriminada en la sociedad de Cúcuta. Una de las problemáticas de este colectivo es la precarización laboral y las posibilidades de futuro. compartiré con ustedes qué hemos hecho para mitigar estas desigualdades. ¿Se animan a intercambiar ideas?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Desigualdad e inclusión laboral en la sociedad",
    "url": ""
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "luzmerylopez56@gmail.com",
    "expert": "Luz Mery López",
    "id": 485626727058821,
    "phone": "313 8248187",
    "profile": "Coordinadora de procesos sociales en Tibú a través de la Pastoral Social. Especialista en fortalecimiento de las organizaciones para la creación de pequeña y mediana empresa",
    "region": "Nororiente",
    "socialMedia": "x",
    "table": 13,
    "theme": "Me gustaría contarles cómo en la Tierra del Trueno, una de las regiones más golpeadas por el conflicto armado colombiano, hemos fortalecido organizaciones de la sociedad civil, implementado iniciativas de paz y acompañado la sustitución de cultivos en el Catatumbo. Si uno de los valores ciudadanos más importantes es la soberanía, en su sentido más amplio, creemos que es importante brindar herramientas de participación e incidencia política, económica y cultural. Desde el Catatumbo les puedo mostrar las apuestas por construir territorio a través de planes de vida comunitarios, con unas visiones de futuro propias, así como a través de la creación de empresas sostenibles.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La incidencia de procesos sociales en la Tierra del Trueno",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340215\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340215').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340215&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "63",
      "bookedOn": "11/26/2021, 8:39:53 AM",
      "email": "momacia58@gmail.com",
      "name": "Monica Macia",
      "region": "North Carolina",
      "tel": "9197446989",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "comiteambientallibano@gmail.com",
    "expert": "Hernando Alarcón",
    "id": 3455226844317935,
    "phone": 3142655839,
    "profile": "Fundador del Comité Ambiental del Líbano, Tolima. Colaborador del Consejo Regional Indígena del Cauca –CRIC– y la Asociación Nacional de Usuarios Campesinos –ANUC–",
    "region": "Centroandina",
    "socialMedia": "x",
    "table": 4,
    "theme": "Como activista por los derechos socioambientales, pintaré con palabras las amenazas hacia el medio ambiente en el norte del Tolima, así como las consecuencias sociales y las dificultades en el funcionamiento de los acueductos y los desplazamientos del campesinado. Estos fenómenos se encuentran relacionados con las minerías y los intereses de los “recién llegados”, la presencia de Anglo Gold Ashanti, las hidroeléctricas mal presentadas como sustentables a los campesinos y los nuevos monocultivos. La Marcha Carnaval se presenta entonces como un referente de la movilización social y de la creación de redes en el departamento, pues se realiza articuladamente también en Ibagué, El Espinal y Honda. ¿Hacemos red hablando?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "La Marcha Carnaval en defensa del agua, la tierra y la vida: inequidad y ambiente en el norte del Tolima",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34026\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34026').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34026&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "38",
      "bookedOn": "28/11/2021 11:23:09",
      "email": "danielsolano.com@gmail.com",
      "name": "Daniel Solano Naranjo ",
      "region": "Barrancabermeja ",
      "tel": "3043796143",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "31",
      "bookedOn": "29/11/2021 14:30:03",
      "email": "lays0311@hotmail.com",
      "name": "LAYS YELISA POTES",
      "region": "CORREGIMIENTO EL LLANITO",
      "tel": "3138167708",
      "timeBooked": " 18:00"
    },
    "email": "javiermelo264@yahoo.es",
    "expert": "Javier Melo",
    "id": 4995306867102742,
    "phone": 3134170606,
    "profile": "Campesino llegado como colono desde el Casanare. Lidera actualmente el proyecto de ecoturismo través de la Cooperativa Ecoturística de Playa Güío -COOEPLAG-",
    "region": "Suroriente: Amazonía y Orinoquía",
    "socialMedia": "x",
    "table": 12,
    "theme": "Me gustaría compartir la historia de la fundación de COOEPLAG y del proyecto de Playa Güío, vereda a 10 km del casco urbano de San José del Guaviare. Explicaré la importancia de la construcción de redes como una herramienta para hacer frente a la desigualdad, la importancia de las Juntas de Acción Comunal –JAC– en la ejecución de proyectos productivos colaborativos, y el ‘negociar sin pelear’ cuando los distintos poderes e intereses económicos y políticos se encuentran en un mismo territorio, en medio del contexto de la guerra y el abandono estatal. Es así como ante la problemática de la siembra de plantas de coca en la región, y la participación de los jóvenes y sus futuros dentro de los grupos armados, se plantean proyectos de ecoturismo para la sustitución de cultivos como estrategia de futuros posibles.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Playa Güío: una voz de esperanza para el Guaviare",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340214\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340214').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340214&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "jorgetantalo189@hotmail.com",
    "expert": "Jorge Humberto Correa Díaz",
    "id": 2874240562377511,
    "phone": "316 3945544",
    "profile": "Educador con 22 años de experiencia. Desde hace 10 años coordina la educación contratada en el Departamento del Guaviare",
    "region": "Suroriente: Amazonía y Orinoquía",
    "socialMedia": "x\nNo soy muy activo en redes, pero te envío mis datos de perfil:\n\n- Facebook: Jorge Humberto Correa Díaz\n- Twitter: @JorgeHu99817643\n\nVoy a pedir autorización de la Diócesis de San José del Guaviare para usar su perfil de Facebook, pero ese dato te lo envío mañana.",
    "table": 3,
    "theme": "Durante diez años la educación contratada en el Guaviare ha contravenido las tradiciones de la contratación pública y, por ende, ha logrado cambiar paradigmas que afectan el acceso, la permanencia y la calidad de la educación en las zonas más alejadas del departamento. La dignificación de los docentes, el acompañamiento a las comunidades colona e indígena, dispersas en las zonas rurales, la atención a las necesidades de cada escuela, la articulación con otras entidades e instituciones, el interés por conservar los entornos naturales de las escuelas, entre otros, son simples acciones que cierran brechas. Han tomado tiempo, pero ¿se podrían romper paradigmas enquistados en la cultura sin el crisol del tiempo? ¿Qué cree usted?",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Entre las trochas se cierran brechas: educación contratada en el Guaviare",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34025\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34025').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34025&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "26",
      "bookedOn": "25/11/2021 13:45:26",
      "email": "smilenahernandez11@gmail.com",
      "name": "Sandra Milena Hernández Ortíz",
      "region": "Corregimiento el centro, vereda campo 5 ",
      "tel": "3133200672",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "30",
      "bookedOn": "25/11/2021, 4:31:40 p. m.",
      "email": "fabcastro@unal.edu.co",
      "name": "Fabian Castro",
      "region": "Bogota",
      "tel": "3127337657",
      "timeBooked": " 18:00"
    },
    "email": "kelly.nukak@gmail.com",
    "expert": "Kelly Peña",
    "id": 3595019729923864,
    "phone": 3173705798,
    "profile": "Integrante de la Confluencia de Mujeres del Guaviare",
    "region": "Suroriente: Amazonía y Orinoquía",
    "socialMedia": "De la Confluencia de Mujeres tenemos en Facebook, Intagram y Twitter como @LaConflu, ahí seguramente van a poder ver nuestras redes. Sobre la llamada, esta perfecto, agradecería si es posible que nos comuniquemos el día de mañana para poder combinar. Quedo atenta al llamado de los compañeros y compañeras de Atarraya I+D.",
    "table": 3,
    "theme": "Conversaré con ustedes sobre la historia de despojo del pueblo Nükak (uno de los dos pueblos indígenas oficialmente declarados en contacto inicial) tanto de su(s) cuerpo(s) como de su(s) territorio(s). Este relato tendrá un componente visual, dado que las fotografías y los mapas constituyen las formas de comunicación preferidas de las personas de este pueblo. La narración se desarrolla en el Guaviare pero tiene un asiento importante en Yee’baka, nombre que los/las Nükak consideran más apto para llamar lo que la sociedad mayoritaria concibe como resguardo. Cerraré el relato contando un poco sobre la experiencia de resistencia de las mujeres Nükak en la elaboración del “Informe de violencia sexual” para la Comisión de la Verdad, ya que como Confluencia de Mujeres del Guaviare las acompañamos en la sistematización de sus narraciones. Les espero.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La historia de despojo del pueblo Nükak",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34025\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34025').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34025&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "30",
      "bookedOn": "28/11/2021 16:24:01",
      "email": "efrain.carvajal@outlook.com",
      "name": "Efrain Yesidt Carvajal Carvajal",
      "region": "Barrancabermeja",
      "tel": "3183124560",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "63",
      "bookedOn": "11/26/2021, 12:39:35 PM",
      "email": "momacia58@gmail.com",
      "name": "Monica Macia",
      "region": "Boyaca",
      "tel": "9197446989",
      "timeBooked": " 19:20"
    },
    "email": "lenayanina@gmail.com",
    "expert": "Lena Yanina Estrada Asito",
    "id": 2449644309702794,
    "phone": 3134921117,
    "profile": "Investigadora y lideresa indígena de la Amazonía y perteneciente a la Red Colombiana de Mujeres Indígenas en la Academia –RedColMIA–",
    "region": "Suroriente: Amazonía y Orinoquía",
    "socialMedia": "",
    "table": 7,
    "theme": "Los conocimientos tradicionales indígenas conforman mecanismos de gobierno y control reconocidos jurídicamente por naciones pluriculturales y multiculturales, que continúan sin abordarse desde una conceptualización que permita un diálogo equitativo entre la sociedad occidental y la sociedad indígena. La conversación profundizará en la base de leyes y prácticas consuetudinarias, articuladas con el sistema de organización social y política de los pueblos indígenas. Las miraremos desde la cosmovisión indígena amazónica, vislumbrando diferencias esenciales entre ambas sociedades. Espero generar un diálogo con ustedes que permita ver cómo se integran las perspectivas locales y que se reconozca el aporte silencioso que estos conocimientos han hecho al mundo globalizado, a la protección de la propiedad colectiva, la biodiversidad, el desarrollo local y la pervivencia íntegra de los pueblos.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Conocimientos tradicionales indígenas: el modelo ancestral como camino hacia el futuro",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34029\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34029').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34029&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "24",
      "bookedOn": "29/11/2021 17:48:14",
      "email": "olaffo42@gmail.com",
      "name": "Olaff Jasso",
      "region": "México",
      "tel": "5549330157",
      "timeBooked": " 16:40"
    },
    "email": "abautista@dejusticia.org",
    "expert": "Diana Isabel Güiza Gómez",
    "id": 2174244625399419,
    "phone": "300 562 6907",
    "profile": "Investigadora en asuntos de tierras, construcción de paz y movilización social (en especial, campesina). Estudiante del doctorado en Ciencia Política y Estudios de Paz de la Universidad de Notre Dame (Indiana, Estados Unidos).",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 4,
    "theme": "Los viejos problemas irresueltos por la tierra no sólo alimentaron la confrontación armada, sino también fueron agravados por la guerra y desencadenaron nuevas formas de despojo y desigualdad en la ruralidad. La centralidad de la tierra ha significado que el campesinado fue afectado desproporcionadamente durante la guerra civil y, en particular, enfrentó exclusiones en tres dimensiones. Primero, el campesinado no es reconocido públicamente como un sujeto político y de derechos. Segundo, este desconocimiento deriva en barreras estructurales en acceso a bienes públicos –tales como la tierra–, lo que implica una injusticia de redistribución. Tercero, el campesinado enfrenta obstáculos en la participación política. Frente a esas condiciones de desigualdad, desde el siglo XX el campesinado se ha movilizado y, en las últimas tres décadas, su lucha se ha concentrado en lograr vivir como quiere, vivir bien y sin humillaciones.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "La desigualdad en el uso y acceso a la tierra: uno de los factores subyacentes al conflicto armado interno en Colombia",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34026\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34026').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34026&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos y huellas ambientales",
    "bookedByOnTime1": {
      "age": "26",
      "bookedOn": "25/11/2021 2:18:06 a. m.",
      "email": "nlgarzonmo@unal.edu.co",
      "name": "Nancy Garzon",
      "region": "Bogotá",
      "tel": "3214094625",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "30",
      "bookedOn": "24/11/2021 22:07:55",
      "email": "efrain.carvajal@outlook.ocm",
      "name": "Efrain Yesidt Carvajal Carvajal",
      "region": "Barrancabermeja",
      "tel": "3183124560",
      "timeBooked": " 18:00"
    },
    "email": "aetter@javeriana.edu.co",
    "expert": "Andrés Etter",
    "id": 638293267679572,
    "phone": 3166015771,
    "profile": "Ecólogo. Ha sido profesor en el Instituto Agustín Codazzi y en la Pontificia Universidad Javeriana",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 9,
    "theme": "El territorio y los ecosistemas que lo conforman son el producto, entre otros aspectos, de la historia de la ocupación humana y los usos que la caracterizan. Hemos estudiado la salud de nuestros ecosistemas como reflejo de la sociedad, de la relación entre los habitantes y de estos con el entorno. La lista roja de ecosistemas colombianos, que hemos elaborado con estudiantes, busca medir la huella del desarrollo humano sobre el medio ambiente, conocimiento muy importante para construir políticas medioambientales. Compartiré con ustedes los resultados de esta investigación.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Ecosistemas colombianos en riesgo",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340211\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340211').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340211&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "19",
      "bookedOn": "29/11/2021 15:16:25",
      "email": "upeguissalon@gmail.com",
      "name": "Luisa Fernanda Upegui Salón",
      "region": "Barrancabermeja ",
      "tel": "3104022775",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "36",
      "bookedOn": "29/11/2021 16:01:58",
      "email": "Erik.romu@gmail.com ",
      "name": "Erika Rodríguez Muñoz",
      "region": "Barrancabermeja",
      "tel": "3134320318",
      "timeBooked": " 16:40"
    },
    "email": "panchamolanofajardo@hotmail.com",
    "expert": "Diana Marcela Molano*",
    "id": 7525850941697550,
    "phone": "Martha Fajardo - Mamá\n317 5182525",
    "profile": "Actriz y tallerista de escritura de fábulas. Autora del poemario Anochecer. Mujer con Síndrome de Down",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "mi madre Martha Fajardo y yo estamos muy pendientes. Y solamente tenemos este correo y el celular 3175182525.",
    "table": 15,
    "theme": "Hablar desde la escritura se ha convertido para Diana en una forma de aporte, de existencia y de expresión, en una sociedad que estigmatiza a las personas con sensibilidades y capacidades cognitivas diferentes, en donde la producción de conocimiento es accesible a personas que se encuentran en los parámetros normalizados de la lógica y la sociabilidad: “Al hablar de la luna, el amor, la muerte y la soledad, a través de la poesía, quiero contarles cómo escribo desde mis propias percepciones, sensibilidades y puntos de vista, puedo compartir un poema con ustedes de mi libro Anochecer. Me encanta escribir porque me puedo desahogar y expresar a partir de las metáforas y las imágenes. Podemos hablar de poetas colombianos como Porfirio Barba Jacob y Aurelio Arturo Martínez, compartir con mi madre Martha, mi compañera incansable, a ella le he escrito versos. ¡Tal vez escriba uno para ustedes!”.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Escribo todas las noches",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340217\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340217').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340217&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "41",
      "bookedOn": "26/11/2021, 8:02:51 p. m.",
      "email": "manuelfaustinoc@hotmail.com",
      "name": "Manuel Cardozo",
      "region": "santander",
      "tel": "3124157083",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "21",
      "bookedOn": "25/11/2021, 02:08:28",
      "email": "vbolivar@unal.edu.co",
      "name": "Vanessa Alejandra Bolívar Molano",
      "region": "DC",
      "tel": "321 3920576",
      "timeBooked": " 18:00"
    },
    "email": "francisco.deroux@comisiondelaverdad.co",
    "expert": "Francisco de Roux, S.J.",
    "id": 7642626558189315,
    "phone": "",
    "profile": "Presidente de la Comisión de la Verdad",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 15,
    "theme": "El desafío de la Comisión de la Verdad ha sido comprender el complejo entramado que ha sumido a Colombia en encadenamientos de guerras y muerte. Ha implicado disponerse a una escucha activa y a un diálogo cercano con las víctimas del conflicto armado para dignificarlas, y un acercamiento a las regiones para descubrir su potencial de vida. Hemos aprendido que pertenecemos a los territorios y es preciso honrarlos. Esto porque el desarrollo es la gente en su plenitud humana y en la fuerza natural del territorio: en sus bosques, en su agua, en sus fuentes de vida. La convivencia pacífica que buscamos exige una visión a largo plazo que propicie relaciones de confianza, seguras y amables, con miras a un bienestar compartido. Compartiré con ustedes estos aprendizajes que fortalecen el buen vivir y ahuyentan el miedo.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "La producción de la vida querida",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340217\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340217').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340217&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "21",
      "bookedOn": "25/11/2021, 02:02:44",
      "email": "aamayaq@unal.edu.co",
      "name": "Angie Viviana Amaya Quiroga ",
      "region": "DC",
      "tel": "3007168566",
      "timeBooked": " 19:20"
    },
    "email": "isabel.ampudia@ecopetrol.com.co",
    "expert": "Isabel Cristina Ampudia Rendón",
    "id": 5463615315744732,
    "phone": "313 4855975",
    "profile": "Gerente Regional de Entorno de la Vicepresidencia de Entorno Sostenible de Ecopetrol",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 10,
    "theme": "Compartiré con ustedes los proyectos de inversión social de Ecopetrol, y de su grupo empresarial, que tienen como propósito contribuir a la generación de desarrollo sostenible y, sobre todo, construir relaciones de confianza y de largo plazo con los distintos actores del territorio.\nEl portafolio de proyectos de inversión social de Ecopetrol, y de su grupo empresarial, se construye en consulta con los gobiernos nacional, departamental y local, así como con la sociedad y las comunidades. Sus acciones intervienen en educación y deporte, en desarrollo rural, y en emprendimiento y desarrollo empresarial. Ecopetrol promueve iniciativas de buen gobierno y participación ciudadana,  junto con gestiones conducentes al fortalecimiento institucional en las zonas donde opera. Nuestra empresa apoya la infraestructura pública y comunitaria con el fin de mejorar las condiciones de interconectividad terrestre y el acceso al agua potable de las diferentes comunidades.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "¿Cómo ha contribuido Ecopetrol a cerrar brechas sociales en aquellos territorios en donde opera?",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340212\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340212').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340212&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "johanahidrobo@gmail.com",
    "expert": "Johanna Hidrobo *",
    "id": 1749941722813859,
    "phone": 3003419915,
    "profile": "Licenciada en educación especial, guía intérprete para personas sordo-ciegas y escritora de cuentos para niños. Coordina en la Fundación Fahrenheit 451 los talleres Yo te cuento",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "Te envío las redes sociales de la Fundación Fahrenheit 451 (que es con\nla que trabajo) y por la cual estoy en el Mercado de conocimiento y no\nconocimiento.\nPágina web: http://fundacion451.com/ facebook: https://www.facebook.com/fundacion451/ Instagram: https://www.instagram.com/fundacionfahrenheit451/ twitter: https://twitter.com/fund451?lang=es  \n\nAsí mismo, estas son mis redes sociales personales. Twitter: https://twitter.com/jade1192 instagram: https://www.instagram.com/jade.1192/ Facebook: https://www.facebook.com/johana.hidrobo.1",
    "table": 11,
    "theme": "¿Cuándo fue la última vez que su voz no fue escuchada? ¿Cuándo fue la última vez que le contaron una historia? Mi conversación inicia con cuestionamientos desde nuestro ser corporal, dándole un lugar a nuestras emociones. Propongo partir de lo experiencial poniéndome/les en el lugar de un otro, haciéndome/les navegar en el sistema de percepciones para narrar historias a partir de sentidos diferentes al de la vista y así entender, de manera experiencial, la multisensorialidad.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Conduciendo a ciegas por los paisajes sonoros",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340213\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340213').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340213&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "jotacolmo@gmail.com",
    "expert": "Jorge Andrés Colmenares Molina",
    "id": 1677098960255318,
    "phone": 3158329308,
    "profile": "Antropólogo, Magíster en Ciencias Económicas, psicoanalista y escritor",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 1,
    "theme": "Resulta sorprendente que la Convención sobre los Derechos de las Personas con Discapacidad, promulgada por la Organización de las Naciones Unidas en diciembre de 2006, se refiera a varios de los derechos reconocidos en la Declaración Universal de los Derechos Humanos. Sorprende porque en muchos lugares del mundo las personas con discapacidad no son reconocidas como personas y, por tanto, fue necesario resaltar sus derechos a una escala internacional. Algunos de los conceptos incluidos en esta Convención, como diseño universal, multisensorialidad y ajustes razonables, apuntan a mejorar las condiciones de la población con discapacidad y tienen como consecuencia, al aplicarse, una mejora general de las condiciones del total de personas que la integran. La implementación de estos conceptos, en espacios como museos o en entornos educativos, contribuyen a la realización de los derechos de todos y todas en general. En la conversación veremos cómo ocurre.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "La discapacidad: una oportunidad colectiva",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34023\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34023').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34023&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "32",
      "bookedOn": "28/11/2021 18:15:36",
      "email": "giraldo.laura.m@gmail.com",
      "name": "Laura Giraldo",
      "region": "Bogotá",
      "tel": "3103245030",
      "timeBooked": "17:20"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "bernardo.toro@avina.net",
    "expert": "José Bernardo Toro",
    "id": 6080325408240693,
    "phone": 3164714476,
    "profile": "Director Programático de la Fundación Avina en Colombia",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 11,
    "theme": "La equidad y sostenibilidad de una sociedad dependen de la cantidad y calidad de bienes públicos que ésta produzca a través de sus transacciones (emocionales, sociales, políticas, culturales y espirituales), y a través del debate público en el que se defienden, apoyan y articulan intereses, se buscan metas, propósitos y objetivos que contribuyan a la vida digna de todos y al cuidado de los bienes ecosistémicos del planeta. Esto nos ubica en un paradigma del cuidado que propone la solidaridad como modelo que ordene la vida en común. De este modelo pueden surgir nuevas formas de participación de la sociedad civil, se pueden promover prácticas sustentables en empresas, y se pueden imaginar novedosos sistemas de comunicación y de educación, entre otros. Propongo hablar con ustedes de este reto de participar en la construcción de bienes públicos. Les espero.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Los bienes públicos nos permiten a todos vivir dignamente",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340213\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340213').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340213&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "48",
      "bookedOn": "25/11/2021 15:56:03",
      "email": "pclopezarci@gmail.com",
      "name": "Paula Carmenza Lopez Arciniegas",
      "region": "Barrancabermeja",
      "tel": "3114426603",
      "timeBooked": " 18:00"
    },
    "email": "jkmoyano@hotmail.com",
    "expert": "Juan Carlos Moyano",
    "id": 7555518316128415,
    "phone": 3124038145,
    "profile": "Director del Teatro Tierra",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 7,
    "theme": "Hijo de una mujer dos veces desplazada por la violencia, nací en el sur de Bogotá. Crecí en contextos hostiles, pero siempre tuve a mi favor la imaginación, los sueños y el deseo de aprender. En 1989 fundamos el Teatro Tierra. Desde entonces hemos recorrido el país y otros países forjando un camino de investigación escénica y persistencia, revelando la circunstancia humana y la memoria de los olvidados. A donde vamos nos nutrimos de las culturas y los saberes de la gente. Leemos la literatura, el teatro griego visto con ojos actuales y las vivencias de la historia. Con el trabajo aprendimos a enfrentar la realidad para transformarla en arte, creando, creyendo, superando imposibilidades, porque el teatro es pasión, descubrimiento oficioso, batalla interminable. Nuestra estética busca la parte de lo efímero que trasciende, aquello que está al otro lado de las apariencias. Con ustedes quiero compartir estas apuestas artísticas.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Teatro en resistencia",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34029\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34029').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34029&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "24",
      "bookedOn": "29/11/2021 17:44:00",
      "email": "olaffo42@gmail.com",
      "name": "Olaff Jasso",
      "region": "México",
      "tel": "5549330157",
      "timeBooked": "18:40"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "juan.f.vargas@gmail.com",
    "expert": "Juan Fernando Vargas  Duque",
    "id": 6374637307279422,
    "phone": 3203412380,
    "profile": "Profesor titular de la Facultad de Economía de la Universidad del Rosario",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 15,
    "theme": "Colombia ha sido tradicionalmente un país violento, pero al mismo tiempo ha tenido instituciones políticas democráticas. Esta paradoja se explica porque las reformas democráticas que Colombia ha implementado desde su independencia no sólo han fracasado en su intento de reducir los conflictos políticos y sociales, sino que muchas veces los han exacerbado. Esto se debe a que, en buena medida, las reformas democráticas han perpetuado la desigualdad política del país, impidiendo una participación amplia en la política de grupos diversos de la sociedad, y han permitido que las élites de poder contrarresten el espíritu democrático de las reformas. ¿Cree usted que es posible salir de esta paradoja? ¿Cómo?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "200 años de desigualdad política y conflicto en Colombia",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340217\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340217').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340217&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "41",
      "bookedOn": "26/11/2021, 7:59:14 p. m.",
      "email": "manuelfaustinoc@hotmail.com",
      "name": "Manuel F Cardozo",
      "region": "Santander",
      "tel": "3124157083",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "director.comercial@protmark.com.co",
    "expert": "Julio César Prieto*",
    "id": 975643654552513,
    "phone": "",
    "profile": "Coronel retirado del Ejército Nacional",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "Yo funjo como persona natural, la misma que cuenta que suscripciones en Facebook, Telegram y Twitter, pero son cuentas que poco utilizo y las que solo empleo de manera escasa para asuntos netamente personales, motivo por el cual no veo la posibilidad de ser a través de ellas difusor del evento.",
    "table": 7,
    "theme": "Testigo de excepción del conflicto armado, combatió a la guerrilla, paramilitares, políticos y hasta a sus propios compañeros. Su respeto a la ley lo llevó a caer en un mundo absurdo en el que la justicia, y el Estado colombiano, lo abandonaron casi hasta el ostracismo. Ha sido el único oficial de la historia de las Fuerzas Militares y de la Policía Nacional que, encontrándose en actividad, denunció y demostró en los estrados judiciales los vínculos de reconocidos dirigentes políticos del orden departamental y nacional con estructuras paramilitares. Plasmó esta cruda realidad en el libro Desenmascarando al hombre que mató a Pabló Escobar (ediciones B), contribuyendo de esta manera a conservar la memoria histórica del país y al esclarecimiento de la verdad del conflicto armado en Colombia. Compartiré con ustedes esta historia y la importancia de la verdad para alcanzar la equidad.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Verdad para la equidad y la justicia",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34029\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34029').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34029&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos y territorios de agua: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "22",
      "bookedOn": "25/11/2021, 01:50:14",
      "email": "vbolivar@unal.edu.co",
      "name": "Vanessa Alejandra Bolívar Molano",
      "region": "DC",
      "tel": "321 3920576",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "21",
      "bookedOn": "25/11/2021 2:09:53",
      "email": "lucardenasgu@unal.edu.co",
      "name": "Luna Cárdenas Guevara",
      "region": "Bogotá D.C.",
      "tel": "3142299711",
      "timeBooked": " 20:40"
    },
    "email": "giraldo.laura.m@gmail.com",
    "expert": "Laura Giraldo",
    "id": 549411470686097,
    "phone": 3103245030,
    "profile": "Filósofa y geógrafa",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 18,
    "theme": "Propondré conversar sobre las geografías de un río que se construyen, por un lado, a través de la memoria –del río– y del espacio que ha ocupado su agua y, por otro, a través de las memorias –sobre el río– que se refieren a las relaciones que las personas han creado al habitarlo, transitarlo, recordarlo y darle significado. Espero que ustedes compartan su memoria del río conmigo.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Recordar como el río: geografías del río Magdalena en su valle medio",
    "url": ""
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "liliana.angulo@gmail.com",
    "expert": "Liliana Angulo Cortés",
    "id": 4688082762483863,
    "phone": 3208173100,
    "profile": "Artista integral",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 19,
    "theme": "Hablar de inequidad desde la perspectiva de las comunidades negras, afrodescendientes, palenqueras y raizales implica reconocer que nuestros ancestros y ancestras ni siquiera eran considerados seres humanos por quienes les secuestraron en África, y que bajo esa premisa de inhumanidad fueron traídos a América para ser esclavizadxs. Los cimientos de la nación colombiana descansan sobre los cementerios no identificados, el abuso y la explotación de las personas negras que fueron esclavizadas y sus descendientes. Esos cimientos son un sistema de jerarquía racial que sigue operando en la sociedad colombiana.\nEsta nación, que se define como pluriétnica y multicultural, necesita reconocer, sanar y reparar los múltiples abusos y violencias cometidas y resolver de fondo las causas fundamentales de las injusticias y problemáticas que siguen operando sobre las comunidades negras e indígenas. Se requiere desmontar los mitos de superioridad que mantienen la violencia y explotación de unos pocos sobre una gran mayoría de gente empobrecida históricamente. Hablaré con ustedes sobre cómo en mi práctica artística trabajo estos asuntos. Les espero.",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Inequidad y jerarquía racial: una aproximación desde el arte",
    "url": ""
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "29",
      "bookedOn": "29/11/2021 8:17:10",
      "email": "lauraserranovecino@gmail.com",
      "name": "Laura Marcela Serrano Vecino",
      "region": "Barrancabermeja",
      "tel": "3023471160",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "linaalvarezv@gmail.com",
    "expert": "Lina Álvarez",
    "id": 1716726825857860,
    "phone": 3044961535,
    "profile": "Académica activista, profesora asistente del departamento de Ciencia Política de la Universidad de Los Andes",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "Sobre redes sociales: no tengo ninguna, las cerré todas hace ya un buen tiempo.",
    "table": 10,
    "theme": "Conversaremos sobre el papel que ha tenido la forma-Estado en la generación de estructuras de injusticia durante la época moderna. Nuestra hipótesis es que la causa principal de la actual crisis política se encuentra en el rompimiento que el Estado moderno realizó con respecto a la tierra y al cuerpo humano. En este proceso de desconexión, la deuda y el sistema fiscal operaron como tenaces mecanismos de poder. Para impulsar una economía comercial los soberanos contrajeron deudas y, para pagar esas deudas, transformaron el sistema fiscal, centralizando la toma de decisiones sobre la política fiscal para así poder multiplicar y ampliar los impuestos. Al hacerlo, el campesinado y los propietarios de tierras se vieron obligados a restringir tanto su consumo vital, como el uso de materias necesarias para la agricultura, pues sólo así podían cumplir con sus obligaciones fiscales. Me pregunto: ¿en qué sentido la política y la economía deben articularse a través de esta materia viviente que es la Tierra? ¿Qué preguntas se haría usted? Venga, conversemos.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Políticas de la tierra (in)habitada: una alternativa a la política estado-céntrica",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340212\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340212').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340212&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "41",
      "bookedOn": "26/11/2021, 8:10:31 p. m.",
      "email": "manuelfaustinoc@hotmail.com",
      "name": "Manuel Cardozo",
      "region": "santander",
      "tel": "3124157083",
      "timeBooked": "20:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "lucia.gonzalez@comisiondelaverdad.co",
    "expert": "Lucía González Duque",
    "id": 6908540576660110,
    "phone": 3042074737,
    "profile": "Comisionada. Comisión de la Verdad",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 15,
    "theme": "Inequidad y desigualdad tienden a concebirse como problemas económicos, sin observar otras dimensiones sociales profundamente humanas. “No tener un lugar” puede ser uno de los sentimientos más frustrantes y demoledores producto de este estado de cosas. La pérdida del estado social, la retirada de la política como herramienta de defensa de la comunidad; la producción del miedo y el mercado de la seguridad son asuntos que marcan lo más cotidiano de nuestra vida personal y también la posibilidad de una vida en comunidad. \nPara avanzar a modelos de cohabitación humana se requiere del rechazo a la humillación que está en el corazón de los modelos que producen la inequidad. Es una opción ética que parte de la noción de los otros, de la corresponsabilidad que una sociedad logre desatar, de la capacidad de empatía que aliente y de la compasión. De esta necesidad de empatía y compasión quisiera conversar con ustedes.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "Compasión y empatía por un mundo digno para todos",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340217\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340217').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340217&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "20:00",
      " 20:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "48",
      "bookedOn": "25/11/2021 23:56:26",
      "email": "pclopezarci@gmail.com",
      "name": "Paula Carmenza Lopez Arciniegas",
      "region": "Barrancabermeja ",
      "tel": "3114426603",
      "timeBooked": " 20:40"
    },
    "email": "marciacabreraantia@gmail.com",
    "expert": "Marcia Cabrera Antia",
    "id": 366996731207007,
    "phone": "",
    "profile": "Artista interdisciplinar",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "https://www.facebook.com/marcia.c.antia/\nhttps://www.facebook.com/search/top?q=el%20grito%20de%20la%20mujer-cabra\n\nhttps://www.instagram.com/marciacabrerantia/\nhttps://www.instagram.com/elgritodelamujercabra/",
    "table": 13,
    "theme": "¿Dónde me sitúo yo, ni blanca, ni negra, ni indígena? ¿Cómo me re-invento este cuerpo que es retazos de tantos otros? ¿En dónde se hacen presentes los afectos que pulsan en mi cuerpo diverso? Cuerpo ibaguereño, pijao, amazónico, nordestino, fiestero, insurrecto, marchante, callejero. Cuerpo que quiere soltarse de las relaciones de poder que lo mantienen aprisionado en el llamado “género”, en la así llamada “raza”, en la tan nombrada “clase”.\nDe El grito de la mujer-cabra, de las décimas “diferentemente iguales”, quiero conversar con ustedes. Su proceso de creación desafía los modelos que invisibilizan y callan a los cuerpos y a las voces. Crea colectivos/manadas de cuerpos-gritos singulares, de mujeres-cabras y mujeres-perras que, a ritmo vertiginoso, ponen a temblar la vida homogeneizada y sumisa.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda D",
    "timeFrameRef": "D",
    "title": "La mujer-cabra: versos y cuerpos insurrectos en tiempo(s) disonante(s)",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340215\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340215').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340215&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "46",
      "bookedOn": "27/11/2021 09:44:25",
      "email": "milagrosmunosporras",
      "name": "Erney Enrique Muñoz Plata",
      "region": "Barrancabermeja",
      "tel": "3024741037",
      "timeBooked": " 19:20"
    },
    "email": "marta.ruiz@comisiondelaverdad.co",
    "expert": "Marta Ruiz",
    "id": 8765414999388550,
    "phone": "",
    "profile": "Comisionada. Comisión de la Verdad",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 13,
    "theme": "Me interesa poner sobre la mesa los esfuerzos que se han venido haciendo desde la Comisión de la Verdad por lograr el esclarecimiento y la verdad. Compartiré algunas de las conclusiones a las que hemos ido llegando en este proceso de escucha, construcción y reflexión. Quiero hacer énfasis en la importancia de las labores de reconocimiento, no solo en lo que se refiere a las responsabilidades de los victimarios sino también a la dignificación de las víctimas. Hemos entendido el reconocimiento no como el señalamiento de una culpabilidad individual, sino como la responsabilidad de toda una sociedad que, ante sus ojos, permitió por más de 40 años las atrocidades de una guerra interna, un conflicto que se afincó en la desigualdad y que, al mismo tiempo, la profundizó. ¿Nos sentamos a conversar?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "El reconocimiento como responsabilidad y dignificación",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340215\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340215').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340215&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "46",
      "bookedOn": "27/11/2021 08:45:10",
      "email": "Wenzel.Bilger",
      "name": "Wenzel Bilger",
      "region": "Bogotá",
      "tel": "3118572960",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "27",
      "bookedOn": "27/11/2021 20:58:47",
      "email": "rfsuarezca@unal.edu.co",
      "name": "Robinson Fabián Suárez",
      "region": "Bogotá",
      "tel": "3122831075",
      "timeBooked": " 16:40"
    },
    "email": "mauricio.tu@gmail.com",
    "expert": "Mauricio Trujillo Uribe*",
    "id": 4921601410862158,
    "phone": 3007574800,
    "profile": "Dirige el programa radial Jazz Sin Fronteras",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 1,
    "theme": "Tuve que salir del país, de repente, porque mi vida estaba en riesgo. Partí con una esposa embarazada de seis meses y nuestra hija de un año, dejando atrás familia, amistades, trabajo, ciudad. Llegué a un país cuya lengua no hablaba, sin recursos económicos, sin conocer a nadie y con un invierno que abrumaba. Fue un cambio brutal e injusto.\nRegresé a Colombia veinticinco años después. Mis hijas se quedaron. Nada era como antes. Miembros de la familia ya no estaban; otros habían crecido. Amigos y conocidos no eran los mismos y yo tampoco lo era. Mi ciudad natal ahora me era ajena. El exilio y el retorno son experiencias que desajustan la vida, pero también la enriquecen: proponen problemas sociales de exclusión, pero también permiten conocer la solidaridad. Quisiera compartir con ustedes las marcas que esta experiencia ha dejado en mí y en mi familia.",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "D",
    "title": "Exilio y retorno",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34023\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34023').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34023&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "18:40",
      " 19:20"
    ],
    "body": "Cuerpos y territorios de tierra: prácticas, usos y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "21",
      "bookedOn": "25/11/2021, 02:05:41",
      "email": "vbolivar@unal.edu.co",
      "name": "Vanessa Alejandra Bolívar Molano",
      "region": "DC",
      "tel": "321 3920576",
      "timeBooked": " 19:20"
    },
    "email": "Natalia.Orduz@co.boell.org",
    "expert": "Natalia Orduz",
    "id": 8528849658392359,
    "phone": "321 2337155\n(1) 3719111",
    "profile": "Abogada con Maestría en Conservación y uso de la Biodiversidad. Coordinadora de proyectos socioambientales de la Fundación Heinrich Böll",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "x",
    "table": 20,
    "theme": "Quiero poner sobre la mesa el tema del fracking en relación con la participación, los derechos humanos y las violencias ambientales y sociales que dicha práctica puede generar. Es así como detrás de la lógica del concepto de “licencia social” no hay contenidos jurídicos constitucionales, ni tampoco se está orientado a garantizar los derechos fundamentales. La “licencia social” es una idea que desarrollan las mineras para la gestión de sus propios intereses. Les invito a debatir sobre posiciones alternativas energéticas que dejen atrás los combustibles fósiles y sus formas extremas de explotación ¿Se unen?",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda C",
    "timeFrameRef": "C",
    "title": "Las licencias del fracking y la participación ciudadana",
    "url": ""
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "Nikolai.engel@bbraun.com\nkonniev.rodriguez@bbraun.com",
    "expert": "B. Braun Avitum en Colombia: Nikolai Engel / Konniev Rodríguez*",
    "id": 3260359238042821,
    "phone": "",
    "profile": "Master en Administración de Negocios y director  / Director médico",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 13,
    "theme": "Proponemos partir de la experiencia que hemos tenido en Colombia desde los años sesenta. B. Braun es una compañía alemana dedicada a la fabricación de equipamiento médico y productos farmacéuticos desde hace más de 180 años, por lo tanto es en el sector salud en donde se encuentra nuestro principal foco de interés, ello siempre acompañado de la responsabilidad social que nos compete. Además de apoyar a determinados prestadores de salud, la diversidad regional entre nuestros trabajadores  hace parte de nuestras prioridades. También ayudamos y apoyamos las posibilidades de inversión a través de los Centro de Servicios Compartidos –CSC–, incluyendo procesos de finanzas, tecnología, recursos humanos y logística.  Nos gustaría contarles cómo el interés por el capital humano y por la creación de oportunidades ayudan a disminuir brechas y hacen parte del aumento de valor de una empresa multinacional. ¿Quieren saber cómo hemos hecho esto en Colombia?",
    "time1booked": false,
    "time2booked": false,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Creando oportunidades laborales y de inversión: ayudando al crecimiento económico de Colombia",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340215\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340215').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340215&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos",
    "bookedByOnTime1": {
      "age": "29",
      "bookedOn": "26/11/2021 06:54:16",
      "email": "anyel.duran@unipaz.edu.co",
      "name": "Anyel Durán",
      "region": "Barrancabermeja",
      "tel": "57 3122553313",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "email": "ochycuriel@gmail.com",
    "expert": "Ochy Curiel Pichardo*",
    "id": 7456191427237295,
    "phone": "",
    "profile": "Doctora en antropología. Feminista decolonial antirracista. Grupo Latinoamericano de Formación y Acción Feminista –GLEFAS–",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "No quiero que la actividad se divulgue por mis redes, ni tampoco que me hagan entrevistas previas para pasarlas por redes.",
    "table": 6,
    "theme": "El feminismo negro, desde hace ya décadas, señaló que la experiencia es fuente de conocimiento. Propongo una conversación que gire en torno a las propuestas políticas que hemos llevado a cabo quienes hemos sido racializadas, quienes no somos parte de la sexualidad hegemónica, quienes hemos apostado a la autonomía y al arte, quienes proponemos un proyecto de liberación para todxs lxs condenadxs del mundo. ¿Se unen?",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Acción política frente a la matriz de las opresiones: experiencia de una afrocaribeña decolonial",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E34028\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E34028').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E34028&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "49",
      "bookedOn": "26/11/2021 4:46:19",
      "email": "jorgetantalo189@hotmail.com ",
      "name": "Jorge Humberto Correa Díaz",
      "region": "San José del Guaviare",
      "tel": "3163945544",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "27",
      "bookedOn": "26/11/2021 21:22:24",
      "email": "monica.hernandez@unipaz.edu.co",
      "name": "Mónica Marcela Hernández Lagares",
      "region": "Barrancabermeja",
      "tel": "3046171422",
      "timeBooked": " 16:40"
    },
    "email": "rafael.pabon.g@gmail.com",
    "expert": "Rafael Pabón García*",
    "id": 6627734410006239,
    "phone": "",
    "profile": "Maestro por más de 30 años, ha tratado de superar los muros de la marginación escolar, de construir opciones de educación inclusiva y de una escuela con todos y para todos",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "Facebook: https://www.facebook.com/rafael.pabon.733\n\nTwitter: @RafaelPabonG",
    "table": 18,
    "theme": "A casi todos nosotros, en algún momento de nuestras vidas, nos anunciaron que la mejor herencia –quizá la única– que nos podían dejar nuestros mayores era la educación. Hemos creído, tal vez con ingenuidad, que la escuela es el camino para el desarrollo material y espiritual, para el progreso de las comunidades y para el ascenso social de los individuos. Sin embargo, cuanto más se expande el aparato escolar, y cuanto más crece el sistema educativo, más profundas son las brechas sociales, mayor es la exclusión y cada vez más las formas de marginación se muestran como insuperables.\nLa invitación es a dialogar sobre las causas de esa exclusión que genera la escuela, a conversar unos minutos sobre lo que significa la equidad en educación. ¿Está la escuela en capacidad de cumplir su promesa de igualdad y de justicia?",
    "time1booked": true,
    "time2booked": true,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "¿Una escuela para la equidad en un mundo desigual y diverso?",
    "url": ""
  },
  {
    "availableTimes": [
      "17:20",
      " 18:00"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": "",
      "uid": ""
    },
    "bookedByOnTime2": {
      "age": "64",
      "bookedOn": "26/11/2021 12:00:27",
      "email": "ubuencel@gmail.com",
      "name": "Ubencel Duque Rojas",
      "region": "Barrancabermeja",
      "tel": "3105793090",
      "timeBooked": " 18:00"
    },
    "email": "rocio.londonobotero@gmail.com",
    "expert": "Rocío Londoño Botero",
    "id": 4287674795782664,
    "phone": 3103293303,
    "profile": "Socióloga e historiadora. Ha publicado trabajos de investigación sobre historia agraria colombiana, organizaciones y movimientos sociales y cuestiones educativas",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "",
    "table": 10,
    "theme": "Diferentes índices internacionales ponen de relieve que Colombia es uno de los países más desiguales e inequitativos del mundo, siendo estos índices aún más acentuados en la población rural. Conviene una reflexión (individual y colectiva) sobre los factores que han mantenido a la población rural en una notoria desventaja con relación a la población urbana. Y sobre lo que habría que hacer para lograr a mediano plazo una mayor equidad. Me interesa compartir con ustedes estos factores de inequidad rural.",
    "time1booked": false,
    "time2booked": true,
    "timeFrame": "Ronda B",
    "timeFrameRef": "B",
    "title": "Equidad e inequidad en el ámbito rural colombiano",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340212\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340212').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340212&view=embed&rnd='+Math.random();\n\n</script>"
  },
  {
    "availableTimes": [
      "16:00",
      " 16:40"
    ],
    "body": "Cuerpos sociales y territorialidades: prácticas y relaciones",
    "bookedByOnTime1": {
      "age": "60",
      "bookedOn": "29/11/2021 16:04:19",
      "email": "joseraularbelaez1@hotmail.com",
      "name": "José Raúl Arbeláez ",
      "region": "Barrancabermeja ",
      "tel": "3146300195",
      "timeBooked": "16:00"
    },
    "bookedByOnTime2": {
      "age": "",
      "bookedOn": "",
      "email": "",
      "name": "",
      "region": "",
      "tel": "",
      "timeBooked": ""
    },
    "email": "grabe.vera@gmail.com",
    "expert": "Vera Grabe*",
    "id": 5473594023040196,
    "phone": 3107893427,
    "profile": "Antropóloga. Miembro del movimiento guerrillero M-19 desde sus inicios en 1974 hasta la dejación de armas en 1990",
    "region": "Bogotá, Soacha y Nación",
    "socialMedia": "Estare en es franja de 4 a 5. Luego me tengo que retirar.",
    "table": 9,
    "theme": "La paz como fuerza transformadora de nuestra realidad implica sacar la paz del plano de la utopía, y de la idea de una paz total y única, para hacer de ella una posibilidad cercana y concreta para nuestra vida personal, social, política, profesional, etc. \nEsto puede lograrse a través de una pedagogía de transformación cultural que desarticule las violencias presentes en creencias, prácticas, relaciones, lógicas y que transforme los conflictos y fortalezca la paz como práctica y postura para la familia, la escuela, el trabajo, la comunidad y el medio ambiente. Conversaremos sobre este enfoque educativo que pretende fortalecer nuestras capacidades de paz y que nos permite asumirnos como sujetos de nuestra propia transformación, con incidencia en nuestro entorno y en el ejercicio de nuestras ciudadanías. Esto implica hacer de la paz un camino, un ejercicio, un método y un objetivo. Les espero con sus saberes y preguntas.",
    "time1booked": true,
    "time2booked": false,
    "timeFrame": "Ronda A",
    "timeFrameRef": "A",
    "title": "Pedagogía para la paz",
    "url": "<iframe id=\"35CAA1A85D374EB895A3FC1D31B73E340211\" width=\"200\" height=\"200\" allow=\"autoplay;fullscreen\" allowfullscreen=\"true\" webkitallowfullscreen=\"true\" mozallowfullscreen=\"true\" oallowfullscreen=\"true\" msallowfullscreen=\"true\" frameborder=\"0\" marginheight=\"0px\" marginwidth=\"0px\"></iframe>\n\n<script>\n\ndocument.getElementById('35CAA1A85D374EB895A3FC1D31B73E340211').src='https://shares.enetres.net/live.php?source=CoreV1&v=35CAA1A85D374EB895A3FC1D31B73E340211&view=embed&rnd='+Math.random();\n\n</script>"
  }
]
const cyrb53 = function (str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

for (let index in arr) {
  arr[index]['id'] = cyrb53(arr[index]['title']);
  arr[index]['time1booked'] = false;
  arr[index]['time2booked'] = false;
  arr[index]['bookedByOnTime1'] = { name: '', email: '', tel: '', region: '', age: '', uid: '', timeBooked: '', bookedOn: '' }
  arr[index]['bookedByOnTime2'] = { name: '', email: '', tel: '', region: '', age: '', uid: '', timeBooked: '', bookedOn: '' }
}

export default arr;