import React, { lazy, Suspense, useEffect } from 'react';
import listaDeEventosLocal from '../../listaDeEventosUnique.js';

import { Intro, Title, Grid, Imagen } from './style';
import { get, ref, push } from '@firebase/database';
import { auth, database } from '../../firebase/config.js';

const BookingsVis = (props) => {
    const [expertxArr, setExpertxs] = React.useState(listaDeEventosLocal);
    const [escuchaArr, setEscucha] = React.useState([]);
    const getExpertxsList=()=>{
        get(ref(database, 'chats'))
        .then(async (data) => {
            setExpertxs(data.val());
        });
    }
    const getEscuchaArr=()=>{
        get(ref(database, 'escuchaVirtual'))
        .then(async (data) => {
            console.log('data',data.val());
            setEscucha(data.val());
        });
    }
    useEffect(() => {
        getEscuchaArr();
        getExpertxsList();
    } , []);
    console.log('p',escuchaArr)
    return (
        <div>
            <h1>
                Lista de agendamiento personal:
            </h1>
            <div className='bookedItem'>
                {expertxArr.filter((item)=>item.time1booked || item.time2booked ).map((localItem, index)=>{
                    console.log('localItem',localItem)
                    return(
                        <div key={index}>
                            <h3>{localItem.title}</h3>
                            {localItem.time1booked ? (
                                <div>
                                    {Object.keys(localItem.bookedByOnTime1).map((key)=>{
                                        return(
                                            <div key={key}>
                                                {key} : {localItem.bookedByOnTime1[key]}
                                            </div>
                                        )
                                    })}
                                <br/>
                                </div>
                            ): ''}
                            {localItem.time2booked ? (
                                <div>
                                    {Object.keys(localItem.bookedByOnTime2).map((key)=>{
                                        return(
                                            <div key={key}>
                                                {key} : {localItem.bookedByOnTime2[key]}
                                            </div>
                                        )
                                    })}
                                <br/>
                                </div>
                            ): ''}
                        </div>
                    )
                })}
            </div>
            <h1>
                Lista de agendamiento de escucha virtual:
            </h1>
            <div className='bookedItem'>
                {Object.keys(escuchaArr).map((item)=>{

                    console.log('item',item)
                    return(
                        <div key={item}>
                            {Object.keys(escuchaArr[item]).map((key)=>{
                                return(
                                    <div key={key}>
                                        {key} : {escuchaArr[item][key]}
                                    </div>
                                )
                            })}
                            <br/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default BookingsVis;
