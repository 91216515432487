import styled from "styled-components";

export const Wrapper = styled.div`
    transition: all 0.3s ease-in-out;
    position: fixed;
    top: 5vh;
    left:20vw;
    width: 40vw;
    height: 90vh;
    padding: 3vh 2vw;
    box-sizing: border-box;
    color: var(--textBlack);
    background-color: var(--bgWhite);
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);


     @media (max-width: 900px) {
      width: 90vw;
      height: 90vh;
      left: 5vw;
      top: 5vh;
      z-index: 101;
    }

    h3 {
    font-family: 'Source Sans Pro';
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.2em;
    }

`;

export const AudioWrapper = styled.div`
height: 16%;
display: flex;
align-items: center;
justify-content: center;
gap: 1vw;
;

font-family: Source Code Pro;
font-size: 25px;
font-style: normal;
font-weight: 300;
line-height: 27px;
letter-spacing: 0em;
text-align: left;

`;

export const TextWrapper = styled.div`
    border-top: 2px solid var(--textGray);
    border-bottom: 2px solid var(--textGray);
    height: 70%;
    overflow: auto;
    padding: 2vh 2vw;

    font-family: 'Source Sans Pro';
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.2em;
`;
