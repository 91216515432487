import styled from "styled-components";

export const StreamWindow = styled.div`
    width: 100%;
    margin: 20px auto 10px;
    .stream{
        border 1px solid #ccc;
        border-radius: 19px;
        width: 100%;
        height: 70vh;
    }
`;

export const StreamWrap = styled.div`
position: fixed;
top: 2vh;
left: 1vw;
width: 90vw;
height: auto;
z-index:200;
background: var(--bgWhite);
padding: 2vh 3vw 1vh;
box-shadow: 1px 1px 5px var(--textGray);
font-size: .9em;
.closeBtn{
    position: absolute;
    right: 10px;
    top: 10px;
}
`;