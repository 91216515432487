import React from 'react'
import { Wrapper,  TextWrapper } from './style'

const ComoNavegarPop = ({toggle}) => {


    return (
        <Wrapper>
            <div className='popHeader'>
                <h2 onClick={toggle}>Preguntas frecuentes</h2>
                <button id="closePopNav" className="closePop" onClick={toggle}>X</button>
            </div>
            
            <TextWrapper>
                <div>
                <h3>¿De qué se trata este encuentro?</h3>
                <p>
                    Es un espacio escénico y público para el diálogo en el cual 70 personas, procedentes de muchos lugares de Colombia, conversarán sobre las formas de superar las inequidades históricas de este país.
                </p>
                <h3>¿Por qué en Barrancabermeja?</h3>
                <p>
                    Barrancabermeja, al borde del gran río Magdalena y cobijada por una exuberante vegetación, ha sido históricamente un epicentro de los conflictos armados del país. En esta ciudad convergen la explotación minera, la pesca, el encuentro de diversidades, la movilización y las luchas sociales, por lo que aquí se yuxtaponen múltiples memorias de la Nación. Es pues el lugar idóneo para poner sobre la mesa el tema de las inequidades y desigualdades que se han construido en nuestro país y cómo las estamos superando.
                </p>
                <h3>¿Cuándo y dónde se realizará?</h3>
                <p>
                    Se realizará el 2 de diciembre en el Espacio Teatral Corporación A VER TEATRO, en la Carrera 11 con calle 52 (esquina) del Barrio Olaya Herrera en Barrancabermeja. Hay que llegar 20 minutos antes de la hora agendada para sus conversaciones.
                </p>
                <h3>¿Quiénes son las sabedoras y sabedores, expertas y expertos?</h3>
                <p>
                    Son 70 personas entre las que se encuentran académicos, empresarios, líderes sociales y firmantes de paz. La lista de todos los expertos y expertas, sabedores y sabedoras, está publicada en www.acontracorriente.co y en nuestras redes sociales.
                </p>
                <h3>¿Tiene algún costo?</h3>
                <p>
                    No, es completamente gratuito.
                </p>
                <h3>¿Cómo puedo vincularme?</h3>
                <p>
                    Participando activamente como asistentes de manera presencial o virtual. Y también promoviendo la difusión del encuentro entre estudiantes, jóvenes, organizaciones sociales, JAC, etc. Así mismo puede apoyarnos con la proyección del evento a nivel comunitario el día 2 de diciembre. Para más información puede contactarnos a través de nuestras redes sociales @merconocimiento 
                </p>
               </div>
               <div>
                   <h3>¿Quiénes lo organizan?</h3>
                   <p>
                       Este es un proyecto artístico para la Comisión de la Verdad, de la Academia Móvil de Berlín, adaptado y realizado por Mapa Teatro, en cooperación con el Goethe-Institut. Financiado por el Ministerio de Relaciones Exteriores de la República Federal de Alemania y el Goethe-Institut.
                    </p>
                    <h3>¿Cómo participar?</h3>
                    <p>
                        Para ser interlocutor presencial de esas 70 expertas y expertos, sabedoras y sabedores es necesario inscribirse previamente a través del sitio web: www.acontracorriente.co Podrán agendar hasta cuatro (4) conversaciones en Agendar conversación presencial. Si solo quiere escuchar vía streaming debe clicar en Espacio de escucha virtual. 
                    </p>
                    <h3>¿Qué temas se van a abordar?</h3>
                    <p>
                        Temáticas en torno a las diferentes dimensiones de las inequidades y las formas de ir a contracorriente de ellas. Las conversaciones se agruparon en cinco cuerpos:
                    </p>
                    <p><b className="bullet">1.</b>Cuerpos y huellas ambientales: conflicto armado y ecosistemas.</p>
                    <p><b className="bullet">2.</b>Cuerpos y territorios de agua: prácticas, usos y relaciones: conflicto armado, cuidado del agua, navegación y pesca.</p>
                    <p><b className="bullet">3.</b>Cuerpos y territorios de tierra: prácticas, usos y relaciones: agriculturas, conflicto armado, fracking, migraciones, posesión de tierras, reservas y soberanía alimentaria.</p>
                    <p><b className="bullet">4.</b>Cuerpos sociales y territorialidades: prácticas y relaciones: comunicación, conflicto armado, discriminaciones de género y étnicas, economía, educación, justicia y política.</p>
                    <p><b className="bullet">5.</b>Cuerpos insurrectos: poéticas, transformaciones y germinaciones de mundos.</p>
               </div>
            </TextWrapper>
        </Wrapper>
    )
}

export default ComoNavegarPop;