import styled from "styled-components";
export const Feedback = styled.div`
#feedbackText img{
    margin-right: 10px;
}
position: fixed;
width: 380px;
height: 302px;
top: 25%;
box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
background-color: var(--bgWhite);
border-radius: 5px;
padding: 25px 40px;

@media (max-width: 900px) {
    width: 85vw;
    left: 5vw;
    top: 15vh;
}

#header{
    color: var(--darkBlue);
    text-align:center;
    font-size:24px;
}
#feedbackWrapTopRight{
    margin-right: 10px;
    margin-left: auto;
    text-align: right;
    margin-bottom: 10px;
    font-weight: bold;
}
#feedbackButtons{
    display: grid;
    margin: 30px auto 10px auto;
    text-align: center;
    grid-template-columns: 1fr ;
}
.feedbackButtonOrange{
    border: 1px solid var(--darkBlue);
    width: 137px;
    margin: 0px auto;
    border-radius: 5px;
    display: block; 
    padding: 5px 20px;
    color: var(--darkBlue);
}
.buttonOrange>img{
    margin: 0px auto auto;
}
#feedbackText{
    margin-top: 20px;
}
`;
export const EscuchaWrap = styled.div`
    transition: all 0.3s ease-in-out;
    position: fixed;
    top: 5vh;
    left:20vw;
    width: 537px;
    height: max-content;
    padding: 2vh 2vw;
    color: var(--textBlack);
    background-color: var(--bgWhite);
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    @media (max-width: 900px) {
      width: 85vw;
      left: 5vw;
      top: 5vh;
      z-index: 101;
    }

    h2{
        font-size: 1.2em;
        font-weight: 900;
        color: var(--titleBlack);
    }

    .line{
        border-bottom: 1px solid var(--textGray);
        line-height: 2em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 900px) {
            flex-direction: column;
            border: none;
        }
    }

     .inputWrap {
        width: 90%;
        @media (max-width: 900px) {
            border-bottom:1px solid var(--textGray);
        }
    }

    input{
        background: none;
        border: none;
        line-height: 3em;
        display: inline;
        padding: 1vh 2vw;
        outline: none;
        font-size: 1.1em;
    }

    #termsAndConditionsWrap {
        padding: 2vh 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            padding-right: 1vw;
        }
        text-transform: uppercase;
        font-weight: 600;
        font-size: .8em;
        color: var(--textGray);
    }

    #submitWrap{
        padding: 2vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;

        button{
            border: 1px solid var(--darkBlue);
            border-radius: 5px;
            background: none;
            padding: .7em;
            color: var(--darkBlue);
            font: 900 .95em "Source Sans Pro";
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
    }
`;