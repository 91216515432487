import styled from "styled-components";

export const Wrapper = styled.div`
    transition: all 0.3s ease-in-out;
    position: fixed;
    top: 5vh;
    left: 20vw;
    width: 40vw;
    height: 90vh;
    padding: 3vh 2vw;
    box-sizing: border-box;
    color: var(--textBlack);
    background-color: var(--bgWhite);
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    @media (max-width: 900px) {
      width: 90vw;
      left: 5vw;
      top: 5vh;
      height: 90vh;
      z-index: 101;
    }

    h3 {
    font-family: 'Source Sans Pro';
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.2em;
    }
`;

export const TextWrapper = styled.div`
    border-top: 2px solid var(--textGray);
    border-bottom: 2px solid var(--textGray);
    height: 85%;
    overflow: auto;
    padding: 2vh 2vw;
    margin-top: 2vh;

    font-family: 'Source Sans Pro';
    font-size: .9em;
    font-weight: 400;
    line-height: 1.2em;
    hyphens: none;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3%;
`;
