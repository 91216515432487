import {React, useEffect, useState} from 'react'
import { AgendarWrap, AgendarListaWrap, Feedback } from './style';
import { get, ref, push, set, update } from '@firebase/database';
import { getAuth, createUserWithEmailAndPassword } from '@firebase/auth';
import { database, firestoreDb } from '../../firebase/config';
import { addDoc, collection } from '@firebase/firestore';
import { uploadBytes, ref as storageRefFunction, getDownloadURL, listAll } from "firebase/storage";
import listaDeEventosLocal from '../../listaDeEventosUnique.js';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'

// create a request to firebase that has {userId, cuerpoId, time}

let cuerposFilterArr = ["Cuerpos y huellas ambientales",
"Cuerpos y territorios de agua",
"Cuerpos y territorios de tierra",
"Cuerpos sociales y territorialidades",
"Cuerpos insurrectos",
"Todos los cuerpos"]

let regionesFilterArr =["Caribe e insular",
"Costa Pacífica",
"Antioquia y la región cafetera", 
"Surandina",
"Magdalena Medio", 
"Nororiente",
"Centroandina", 
"Suroriente: Amazonía y Orinoquía",  
"Bogotá, Soacha y Nación",
"Todas las regiones",]

const Agendar = props => {
    const [showFinalFeedback, setShowFinalFeedback] = useState(false);
    const [showConfirmation, setConfirmationShow] = useState(false);
    const [isFormValid, setFormValid] = useState(true);
    const [regionFilterOpen, setRegionFilterOpen] = useState(false);
    const [cuerpoFilterOpen, setCuerpoFilterOpen] = useState(false);   
    const [listaDeExpertxsQuery, setListaQuery] = useState(listaDeEventosLocal);
    const [cuerpoFilter, setCuerpoFilter] = useState(cuerposFilterArr);
    const [regionFilter, setRegionFilter] = useState(regionesFilterArr); 
    const [selectedCuerpo, setSelectedCuerpo] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const [isCaptchaValid, setCaptchaValid] = useState(false);
    const [isTermsChecked, setTermsChecked] = useState(false);
    const [isBarrancaChecked, setBarrancaChecked] = useState(false);
    const [currentExpertxsIndex, setCurrenExpertxs] = useState(0);
    const [listaDeExpertxs, setCuerpos] = useState([]);
    const [timeSlotBookedIndex, setTimeSlotBookedIndex] = useState(null);
    const [currentCheckedEvent, setCurrentCheckedEvent] = useState({index1:null});
    const [currentCheckedEventPage, setCurrentCheckedEventPage] = useState(null);
    const [currentUserFormData, setCurrentUserFormData] = useState({name:'',email:'',tel:'',region:'',age:''});
    const handleSetSelectedCuerpo = (e, item) => {
        if(item === 'Todos los cuerpos') {
            setSelectedCuerpo('');
        } else {
            setSelectedCuerpo(item);
        }
    }
    const handleSetSelectedRegion = (e, item) => {
        if(item === 'Todas las regiones') {
            setSelectedRegion('');
        } else {
            setSelectedRegion(item);
        }
    }
    useEffect(() => {
        setListaQuery(listaDeExpertxs.filter(item => expertxsFilter(item)));
        setCurrenExpertxs(0);
        setCurrentCheckedEventPage (null);
    }, [selectedCuerpo, selectedRegion]);
    const expertxsFilter = (item, index) => {
        // if(
        //     return true;
        // }
        let flag = false;

        if(item.body.includes(selectedCuerpo) && item.region.includes(selectedRegion)) {
            flag = true;
        }
        if(selectedCuerpo === '' && selectedRegion === '') {
            flag = true;
        }

        return flag;
    }

    const onLoadRecaptcha=() =>{
        if (captcha) {
            captcha.reset();
        }
    }
    const toggleCuerpoFilter = (e) => {     
        setCuerpoFilterOpen(!cuerpoFilterOpen);
    }
    
    const toggleRegionFilter = (e) => {
        setRegionFilterOpen(!regionFilterOpen);
    }
    const toggleConfirmation = (e) => {
        e.preventDefault();
        if(!checkFormData()){
            console.log('form not valid');
            return;
        } else {
            setConfirmationShow(!showConfirmation);
        }
    }
    const verifyCallback=(recaptchaToken)=> {
      // Here you will get the final recaptchaToken!!!
      setCaptchaValid(recaptchaToken);
      console.log(recaptchaToken, "<= your recaptcha token")
    }
    const setCurrenExpertxsIndex = (val) => {
        val = currentExpertxsIndex + val;
        if(val > listaDeExpertxs.length-1) {
            setCurrenExpertxs(0);
        } else if(val < 0) {
            setCurrenExpertxs(listaDeExpertxsQuery.length-1);
        } else {
            setCurrenExpertxs(val);
        }
        setCurrentCheckedEventPage(null);
        setCurrentCheckedEvent({index1:null});
    }
    const setEventForSubmit = (e, chat, indexLocal) => {
        e.preventDefault()
        for(let index in listaDeExpertxs){
            if(listaDeExpertxs[index].title === chat.title){
                setCurrentCheckedEvent({chat: chat, index1: index });
                setCurrentCheckedEventPage(indexLocal);
                console.log('event set', chat, index);
            }
        }
        setTimeSlotBookedIndex(null);
        // createEvent()
    }
    const toggleTerms = (e) => {
        setTermsChecked(!isTermsChecked)
    }
    const toggleBarranca = (e) => {
        setBarrancaChecked(!isBarrancaChecked)
    }
    const updateCuerposArray = (index, object) => {
        let newArray = listaDeExpertxs;
        Object.keys(object).forEach(key => {
            newArray[index][key] = object[key]
        })
        setCuerpos(newArray);
    }
    useEffect(() => {
        console.log('useEffect', props)
        loadReCaptcha();
        if(props.expertxs) {
            setCuerpos(props.expertxs);
            setListaQuery(props.expertxs);
        } else {
            getExpertxs();
        }
    } , []);
    const setExpertxs = (expertxs) => {
        if(expertxs) {
            let arr = [];
            Object.keys(expertxs).forEach((key) => {
                arr.push(expertxs[key]);
            })
            setCuerpos(arr);
        }
    }
    const getExpertxs = () => {
        get(ref(database, 'chats'))
        .then(async (data) => {
            setCuerpos(data.val());
            setListaQuery(data.val());
        });
    }
    const checkFormData = (e) => {
        let isValid = true;
        Object.keys(currentUserFormData).forEach(key => {
            if(currentUserFormData[key] === '') {
                console.log('invalid', key)
                isValid = false;
            }
        })
        if(timeSlotBookedIndex === null) {
            console.log('timeSlotBookedIndex is null')
            isValid = false;
        }
        if(currentCheckedEvent.index1 === null) {
            console.log('currentCheckedEvent.index1 === null')
            isValid = false;
        }
        if(!isTermsChecked) {
            console.log('terms not checked');
            isValid = false;
        }
        if(!isBarrancaChecked) {
            console.log('barranca not checked');
            isValid = false;
        }
        if(!isCaptchaValid) {
            console.log('isCaptchaValid', isCaptchaValid)
            isValid = false;
        }
        setFormValid(isValid);
        return isValid;
    }
    const sendAgendarRequest=(e)=>{
        e.preventDefault();
        
        let object = {}
        object[`time${timeSlotBookedIndex}booked`] = true;
        object[`bookedByOnTime${timeSlotBookedIndex}`] = currentUserFormData;
        object[`bookedByOnTime${timeSlotBookedIndex}`]['timeBooked'] = currentCheckedEvent.chat.availableTimes[timeSlotBookedIndex-1];
        object[`bookedByOnTime${timeSlotBookedIndex}`]['bookedOn'] = new Date().toLocaleString();

        console.log('current data',object, currentCheckedEvent)
        get(ref(database, `chats/${currentCheckedEvent.index1}/time${timeSlotBookedIndex}booked`))
        .then(async (data) => {
            if(data.val() === true) {
                console.log('time already booked');
                alert('El horario seleccionado ya esta ocupado, por favor seleccione otro horario y refrescar la pagina');
                return;
            } else {
                push(ref(database, `chatRequests`),{user: object, event: currentCheckedEvent})
                .then(async (data) => {
                    console.log('res', data);
                });
                update(ref(database, `chats/${currentCheckedEvent.index1}`),object)
                .then(async (data) => {
                    updateCuerposArray(currentExpertxsIndex, object);
                    console.log('res', data);
                    getExpertxs();
                    toggleConfirmation(e);
                    setShowFinalFeedback(true);
                    addDoc(collection(firestoreDb, "mail"), {
                        to: currentUserFormData.email,
                        message: {
                            subject: "Confirmacion de agendado - A Contracorriente",
                            html: `<html>
                            <head>
                            <title>Mercado del conocimiento (comunicación) (Preview)</title>
                            <meta name="viewport" content="width=device-width, initial-scale=1">
                            <style>body{font-family:'Helvetica','Arial',sans-serif;margin:0;padding:32px 16px;background:#eeeeee;}h2{color:#111;font-size:24px;font-weight:bold;margin:0;}h3{color:#666;font-size:14px;font-weight:normal;margin:8px 0;}.email{max-width:1100px;margin:auto;}.email__creative{display:flex;justify-content:space-around;margin:30px 0 100px;}.email__frame{border:0;}.email__frame--small{width:320px;height:568px;border-radius:8px;}.email__frame--large{width:700px;height:700px;}.email__header{background:#fff;border-radius:4px;padding:16px;}.preview{padding-top:12px;padding-bottom:12px;border-top:1px solid #ddd;border-bottom:1px solid #ddd;margin-top:8px;}.preview__text{font-size:14px;}.preview__text--subject{color:#202124;}.preview__text--preheader{color:#5f6368;opacity:0.7;}
                            </style>
                            </head>
                            <body><div class="emails"><div class="email"><div class="email__header"><h2>Confirmacion de agendamiento - Presencial</h2>
                            <table class="preview"><tr class="preview__row"><td class="preview__text preview__text--subject">
                            Gracias ${currentUserFormData.name} por agendar con nosotros.
                            <br/>
                            Su cita esta programada para el dia 2 de Diciembre del 2021 a las ${currentCheckedEvent.chat.availableTimes[timeSlotBookedIndex-1]} horas.
                            Con el expertx ${currentCheckedEvent.chat.expert} para la charla ${currentCheckedEvent.chat.title}. En la mesa ${currentCheckedEvent.chat.table} para la ronda ${currentCheckedEvent.chat.timeFrameRef}. 
                            <br/>
                            Por favor procura llegar 30 minutos antes de la hora de la charla.
                            </td>
                            <td class="preview__text preview__text--preheader">
                            </td></tr></table></div><div class="email__creative"><div class="email__col email__col--large"><h3></h3> <iframe class="email__frame email__frame--large" style="width:600px;height:1822px;" src="mail-a-copia/index.html"></iframe></div><div class="email__col email__col--small"><h3></h3> <iframe class="email__frame email__frame--small" src="mail-a-copia/index.html"></iframe></div></div></div></div>
                            </body>
                            </html>`
                        },
                    });
                });
            }
        });
        
      }
    const handleFormChanges = (e) => {
        setCurrentUserFormData({
            ...currentUserFormData,
            [e.target.id]: e.target.value
        })
        checkFormData();
    }
    const selectTime = (val) => {
        setTimeSlotBookedIndex(val);
    }
    return (
        <AgendarWrap>
            <div className='popHeader'>
                <h2 onClick={props.toggle}>AGENDAR CONVERSACION PRESENCIAL</h2>
                <button className="closePop" onClick={props.toggle}>X</button>
            </div>
            <div id='agendarBody'>
                <form>
                    <div className="line">
                    <div id='nombreWrap' className='inputWrap'>
                        <img src='media/users.svg' alt='icon-user' />
                        <input id='name' type='text' placeholder='Nombre completo*' onChange={handleFormChanges} />
                    </div>
                    </div><div className="line">
                    <div id='emailWrap' className='inputWrap'>
                        <img src='media/mail.svg' alt='icon-user' />
                        <input id='email' type='text' placeholder='Correo electrónico*' onChange={handleFormChanges}/>
                    </div>
                    <div id='telefonoWrap' className='inputWrap'>
                        <img src='media/phone.svg' alt='icon-user' />
                        <input id='tel' type='text' placeholder='Número telefónico*' onChange={handleFormChanges}/>
                    </div>
                    </div><div className="line">

                    <div id='lugarWrap' className='inputWrap'>
                        <img src='media/map-pin.svg' alt='icon-user' />
                        <input id='region' type='text' placeholder='Lugar de procedencia*' onChange={handleFormChanges}/>
                    </div>
                    <div id='edadWrap' className='inputWrap'>
                        <img src='media/gift.svg' alt='icon-user' />
                        <input id='age' type='text' placeholder='Edad*' onChange={handleFormChanges}/>
                    </div>
                    </div>
                    <div id='filtro'>
                        <div className='filtroCat' id='filtroCuerpo' onClick={toggleCuerpoFilter}>
                            <img src='media/filter.svg' alt=""/>
                            <b>FILTRAR POR CUERPO</b>
                            <img src='media/down.svg' alt=""/>
                        </div>
                        <div className='filtroCat' id='filtroRegion' onClick={toggleRegionFilter}>
                            <img className='mobileOnly' src='media/filter.svg' alt=""/>
                            <b>FILTRAR POR REGION</b>
                            <img src='media/down.svg' alt=""/>
                        </div>
                        <div className='filtroCuerpoLista'>
                            {cuerpoFilterOpen ? (
                                <>
                                    {cuerpoFilter.map((item, index) => {
                                        let cuerpoColor;
                                switch (index) {
                                    case 0:
                                        cuerpoColor = 'var(--cuerpoAmbientales)';
                                        break;
                                    case 1:
                                        cuerpoColor = 'var(--cuerpoAgua)';
                                        break;
                                    case 2:
                                        cuerpoColor = 'var(--cuerpoTierra)';
                                        break;
                                    case 3:
                                        cuerpoColor = 'var(--cuerpoSociales)';
                                        break;
                                    case 4:
                                        cuerpoColor = 'var(--cuerpoInsurrectos)';
                                        break;
                                    default:
                                        cuerpoColor = 'var(--titleBlack)';
                                }
                                        return(
                                            <div className='filterItemWrap' key={index} onClick={(e)=>{handleSetSelectedCuerpo(e, item)}}>
                                                {selectedCuerpo === item || selectedCuerpo === '' ? (
                                            <svg width="19" height="19" viewBox="0 0 20 20" fill={cuerpoColor} xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.5 9.5C18.5 14.4707 14.4707 18.5 9.5 18.5C4.52929 18.5 0.5 14.4707 0.5 9.5C0.5 4.52929 4.52929 0.5 9.5 0.5C14.4707 0.5 18.5 4.52929 18.5 9.5Z" stroke={cuerpoColor} />
                                            </svg>
                                        ) : (
                                            <svg width="19" height="19" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.5 9.5C18.5 14.4707 14.4707 18.5 9.5 18.5C4.52929 18.5 0.5 14.4707 0.5 9.5C0.5 4.52929 4.52929 0.5 9.5 0.5C14.4707 0.5 18.5 4.52929 18.5 9.5Z" stroke={cuerpoColor} />
                                            </svg>                                                )}
                                                <div>
                                                    {item}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            ):('')}
                        </div>
                        <div className='filtroCuerpoLista'>
                            {regionFilterOpen ? (
                                <>
                                    {regionFilter.map((item, index) => {
                                        return(
                                            <div className='filterItemWrap' key={index} onClick={(e)=>{handleSetSelectedRegion(e, item)}}>
                                                {selectedRegion === item || selectedRegion === ''? (
                                                    <img src='media/selected_chat.svg' alt=""/>
                                                ):(
                                                    <img src='media/unselected_chat.svg' alt=""/>
                                                )}
                                                <div>
                                                    {item}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            ):('')}
                        </div>
                    </div>
                    <AgendarListaWrap>
                        <div id='agendarListaDeEventos'>
                            {listaDeExpertxsQuery.filter((item, index)=> {
                                return window.innerWidth < 900 ? 
                                (index === currentExpertxsIndex) :
                                true
                            }
                            ).map((cuerpo, index1) => {
                                    return(
                                        <div key={index1} className='eventoItemWrap'>
                                            <div id={`check${index1}`} onClick={(e)=>{setEventForSubmit(e, cuerpo, index1)}}>
                                                {currentCheckedEventPage === index1 ? (
                                                    <div className='check'>
                                                        <img src='media/selected_chat.svg' alt='icon-check' />
                                                    </div>
                                                ):(
                                                    <div className='check'>
                                                        <img src='media/unselected_chat.svg' alt='icon-check' />
                                                    </div>
                                                )}
                                            </div>
                                            <div className='eventoInfoWrap'>
                                                <b>{cuerpo['title']}</b>
                                                <br/>
                                                {cuerpo['expert']} | <b>Ronda {cuerpo['timeFrameRef']}</b> | 
                                                {cuerpo['time1booked']?(''):(<b> {cuerpo['availableTimes'][0]}</b>)} 
                                                {!cuerpo['time1booked'] && !cuerpo['time2booked'] ?(<b> o</b>):('')} 
                                                {cuerpo['time2booked']?(''):(<b>{cuerpo['availableTimes'][1]} </b>)}
                                                {cuerpo['time1booked'] && cuerpo['time2booked'] ?('Este evento ya esta agendado'):('')} 
                                                |
                                                <b> mesa {cuerpo['table']}</b>
                                                <br/>
                                                {cuerpo['profile']}
                                                <br/>
                                                {cuerpo['region']}
                                                <br/>
                                                <b
                                                className={
                                                    cuerpo['body'].includes('agua') ? 'cuerpoAgua' : 
                                                    cuerpo['body'].includes('tierra') ? 'cuerpoTierra' : 
                                                    cuerpo['body'].includes('ambientales') ? 'cuerpoAmbientales' : 
                                                    cuerpo['body'].includes('sociales') ? 'cuerpoSociales' : 
                                                    cuerpo['body'].includes('insurrectos') ? 'cuerpoInsurrectos' :('') 
                                                } 
                                                >{cuerpo['body']}</b>
                                                <br/>
                                                {currentCheckedEventPage === index1 ? (
                                                    <div>
                                                        {!cuerpo['time1booked'] || !cuerpo['time2booked'] ?('Seleccione una hora para agendar:'):('')} 
                                                        {cuerpo['time1booked']?(''):(
                                                            <div onClick={()=>{selectTime(1)}}>
                                                                <b>{cuerpo['availableTimes'][0]}</b>
                                                                {timeSlotBookedIndex === 1 ? (
                                                                    <img src='media/selected_chat.svg' alt='icon-arrow' />
                                                                ) : (
                                                                    <img src='media/unselected_chat.svg' alt='icon-arrow' />
                                                                )}
                                                            </div>
                                                        )}
                                                        {!cuerpo['time1booked'] && !cuerpo['time2booked'] ?(<b>o</b>):('')} 
                                                        {cuerpo['time2booked']?(''):(
                                                            <div onClick={()=>{selectTime(2)}}>
                                                                <b>{cuerpo['availableTimes'][1]}</b>
                                                                {timeSlotBookedIndex === 2 ? (
                                                                    <img src='media/selected_chat.svg' alt='icon-arrow' />
                                                                ) : (
                                                                    <img src='media/unselected_chat.svg' alt='icon-arrow' />
                                                                )}
                                                            </div>
                                                        )}
                                                        {cuerpo['time1booked'] && cuerpo['time2booked'] ?(
                                                            <div>
                                                                Este evento ya esta agendado.
                                                            </div>
                                                        ):('')}

                                                    </div>
                                                ):('')}
                                            </div>
                                        </div>
                                    )
                                }    
                            )}
                        </div>
                        {/* <div id='agendarNav'>
                            <div id='agendarNavTop' onClick={()=>{setCurrenExpertxsIndex(-1)}}>
                                <img src='media/chevrons-up.svg' alt='icon-arrow-up'/>
                            </div>
                            <div id='agendarNavBottom' onClick={()=>{setCurrenExpertxsIndex(1)}}>
                                <img src='media/chevrons-down.svg' alt='icon-arrow-down'/>
                            </div>
                        </div> */}
                    </AgendarListaWrap>
                    <div id='barrancaWrap'>
                        {isBarrancaChecked ? (
                            <img src='media/selected_chat.svg' alt='icon-check' onClick={toggleBarranca}/>
                        ) : (
                            <img src='media/unselected_chat.svg' alt='icon-check' onClick={toggleBarranca}/>
                        )}
                        <p><b><u>IMPORTANTE</u>: esta es una conversación presencial.</b><br/>
                        Asegúrate de estar en <b>Barracabermeja (Santander, Colombia)</b> el 02 de diciembre y llegar media hora antes al lugar indicado.                    
                        </p>
                    </div>
                    <div id='termsAndConditionsWrap'>
                        {isTermsChecked ? (
                            <img src='media/selected_chat.svg' alt='icon-check' onClick={toggleTerms}/>
                        ) : (
                            <img src='media/unselected_chat.svg' alt='icon-check' onClick={toggleTerms}/>
                        )}
                            He leído y acepto la Política de uso de datos
                    </div>
                    <div id='submitWrap'>
                    <ReCaptcha
                        ref={(el) => {setCaptcha(el);}}
                        size="normal"
                        render="explicit"
                        sitekey="6LcGjUgdAAAAALlaAYawYKFJxMtf6gGdRghkEyPy"
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={verifyCallback}
                    />
                        <button type='submit' color="var(--orange)" onClick={toggleConfirmation}><img src="media/icons/calendar.svg" alt="" />Agendar</button>
                        {isFormValid ? ('') : ('Formulario invalido')}
                    </div>
                </form>
            </div>
            {showConfirmation? (
                <div id='confirmationWrap'>
                    <div id='confirmationWrapTop'>
                        <div id='confirmationWrapTopLeft'></div>
                        <div id='confirmationWrapTopRight' onClick={toggleConfirmation}>X</div>
                        Usted está a punto de agendar una cita con las siguientes personas, ¿desea confirmar?
                    </div>
                    <div id='confirmationText'>
                        <img src='media/users.svg' alt='icon-check'/>
                        <b>{currentCheckedEvent.chat.expert} </b> 
                        | 
                        Presencial, 
                        <b>{currentCheckedEvent.chat.availableTimes[timeSlotBookedIndex-1]} horas</b>
                    </div>
                    <div id='confirmationButtons'>
                        <div className='buttonOrange' onClick={toggleConfirmation}>
                            <img src='media/list.svg' alt='icon-check'/>
                            Editar
                        </div>
                        <div className='buttonOrange' onClick={sendAgendarRequest}>
                            <img src='media/calendar.svg' alt='icon-check'/>
                            <div>Confirmar</div>
                        </div>
                    </div>
                </div>
            ) : ('')}
            {showFinalFeedback? (
                <Feedback>
                    <div id='feedbackWrapTop'>
                        <div id='feedbackWrapTopLeft'></div>
                        <div id='feedbackWrapTopRight' onClick={(e)=>{
                            setShowFinalFeedback(false)
                            props.toggle(e)
                            }}>X</div>
                        <div id='header'>
                            Te has inscrito correctamente a la conversación presencial 
                            el 02 de diciembre.
                        </div>
                    </div>
                    <div style={{textAlign:'center', lineHeight:'1.5rem'}} id='feedbackText'>    
                        Recuerda estar media hora antes en Espacio teatral Corporación A VER TEATRO.
                        Carrera 11 con calle 52 esquina
                        Barrio Olaya Herrera.
                    </div>
                    <div id='feedbackButtons'>
                        <div className='feedbackButtonOrange' onClick={(e)=>{
                            setShowFinalFeedback(false)
                            props.toggle(e)
                            }}>
                            <div>ACEPTAR</div>
                        </div>
                    </div>
                </Feedback>
            ) : ('')}
        </AgendarWrap>
        )
    }
    


Agendar.propTypes = {

}

export default Agendar
