import { initializeApp } from "@firebase/app";
import { getDatabase } from "@firebase/database";
import { getStorage } from "@firebase/storage";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
var config = {
    apiKey: "AIzaSyAX7woaeFo-BhIju6-gteyLWownJAAA_Kc",
    authDomain: "comision-conferencia.firebaseapp.com",
    projectId: "comision-conferencia",
    storageBucket: "comision-conferencia.appspot.com",
    messagingSenderId: "915567681914",
    appId: "1:915567681914:web:f605fbca2e5355b5a776c3",
    measurementId: "G-3BPVV1Y9EG"
  };

export const app = initializeApp(config);
export const storage = getStorage(app);
export const database = getDatabase();
export const auth = getAuth(app);
export const firestoreDb = getFirestore(app);