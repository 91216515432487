import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    
    :root {
      --titleBlack: #022601;
      --textBlack: #242424;
      --iconGray: #494943;
      --darkBlue: #3C5282;
      --orange: #F26100;
      --bgWhite: #FFFAF1;
      --white: #FFFFFF;
      --textGray: #9D9D9C;
      --red: #FF0000;
      --transparentWhite: rgba(255, 250, 241, 0.9);
      --green: ##70E05C;
      --blue: #59C7F7;
      --darkRed: #91302B;
      --yellow: #F7CD59;
      --orchid: #BB55ED;
      --cuerpoSociales: #F7CD59;
      --cuerpoInsurrectos: #BB55ED;
      --cuerpoAgua: #59C7F7;
      --cuerpoTierra:  #91302B;
      --cuerpoAmbientales: #70E05C;
    }
    #mesasWrap {
      padding: 30px 0px;
    }
    #mesasWrap h1 {
      text-align: center;
      margin-bottom: 10px;
    }
    #mesasWrap h2 {
      text-align: center;
      color: var(--orange);
    }
    #mesasGrid{
      text-align: center;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 1rem;
    }
    .mesa:hover{
      transform: translate(-2px, -2px);
      transition: all 0.3s ease-in-out;
      color: var(--orange);
    }
    .mesa{
      cursor: pointer;
      border: 1px solid var(--orange);
      transition: all 0.3s ease-in-out;
    }
    body {
      background-color: var(--bgWhite);
      margin: 0;
      width: 100vw;
      font-family: 'Source Sans Pro', sans-serif;
      -ms-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      overflow-x: hidden;
    }

    h2 {
      font-family: Source Sans Pro;
      font-size: 1.5em;
      font-style: normal;
      font-weight: 900;
      line-height: 1.7em;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;

    }

    .popHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        h2{
        font-family: Source Sans Pro;
        font-size: 1.1em;
        font-style: normal;
        font-weight: 900;
        line-height: 1.2em;
        letter-spacing: 0em;
        text-align: left;
        }

    }

    @media (max-width: 900px) {
      #grid {
        grid-template-columns: 1fr;
      }
    }
    @media (min-width: 900px) {
      #grid {
        grid-template-columns: 20% 80%;
      }
    }

    #grid {
      top: 70vh;
      display: grid;
      grid-gap: 3vw;
      padding: 5vh 5vw 5vh 0;
    }

    main {
      position: relative;
      top: 65vh;
      padding: 5vh 5vw;
    }

    .closePop{
        border:none;
        background:none;
        font-size: 1.2em;
        font-weight: 900;
        text-transform: uppercase;
        color: var(--iconGray);
    }

    .mobileOnly {
      @media (min-width: 900px) {
        display:none;
      }
    }

    .desktopOnly {
      @media (max-width: 900px) {
        display:none;
      }
    }

    #creditos {
      margin-top: 500px;
      width: 100vw;
      background-color: #022601;
      img{
        width: 100%;

      }
    }
    .cuerpoSociales{
      color: var(--cuerpoSociales);
   }
   .cuerpoInsurrectos{
       color: var(--cuerpoInsurrectos);
   }
   .cuerpoAgua{
       color: var(--cuerpoAgua);
   }
   .cuerpoTierra{
       color: var(--cuerpoTierra);
   }
   .cuerpoAmbientales{
       color: var(--cuerpoAmbientales);
   }

`;

export default GlobalStyle;
