import styled from "styled-components";

export const HeaderContainer = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    margin: 0;
    padding: 0;
`;

export const HeaderImage = styled.img`
    width: 100%;
    object-fit: cover;
    height:100%;
    position: absolute;
    z-index: -1; 
`;

export const SocialLink = styled.a`
    border:none;
    background:transparent;
    box-sizing: border-box;
    padding: .5vh;
    img{
        width: 2vh;
        height: 2vh;
    }
`;

export const Title = styled.h1`
    font-family: 'Source Serif Pro';
    font-weight: ${props => props.bold ? 700 : 300};
    font-size: 2.25em;
    color: var(--titleBlack);
    background-color: var(--bgWhite);
    margin: .5vh 0 ;
    padding: 0 1vw;
    width: fit-content;

    @media (max-width: 900px) {
        font-size: 2em;
    }
`;


export const SocialLinksContainer = styled.div`
    background: ${props => props.gray ? 'var(--iconGray)' : 'var(--bgWhite)'};
    border-radius: 5vh;
    margin: auto;
    padding: .5vh;
    width: auto;

    ${props => props.gray && 'margin-bottom: 4vh'}
`;

export const TitleContainer = styled.div`
    position: absolute;
    top: 40vh;
    width: auto;
    left: 20vw;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 900px) {
        left: 2vw;
    }
`;

export const MenuItem = styled.button`
    font-family: 'Source Sans Pro';
    font-size: 1.15em;
    font-weight: 600;
    color: ${(props) => props.color || 'var(--titleBlack)'};
    background-color: var(--bgWhite);
    border: none;
    padding: 3px 10px;
    margin: 0 0 0 auto ;
    width: fit-content;
    box-shadow: 1px 1px 5px var(--textGray);
    text-align: right;
    cursor:pointer;

    img{
        padding-left: 35px;
    }
`;

export const NavMenu = styled.nav`
    position: fixed;
    top: 4vh;
    right: 5vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    z-index: 100;

    @media only screen and (max-width: 600px) {
        right: 5vw;
        top: 2vh;
    }

    #menuButton:hover {
        nav{
            display: flex;
        }
    }
`;

export const SocialMenu = styled.nav`
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 5vw;
    bottom: 5vh;
    gap: 1vh;
    @media (max-width: 900px){
        bottom: 5vh;
    }
`;

export const ExpandedMenu = styled.nav`
    background: var(--bgWhite);
    
    display:none;
    border-radius: 5px;
    padding: 1vh 1vw;
    width: fit-content;
    
    position: fixed;
    right: 14.5vw;
    top: 5vh;

    box-shadow: 1px 1px 5px var(--titleBlack);

    /* for when it switches to flex */
    flex-direction: column;
    a, a:active {
        text-transform: uppercase;
    font-family: Source Sans Pro;
    text-decoration: none;
    font-size: .95em;
    font-style: normal;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.05em;
    text-align: left;
    color: var(--textBlack);
    border: none;
    background: none;
    cursor: pointer;
    }

    hr {
        width: 100%;
        border-top: .5px solid var(--textGray);
        margin: .5vh 0;
        cursor: default;
    }

    #flechaContainer {
        width: 10vw;
        height: 10vh;
        position: absolute;
        right: -10vw;
        top: .5vh;
        text-align: left;
    }

    img {
        width: 1.5vw;
        height: auto;
        padding: 0 2px;;
        margin: 0;
    }
    `;

export const SongButton = styled.button`
    padding: .6vh;
    box-sizing: border-box;
    border:none;
    background:transparent;
    svg{
        width: 2vh;
        height: 1.5vh;
    }
`;