import React, { useState } from 'react';
import {
    HeaderImage, TitleContainer, SocialMenu, HeaderContainer,
    SocialLink, NavMenu, Title, SocialLinksContainer,
    SongButton, MenuItem, ExpandedMenu
} from './style';
import Agendar from '../../popUps/agendar';
import EscuchaVirtual from '../../popUps/escucha';
import ComoNavegarPop from '../../popUps/ComoNavegarPop';
import Preguntas from '../../popUps/Preguntas';

const Header = (props) => {

    const [agendarIsOpen, setOpenAgendar] = useState(false);

    const toggleAgendar = (e) => {
        e.preventDefault()
        window.scrollTo(0, 0);
        setOpenAgendar(!agendarIsOpen)
        if (escuchaIsOpen) setOpenEscucha(false);
        if (comoNavegarIsOpen) setOpenComoNavegar(false);

    }

    const [escuchaIsOpen, setOpenEscucha] = useState(false);

    const toggleEscucha = (e) => {
        e.preventDefault()
        setOpenEscucha(!escuchaIsOpen)
        if (agendarIsOpen) setOpenAgendar(false);
        if (comoNavegarIsOpen) setOpenComoNavegar(false);
        if (preguntasIsOpen) setOpenPreguntas(false);
    }

    const [comoNavegarIsOpen, setOpenComoNavegar] = useState(false);

    const toggleComoNavegar = (e) => {
        e.preventDefault()
        setOpenComoNavegar(!comoNavegarIsOpen)
        if (escuchaIsOpen) setOpenEscucha(false);
        if (agendarIsOpen) setOpenAgendar(false);
        if (preguntasIsOpen) setOpenPreguntas(false);
    }

    const [preguntasIsOpen, setOpenPreguntas] = useState(false);

    const togglePreguntas = (e) => {
        e.preventDefault()
        setOpenPreguntas(!preguntasIsOpen)
        if (escuchaIsOpen) setOpenEscucha(false);
        if (agendarIsOpen) setOpenAgendar(false);
        if (comoNavegarIsOpen) setOpenComoNavegar(false);
    }

    return (
        <HeaderContainer>
            <HeaderImage src="media/header.png" alt="" />

            <TitleContainer>
                <Title bold={true}>A contracorriente de las inequidades.</Title>
                <Title>Bogando por el presente hacia (otros)</Title>
                <Title>futuros posibles</Title>

            </TitleContainer>
            <NavMenu>
                <MenuItem id="menuButton" type="button">Menú<img src="media/icons/list.svg" alt="" />
                    <ExpandedMenu>
                        <a href='#sobreElMercado'>
                            Sobre el mercado
                        </a>
                        <hr />
                        <a href='#curaduria'>
                            Curaduría
                        </a>
                        <hr />
                        <a href='#mapa'>
                            Mapa de regiones y cuerpos
                        </a>
                        <hr />
                        <a href='#expertxs'>
                            Expertxs / sabedorxs
                        </a>
                        <hr />
                        <a href='#creditos'>
                            Créditos
                        </a>
                        <div id="flechaContainer">
                            <img src="media/flechaMenu.svg" alt="" />
                        </div>
                    </ExpandedMenu>
                </MenuItem>
                {/* <MenuItem type="button" onClick={toggleAgendar} color="var(--orange)">Agendar conversación presencial<img src="media/icons/calendar.svg" alt="" /></MenuItem> */}
                {/* <MenuItem type="button" onClick={toggleEscucha} color="var(--darkBlue)">Inscribirse para escucha virtual<img src="media/icons/radio.svg" alt="" /></MenuItem> */}
                <MenuItem type="button" onClick={toggleComoNavegar} >Cómo navegar<img src="media/icons/compass.svg" alt="" /></MenuItem>
                <MenuItem type="button" onClick={togglePreguntas}>Preguntas frecuentes<img src="media/icons/question.svg" alt="" /></MenuItem>
            </NavMenu>

            <SocialMenu>
                <SocialLinksContainer gray={true}>
                    <SongButton onClick={() => {
                        const audioElt = document.getElementById("mercadoSong");
                        audioElt.paused ? audioElt.play() : audioElt.pause();
                    }}>
                        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0L8 4.5L0 9V0Z" fill="#FFFAF1" />
                        </svg>
                        <audio id="mercadoSong" src="media/audio/musica.wav" />
                    </SongButton>
                </SocialLinksContainer>
                <SocialLinksContainer>
                    <SocialLink href="https://instagram.com/merconocimiento" target="_blank" rel="noopener noreferrer"><img src="media/icons/instagram.svg" alt="Instagram" /></SocialLink>
                </SocialLinksContainer>
                <SocialLinksContainer>
                    <SocialLink href="https://m.facebook.com/MerConocimiento/" target="_blank" rel="noopener noreferrer"><img src="media/icons/facebook.svg" alt="Facebook" /></SocialLink>
                </SocialLinksContainer>
                {/*<SocialLinksContainer>
                    <SocialLink href="/" target="_blank" rel="noopener noreferrer"><img src="media/icons/youtube.svg" alt="YouTube" /></SocialLink>
                </SocialLinksContainer>*/}
                <SocialLinksContainer>
                    <SocialLink href="https://twitter.com/merconocimiento" target="_blank" rel="noopener noreferrer"><img src="media/icons/twitter.svg" alt="Twitter" /></SocialLink>
                </SocialLinksContainer>
                {/*
                <SocialLinksContainer>
                <SocialLink href="/" target="_blank" rel="noopener noreferrer"><img src="media/icons/deezer.svg" alt="" /></SocialLink>
                <SocialLink href="/" target="_blank" rel="noopener noreferrer"><img src="media/icons/spotify.svg" alt="" /></SocialLink>
                <SocialLink href="/" target="_blank" rel="noopener noreferrer"><img src="media/icons/soundcloud.svg" alt="" /></SocialLink>
                <SocialLink href="/" target="_blank" rel="noopener noreferrer"><img src="media/icons/apple.svg" alt="" /></SocialLink>
                <SocialLink href="/" target="_blank" rel="noopener noreferrer"><img src="media/icons/podcast.svg" alt="" /></SocialLink>
                </SocialLinksContainer>
            */}
            </SocialMenu>
            {comoNavegarIsOpen && <ComoNavegarPop toggle={toggleComoNavegar} ></ComoNavegarPop>}
            {escuchaIsOpen && <EscuchaVirtual toggle={toggleEscucha} ></EscuchaVirtual>}
            {agendarIsOpen && <Agendar toggle={toggleAgendar} {...props}></Agendar>}
            {preguntasIsOpen && <Preguntas toggle={togglePreguntas} ></Preguntas>}
        </HeaderContainer>
    )
}

export default Header;
