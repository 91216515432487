import React from 'react';

const Cargando = () => {
    return (
        <div>
            Cargando
        </div>
    )
}

export default Cargando;
