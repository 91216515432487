import styled from "styled-components";


export const Title = styled.h3`
text-transform:uppercase;
font-weight: 900;
font-size: 1.56em;
width: 70%
`;

export const Location = styled.div`
color: #F26100;
padding-bottom: 16px;
`;

export const Text = styled.div`
font-family: Source Sans Pro;
font-style: normal;
font-weight: bold;
font-size: 17px;
line-height: 27px;
/* or 159% */

font-feature-settings: 'tnum' on, 'onum' on;

/* general/titulos */

color: #022601;
`;

export const Sticky = styled.div`
// position: -webkit-sticky; /* Safari */
// position: sticky;
top: 0;
`;
