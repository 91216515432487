import styled from "styled-components";

export const Intro = styled.p`
width: 72%;

font-family: Source Sans Pro;
font-size: 1.1em;
font-style: normal;
font-weight: 600;
line-height: 1.6em;
text-align: left;

color: var(--titleBlack);

margin-bottom: 8vh;
`;

export const Title = styled.h3`
text-transform:uppercase;
font-weight: 900;
font-size: 1.56em;
width: 70%
`;

export const Column = styled.div`
`;

export const SectionContainer = styled.section`
font-family: 'Source Sans Pro';
`;

export const Grid = styled.div`
display: grid;
grid-gap: 3vw;
@media (max-width: 430px) {
    grid-template-columns: 1fr; 
}
@media (min-width: 430px) {
    grid-template-columns: auto auto;
}
`;