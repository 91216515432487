import React, { useEffect } from 'react';
import { StreamWindow, StreamWrap } from './style';

const StreamPopUp = (props) => {
    const [id, setId] = React.useState(null);
    const [url, setUrl] = React.useState(null);
    console.log(props);
    const extractIdFromString = (string) => {
        let div = document.createElement('div');
        div.innerHTML = string;
        setId(div.getElementsByTagName('iframe')[0].id);
        let extractedUrl = string.match(/.src='(.*?)'/);
        setUrl(extractedUrl[1]);
    }
    useEffect(() => {
        extractIdFromString(props.url);
    },[])
    return (
        <StreamWrap>
            <div>
                <h1>Mesa {props.mesa}</h1>
                <img className='closeBtn' onClick={()=>{console.log('sup');props.toggle();}} src='media/x.svg' alt=""/>
            </div>
            <StreamWindow>
                <iframe src={`${url}${Math.random()}`} 
                id={id}
                className='stream' 
                allow="autoplay;fullscreen" 
                allowFullscreen="true" 
                webkitallowfullscreen="true" 
                mozallowfullscreen="true" 
                oallowfullscreen="true" 
                msallowfullscreen="true" 
                frameborder="0" 
                marginheight="0px" 
                marginwidth="0px"></iframe>
            </StreamWindow>
        </StreamWrap>
    )
}

export default StreamPopUp;
