import React from 'react';
import { Intro, Title, Grid, SectionContainer } from './style';

const SobreElMercado = () => {
    return (
        <SectionContainer>
            <Intro>
                Un proyecto artístico para la <u>Comisión de la Verdad</u> de la <u>Academia Móvil de Berlín</u>, adaptado y realizado por <u>Mapa Teatro</u> en cooperación con el <u>Goethe-Institut</u>. Financiado por el <u>Ministerio de Relaciones Exteriores de la República Federal de Alemania</u> y el <u>Goethe-Institut</u>.
            </Intro>

            <Title>Conversar para convivir: el Legado de la Comisión de la Verdad y el Mercado del Conocimiento y No-Conocimiento Útil en Barrancabermeja</Title>
            <Grid>
                <div>
                    <p>
                        El 2 de diciembre de 2021 llegarán a Barrancabermeja, Distrito Capital del Magdalena Medio, 70 personas procedentes de diversos lugares de Colombia con el propósito de conversar, con habitantes de la región, sobre diferentes formas de hacer frente a las profundas inequidades que aquejan al país. Se encontrarán en la segunda versión colombiana del Mercado del Conocimiento y No-Conocimiento Útil que, para la presente ocasión, hemos llamado: <i>A contracorriente de las inequidades. Bogando por el presente hacia (otros) futuros posibles.</i>
                    </p>
                    <p>
                        Barrancabermeja, situada a orillas del río Magdalena y cobijada por una exuberante vegetación, ha sido epicentro de los conflictos armados del país. En esta ciudad convergen la explotación minera, la pesca, el encuentro entre diversidades, la movilización y las luchas sociales, yuxtaponiendo múltiples y diversas memorias. Es pues el lugar propicio para poner sobre la mesa de conversación el tema de las inequidades y desigualdades del país.
                    </p>
                    <p>
                        La primera versión del proyecto artístico en Colombia tuvo lugar en noviembre de 2019; su temática giró en torno al agua llevando por título <i style={{ textDecoration: 'underline' }}>Culturas anfibias, puertos de contenedores y tumbas líquidas: relatos colombianos en torno al agua.</i>
                    </p>
                    <p>
                        El Mercado del Conocimiento y No-Conocimiento Útil fue creado por la artista alemana Hannah Hurtzig junto a un grupo de artistas, científicos y arquitectos que hacen parte de la Academia Móvil de Berlín –MAB–, y desde el año 2004 ha tenido veintitrés versiones en veinte ciudades del mundo. El mercado dispone un espacio escénico de escucha y conversación, acogedor y libre, llamado ágora: un espacio público para el diálogo. Allí se comparten, en conversaciones de a dos y en simultáneo, saberes y conocimientos individuales y colectivos, fruto de la experiencia y la investigación. Surge entonces un enjambre, un bullicio y un murmullo: una polifonía que da cuenta de las voces que se proponen el reto de vivir juntxs sin arrasarse.
                    </p>
                </div>
                <div>
                    <p>
                        Para finales del 2021, la Comisión para el Esclarecimiento de la Verdad, la Convivencia y la No Repetición estará por finalizar sus labores de investigación. Estará a seis meses de entregar a la sociedad el informe, las recomendaciones y el acumulado de conocimientos, mensajes y experiencias –tanto individuales como colectivos– para contribuir al entendimiento de lo sucedido durante tantos años de guerra en Colombia.
                    </p>
                    <p>
                        <i>La entrega del Legado</i> es el nombre que la Comisión de la Verdad ha dado a este proceso. Para ello se han diseñado espacios de encuentro y diálogo que permitan generar reflexiones profundas en la sociedad, tanto sobre las razones explicativas de la persistencia del conflicto armado como sobre las diferentes propuestas que tienen por objetivo el logro de la reconciliación y la paz. Es en este marco que la Comisión de la Verdad invitó al segundo Mercado del Conocimiento y No-Conocimiento Útil a vincularse a este proceso, reconociendo que este espacio abre diálogos, desde diversas y complementarias perspectivas, que permiten abordar las problemáticas de la desigualdad y la inequidad, así como las posibles respuestas comunitarias ante ellas y proponer sus posibles transformaciones, para el logro de una sociedad más equitativa y justa y, por lo tanto, con condiciones para alcanzar la paz. Esta es una tarea a la que todos podemos aportar. Invitamos a unirse a esta conversación. Sin la participación presencial de las y los habitantes de Barrancabermeja, y del Magdalena Medio, este espacio no tiene sentido. Les invitamos a agendar sus conversaciones y hacer parte del Legado de la Comisión de la Verdad. Para ello pueden pulsar el ícono Brújula: “Cómo navegar”. Allí encontrarán cómo agendar una conversación cuerpo a cuerpo con un(a) sabedorx / expertx o registrarse para la escucha virtual.
                    </p>
                </div>
            </Grid>
        </SectionContainer>
    )
}

export default SobreElMercado;
