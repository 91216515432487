import React, { lazy, Suspense, useEffect } from 'react';
import Cargando from './components/Cargando';
import Evento from './homeSections/Evento';
import Stream from './components/stream';
import MesaPopUp from './components/mesaPopUp';
import TimerComponent from './components/timer';
import Header from './homeSections/Header';
import SobreElMercado from './homeSections/SobreElMercado';
import HashLinkObserver from 'react-hash-link';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, setPersistence } from '@firebase/auth';
import { get, ref, push } from '@firebase/database';
import { auth, database } from './firebase/config.js';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import BookingsVis from './internalUse/bookingsVis';
import listaDeEventosUnique from './listaDeEventosUnique.js'
import listaDeUrlsLocal from './listaDeUrls.json'

const ComoNavegar = lazy(() => import('./homeSections/ComoNavegar'));
const Creditos = lazy(() => import('./homeSections/Creditos'));
const Curaduria = lazy(() => import('./homeSections/Curaduria'));
const Expertxs = lazy(() => import('./homeSections/Expertxs'));
const Mapa = lazy(() => import('./homeSections/Mapa'));


function App() {
    const [userId, setUserId] = React.useState(null);
    const [expertxArr, setExpertxs] = React.useState(null);
    const [listaDeUrls, setListaDeUrls] = React.useState(listaDeUrlsLocal);
    const [currentMesa, setCurrentMesa] = React.useState(null);
    const [isStreamPopUpVisible, setIsStreamPopUpVisible] = React.useState(false);

    const getExpertxsList = () => {
        get(ref(database, 'chats'))
            .then(async (data) => {
                setExpertxs(data.val());
            });
    }
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            setUserId(user.uid);
            // ...
            console.log('uid', user, user.uid);
        } else {
            console.log('else');
            // User is signed out
            // ...
        }
    });
    const getListaDeUrls = () => {
        get(ref(database, 'streamUrls'))
            .then(async (data) => {
                setListaDeUrls(data.val());
            });
    }
    const toggleStreamVisible = () => {
        console.log('toggleStreamVisible');
        setIsStreamPopUpVisible(!isStreamPopUpVisible);
    }

    useEffect(() => {
        getExpertxsList();
        getListaDeUrls();
    }, []);
    
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Header {... {
                            userId: userId, expertxs: expertxArr,
                        }} />
                        <main>
                            <HashLinkObserver />
                                {/* <div id='mesasWrap'>
                                    <h1>Transmision en vivo</h1>
                                    <TimerComponent></TimerComponent>
                                    <div id='mesasGrid'>
                                        {listaDeUrls.map((url, index) => {
                                            return (
                                                <div className='mesa' key={index} onClick={()=>{
                                                    toggleStreamVisible();
                                                    setCurrentMesa(index);        
                                                }}><h3>Mesa {url.mesa}</h3></div>    
                                            )
                                        })}
                                    </div>
                                </div>
                                {isStreamPopUpVisible ? (
                                    <MesaPopUp {...{
                                        mesa: listaDeUrls[currentMesa].mesa, 
                                        url: listaDeUrls[currentMesa].url, 
                                        toggle:toggleStreamVisible 
                                    }} />
                                ):(' ')} */}
                            <div id='grid'>
                                <Evento />
                                <article>
                                    <div id='sobreElMercado'>
                                        <SobreElMercado />
                                    </div>
                                    <Suspense fallback={<Cargando></Cargando>}>
                                        <div id='curaduria'>
                                            <Curaduria />
                                        </div>
                                    </Suspense>
                                    <Suspense fallback={<Cargando></Cargando>}>
                                        <ComoNavegar />
                                    </Suspense>
                                </article>
                                <Suspense fallback={<Cargando></Cargando>}>
                                    <div id='mapa'>
                                        <Mapa {...expertxArr} />
                                    </div>
                                </Suspense>
                            </div>
                            <Suspense fallback={<Cargando></Cargando>}>
                                <div id='expertxs'>
                                    <Expertxs {...expertxArr} />
                                </div>
                            </Suspense>
                        </main>
                        <Suspense fallback={<Cargando></Cargando>}>
                            <Creditos />
                        </Suspense>
                    </Route>
                    <Route exact path="/listadeagendamiento">
                        <BookingsVis {...{ expertxs: expertxArr }} />
                    </Route>
                    {listaDeUrls.map((url, index) => {
                        return (
                            <Route exact path={`/stream/mesa${url.mesa}`} key={index}>
                                <Stream {...{ mesa: url.mesa, url: url.url }} />
                            </Route>
                        )
                    })}
                </Switch>
            </Router>


        </>
    )
}

export default App;
