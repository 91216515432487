import styled from "styled-components";

export const StreamWindow = styled.div`
    width: 100%;
    margin: 20px auto;
    .stream{
        border 1px solid #ccc;
        border-radius: 19px;
        width: 100%;
        height: 82vh;
    }
`;

export const StreamWrap = styled.div`
 margin: 0 30px;
`;