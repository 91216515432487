import React, {useState, useEffect} from 'react'
import { Wrapper,  TextWrapper, AudioWrapper } from './style'

const ComoNavegarPop = ({toggle}) => {

    const [audioTime, setAudioTime] = useState("00:04:35");

    function getFormattedTime(timeInSeconds) {
        let minutes, seconds;

  // calculate time in minutes
  const timeInMinutes = timeInSeconds / 60;

  // calculate formatted time
  minutes = Math.floor(timeInMinutes);
  seconds = Math.floor((timeInMinutes - Math.floor(timeInMinutes)) * 60);

    // add a zero before the value if it's less than 10
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;

  return `00:${minutes}:${seconds}`;
}

    useEffect(() => {

        const updateTime = () => {
        const audio = document.getElementById('audioComoNavegar')
            if (audio) {
                setAudioTime(getFormattedTime(audio.currentTime));
            }
        }

        document.getElementById('audioComoNavegar').addEventListener('timeupdate', updateTime);
    }, [])

    return (
        <Wrapper>
            <div className='popHeader'>
                <h2 onClick={toggle}>cómo navegar este sitio</h2>
                <button id="closePopNav" className="closePop" onClick={toggle}>X</button>
            </div>
            <AudioWrapper>
                <img src="media/icons/play-black.svg" alt="play / pause" onClick={() => {
                    const audio = document.getElementById('audioComoNavegar');
                    audio.paused ? audio.play() : audio.pause();
                }}/>
                <span>{audioTime}</span>
                <img src="media/icons/mute.svg" alt="mute" onClick={() => {
                    document.getElementById('audioComoNavegar').pause();
                }}/>
                <audio id="audioComoNavegar" src="media/audio/comoNavegar.mp4"></audio>
            </AudioWrapper>
            <TextWrapper>
            <h3>Cómo Agendar una conversación presencial con un(a) sabedorx / expertx</h3>
                <p><b className="bullet">1.</b>En el Menú General, ubicado en la esquina superior derecha, pulse el segundo ícono Agendar conversación presencial, allí encontrará el formulario para el agendamiento. 
                </p><p><b className="bullet">2.</b>Diligencie sus datos completos y recuerde que cada campo es obligatorio.
                </p><p><b className="bullet">3.</b>Si lo cree necesario podrá utilizar los filtros que permitirán escoger los sabedorxs / expertxs que se acomoden a sus intereses.
                </p><p><b className="bullet">4.</b>Deberá aceptar la política de datos en la casilla de verificación inferior para generar el agendamiento.
                </p><p><b className="bullet">5.</b>Una vez tenga seleccionadas sus conversaciones, se le hará llegar un correo electrónico con la información de su agendamiento.
                </p><p><b className="bullet">6.</b>Recuerde que puede filtrar su búsqueda por Cuerpos o Regiones según le interese. Para conocer toda la información al respecto de los Cuerpos y las Regiones, puede ingresar al texto curatorial que encontrará en la página principal del sitio web.
                </p>
                <h3>Cómo inscribirse para la Escucha virtual</h3>
                <p>De no poder conversar presencialmente con sabedorxs / expertxs, puede escuchar el diálogo vía streaming. Para hacerlo es necesario registrarse previamente, siguiendo estas indicaciones:
                </p><p><b className="bullet">1.</b>Ingrese al tercer ícono Inscribirse para escucha virtual de la sección derecha de su pantalla y dé clic en él.
                </p><p><b className="bullet">2.</b>Diligencie sus datos completos y recuerde que cada campo es obligatorio.
                </p><p><b className="bullet">3.</b>Lea y acepte la política de datos para acceder a la escucha virtual de las conversaciones.
                </p><p><b className="bullet">4.</b>Una vez se encuentre registrado, le llegará un correo electrónico de confirmación. Al registrarse para la conversación virtual usted podrá enviar vía audio o por escrito sus preguntas o comentarios a los sabedorxs / expertxs que escoja. Esto es una herramienta exclusiva para aquellos que se registren para la escucha virtual.
                </p><p><b className="bullet">5.</b>Recuerde que para acceder a las conversaciones deberá dar clic en las mesetas del mapa o ingresar por el botón de Regiones del Menú principal. Cada una de las mesetas representa una conversación y al ubicarse sobre ellas con el cursor podrá conocer de qué sabedorx / expertx se trata y su temática. 
                </p><p>Para enviar sus preguntas o comentarios por escrito o en audio al sabedorx / expertx que usted está escuchando, deberá seguir los siguientes pasos:
                </p><p><b className="bullet">1.</b>Estar registrado para la escucha virtual. Sin este paso, usted no podrá hacerle llegar sus preguntas y comentarios al sabedorx / expertx.
                </p><p><b className="bullet">2.</b>Escoja la meseta del mapa interactivo para acceder a la conversación vía streaming y pulse el ícono del micrófono para grabar sus preguntas y/o comentarios. Una vez lo tenga listo haga clic en Enviar.
                </p><p><b className="bullet">3.</b>Para enviar su pregunta por escrito deberá hacer click en el cuadro del texto para diligenciar su pregunta y/o comentario y luego de ello pulse enviar.
                </p>
            </TextWrapper>
        </Wrapper>
    )
}

export default ComoNavegarPop;