import {React, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { EscuchaWrap, Feedback } from './style';
import { MenuItem } from '../../homeSections/Header/style';
import { get, ref, push, set, update } from '@firebase/database';
import { addDoc, doc, collection } from "@firebase/firestore"; 
import { getAuth, createUserWithEmailAndPassword } from '@firebase/auth';
import { database, firestoreDb } from '../../firebase/config';
import { uploadBytes, ref as storageRefFunction, getDownloadURL, listAll } from "firebase/storage";
import listaDeEventosLocal from '../../listaDeEventosUnique.js';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
// create a request to firebase that has {userId, cuerpoId, time}

const Escucha = props => {
    const [showFinalFeedback, setShowFinalFeedback] = useState(false);
    const [isFormValid, setFormValid] = useState(true);
    const [captcha, setCaptcha] = useState(null);
    const [isCaptchaValid, setCaptchaValid] = useState(false);
    const [isTermsChecked, setTermsChecked] = useState(false);
    const [currentExpertxsIndex, setCurrenExpertxs] = useState(0);
    const [cuerposArray, setCuerpos] = useState([]);
    const [timeSlotBookedIndex, setTimeSlotBookedIndex] = useState(null);
    const [currentCheckedEvent, setCurrentCheckedEvent] = useState({index1:null});
    const [currentCheckedEventPage, setCurrentCheckedEventPage] = useState(null);
    const [currentUserFormData, setCurrentUserFormData] = useState({name:'',email:'',tel:'',region:'',age:''});
  
    const onLoadRecaptcha=() =>{
        if (captcha) {
            captcha.reset();
        }
    }
    const verifyCallback=(recaptchaToken)=> {
      // Here you will get the final recaptchaToken!!!
      setCaptchaValid(recaptchaToken);
      console.log(recaptchaToken, "<= your recaptcha token")
    }
    const setCurrenExpertxsIndex = (val) => {
        val = currentExpertxsIndex + val;
        if(val > cuerposArray.length-1) {
            setCurrenExpertxs(0);
        } else if(val < 0) {
            setCurrenExpertxs(cuerposArray.length-1);
        } else {
            setCurrenExpertxs(val);
        }
        setCurrentCheckedEventPage(null);
        setCurrentCheckedEvent({index1:null});
    }
    const setEventForSubmit = (e, chat, indexLocal) => {
        e.preventDefault()
        for(let index in cuerposArray){
            if(cuerposArray[index].title === chat.title){
                setCurrentCheckedEvent({chat: chat, index1: index });
                setCurrentCheckedEventPage(indexLocal);
                console.log('event set', chat, index);
            }
        }
        setTimeSlotBookedIndex(null);
        // createEvent()
    }
    const toggleTerms = (e) => {
        setTermsChecked(!isTermsChecked)
    }
    const updateCuerposArray = (index, object) => {
        let newArray = cuerposArray;
        Object.keys(object).forEach(key => {
            newArray[index][key] = object[key]
        })
        setCuerpos(newArray);
    }
    useEffect(() => {
        console.log('useEffect', props)
        loadReCaptcha();
        if(props.expertxs) {
            setCuerpos(props.expertxs)
        } else {
            getExpertxs();
        }
    } , []);
    const setExpertxs = (expertxs) => {
        if(expertxs) {
            let arr = [];
            Object.keys(expertxs).forEach((key) => {
                arr.push(expertxs[key]);
            })
            setCuerpos(arr);
        }
    }
    const getExpertxs = () => {
        get(ref(database, 'chats'))
        .then(async (data) => {
            setExpertxs(data.val());
        });
    }
    const checkFormData = (e) => {
        let isValid = true;
        Object.keys(currentUserFormData).forEach(key => {
            if(currentUserFormData[key] === '') {
                console.log('invalid', key)
                isValid = false;
            }
        })
        if(!isTermsChecked) {
            console.log('terms not checked');
            isValid = false;
        }
        if(!isCaptchaValid) {
            console.log('isCaptchaValid', isCaptchaValid)
            isValid = false;
        }
        setFormValid(isValid);
        return isValid;
    }
    const sendAgendarRequest=(e)=>{
        e.preventDefault();
        if(!checkFormData()){
            console.log('form not valid');
            return;
        }
        let object = currentUserFormData;
        object['bookedOn'] = new Date().toLocaleString();

        console.log('current data',object, currentCheckedEvent)
        push(ref(database, `escuchaVirtual`),object)
        .then(async (data) => {
            console.log('res', data);
            getExpertxs();
            setShowFinalFeedback(true);
            addDoc(collection(firestoreDb, "mail"), {
                to: currentUserFormData.email,
                message: {
                    subject: "Confirmacion de agendado - A Contracorriente",
                    html: `<html>
                    <head>
                    <title>Mercado del conocimiento (comunicación) (Preview)</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <style>body{font-family:'Helvetica','Arial',sans-serif;margin:0;padding:32px 16px;background:#eeeeee;}h2{color:#111;font-size:24px;font-weight:bold;margin:0;}h3{color:#666;font-size:14px;font-weight:normal;margin:8px 0;}.email{max-width:1100px;margin:auto;}.email__creative{display:flex;justify-content:space-around;margin:30px 0 100px;}.email__frame{border:0;}.email__frame--small{width:320px;height:568px;border-radius:8px;}.email__frame--large{width:700px;height:700px;}.email__header{background:#fff;border-radius:4px;padding:16px;}.preview{padding-top:12px;padding-bottom:12px;border-top:1px solid #ddd;border-bottom:1px solid #ddd;margin-top:8px;}.preview__text{font-size:14px;}.preview__text--subject{color:#202124;}.preview__text--preheader{color:#5f6368;opacity:0.7;}
                    </style>
                    </head>
                    <body><div class="emails"><div class="email"><div class="email__header"><h2>Confirmacion de agendamiento - Escucha virtual</h2>
                    <table class="preview"><tr class="preview__row"><td class="preview__text preview__text--subject">Gracias ${currentUserFormData.name} por agendar con nosotros. Ingresa a la pagina y sigue las instrucciones.
                    </td><td class="preview__text preview__text--preheader">
                    </td></tr></table></div><div class="email__creative"><div class="email__col email__col--large"><h3></h3> <iframe class="email__frame email__frame--large" style="width:600px;height:1822px;" src="mail-a-copia/index.html"></iframe></div><div class="email__col email__col--small"><h3></h3> <iframe class="email__frame email__frame--small" src="mail-a-copia/index.html"></iframe></div></div></div></div>
                    </body>
                    </html>`
                },
            });
        });
      }
    const handleFormChanges = (e) => {
        setCurrentUserFormData({
            ...currentUserFormData,
            [e.target.id]: e.target.value
        })
        checkFormData();
    }
    const selectTime = (val) => {
        setTimeSlotBookedIndex(val);
    }
    return (
        <EscuchaWrap>
            <div className='popHeader'>
                <h2>Inscribirse para escucha virtual</h2>
                <button className="closePop" onClick={props.toggle}>X</button>
            </div>
            <div id='agendarBody'>
                <form>
                    <div className="line">
                    <div id='nombreWrap' className='inputWrap'>
                        <img src='media/users.svg' alt='icon-user' />
                        <input id='name' type='text' placeholder='Nombre completo*' onChange={handleFormChanges} />
                    </div>
                    </div><div className="line">
                    <div id='emailWrap' className='inputWrap'>
                        <img src='media/mail.svg' alt='icon-user' />
                        <input id='email' type='text' placeholder='Correo electrónico*' onChange={handleFormChanges}/>
                    </div>
                    <div id='telefonoWrap' className='inputWrap'>
                        <img src='media/phone.svg' alt='icon-user' />
                        <input id='tel' type='text' placeholder='Número telefónico*' onChange={handleFormChanges}/>
                    </div>
                    </div><div className="line">
                    <div id='lugarWrap' className='inputWrap'>
                        <img src='media/map-pin.svg' alt='icon-user' />
                        <input id='region' type='text' placeholder='Lugar de procedencia*' onChange={handleFormChanges}/>
                    </div>
                    <div id='edadWrap' className='inputWrap'>
                        <img src='media/gift.svg' alt='icon-user' />
                        <input id='age' type='text' placeholder='Edad*' onChange={handleFormChanges}/>
                    </div>
                    </div>
                    <div id='termsAndConditionsWrap'>
                        {isTermsChecked ? (
                            <img src='media/selected_chat.svg' alt='icon-check' onClick={toggleTerms}/>
                        ) : (
                            <img src='media/unselected_chat.svg' alt='icon-check' onClick={toggleTerms}/>
                        )}
                            <span>He leído y acepto la Política de uso de datos</span>
                    </div>
                    <div id='submitWrap'>
                    <ReCaptcha
                        ref={(el) => {setCaptcha(el);}}
                        size="normal"
                        render="explicit"
                        sitekey="6LcGjUgdAAAAALlaAYawYKFJxMtf6gGdRghkEyPy"
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={verifyCallback}
                    />
                        <button type='submit' onClick={sendAgendarRequest}><img src="media/icons/radio.svg" alt="" />Inscribirse</button>
                        {isFormValid ? ('') : ('Formulario invalido')}
                    </div>
                    {showFinalFeedback? (
                    <Feedback>
                        <div id='feedbackWrapTop'>
                            <div id='feedbackWrapTopLeft'></div>
                            <div id='feedbackWrapTopRight' onClick={(e)=>{
                                setShowFinalFeedback(false)
                                props.toggle(e)
                                }}>X</div>
                            <div id='header'>
                                Te has inscrito correctamente a la conversación virtual.
                            </div>
                        </div>
                        <div style={{textAlign:'center', lineHeight:'1.5rem'}} id='feedbackText'>    
                            El 02 de diciembre podrás navegar el mapa de la página web y escoger la mesa de tu preferencia.
                        </div>
                        <div id='feedbackButtons'>
                            <div className='feedbackButtonOrange' onClick={(e)=>{
                                setShowFinalFeedback(false)
                                props.toggle(e)
                                }}>
                                <div>ACEPTAR</div>
                            </div>
                        </div>
                    </Feedback>
                ) : ('')}
                </form>
            </div>
        </EscuchaWrap>
        
       )
}

export default Escucha
