import styled from "styled-components";
export const Feedback = styled.div`
#feedbackText img{
    margin-right: 10px;
}
position: fixed;
width: 380px;
height: 302px;
top: 25%;
box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
background-color: var(--bgWhite);
border-radius: 5px;
padding: 25px 40px;

@media (max-width: 900px) {
    width: 85vw;
    left: 5vw;
    top: 15vh;
}

#header{
    color:#F26100;
    text-align:center;
    font-size:24px;
}
#feedbackWrapTopRight{
    margin-right: 10px;
    margin-left: auto;
    text-align: right;
    margin-bottom: 10px;
    font-weight: bold;
}
#feedbackButtons{
    display: grid;
    margin: 30px auto 10px auto;
    text-align: center;
    grid-template-columns: 1fr ;
}
.feedbackButtonOrange{
    border: 1px solid var(--orange);
    width: 137px;
    margin: 0px auto;
    border-radius: 5px;
    display: block; 
    padding: 5px 20px;
    color: var(--orange);
}
.buttonOrange>img{
    margin: 0px auto auto;
}
#feedbackText{
    margin-top: 20px;
}
`;

export const AgendarWrap = styled.div`
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 5vh;
    left:20vw;
    width: 40vw;
    height: max-content;
    padding: 2vh 2vw;
    color: var(--textBlack);
    background-color: var(--bgWhite);
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    @media (max-width: 900px) {
      width: 85vw;
      left: 5vw;
      top: 5vh;
      z-index: 101;
    }

    .eventoItemWrap{
        display: grid;
        grid-template-columns: 10% 90%;
        padding: 15px 0px;
    }
    .eventoInfoWrap{
        line-height: 1.5;
    }
    #confirmationText{
        text-align: left;
        margin: 50px  auto;
    }
    #confirmationText img{
        margin-right: 10px;
    }
    #confirmationWrap{
        position: fixed;
        width: 380px;
        height: 302px;
        top: 25%;
        box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
        background-color: var(--bgWhite);
        border-radius: 5px;
        padding: 25px 40px;
    }
    #confirmationWrapTopRight{
        margin-right: 10px;
        margin-left: auto;
        text-align: right;
        margin-bottom: 10px;
        font-weight: bold;
    }
    #confirmationButtons{
        display: grid;
        margin: 100px auto 10px auto;

        grid-template-columns: 1fr 1fr;
    }
    .buttonOrange{
        border: 1px solid var(--orange);
        width: 137px;
        margin: 0px auto;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 15% 75%;
        padding: 5px 20px;
        color: var(--orange);
    }
    .buttonOrange>img{
        margin: 0px auto auto;
    }
    .filterItemWrap{
        display: grid;
        grid-template-columns: 10% 90%;
    }
    #filtro{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        margin: 0 auto 30px;
        border-top: 1px solid #ccc;

        @media (max-width: 900px) {
            grid-template-columns: 1fr;
        }

    }

    .filtroCat{
        padding: 10px 5px;
        img {
            padding: 0 10px;
        }
    }
    #filtroCuerpo{
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }

    #filtroRegion{
        border-bottom: 1px solid #ccc;
        padding-left: 20px;
    }

    .filtroCuerpoLista{
        padding: 10px 5px;
    }

    h2{
        font-size: 1.2em;
        font-weight: 900;
        color: var(--titleBlack);
    }


    .line{
        border-bottom: 1px solid var(--textGray);
        line-height: 2em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 900px) {
            flex-direction: column;
            border: none;
        }
    }

    input{
        background: none;
        border: none;
        line-height: 3em;
        display: inline;
        padding: 1vh 2vw;
        outline: none;
        font-size: 1.1em;

    }

    .inputWrap {
        width: 90%;
        @media (max-width: 900px) {
            border-bottom:1px solid var(--textGray);
        }
    }

    #barrancaWrap{
        padding: 1vh 2vw;
        display: flex;
        gap: 1vw;
        font-family: Source Sans Pro;
        font-size: 1.1em;
        font-style: normal;
        font-weight: 600;
        line-height: 1.2em;
        letter-spacing: 0em;
        text-align: left;
        color: var(--orange);
        b {
            font-weight: 900;
        }
    }

    #termsAndConditionsWrap {
        padding: 2vh 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            padding-right: 1vw;
        }
        text-transform: uppercase;
        font-weight: 600;
        font-size: .8em;
        color: var(--textGray);
    }

    #submitWrap{
        padding: 2vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;

        button{
            border: 1px solid var(--orange);
            border-radius: 5px;
            background: none;
            padding: .7em;
            color: var(--orange);
            font: 900 .95em "Source Sans Pro";
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
    }
`;
export const AgendarListaWrap = styled.div`
    display: block;
    // grid-template-columns: 90% 10%;
    border-top: 2px solid var(--textBlack);
    border-bottom: 2px solid var(--textBlack);
    margin-top: 10px;
    #agendarListaDeEventos{
        overflow-y: scroll;
        height: calc(100vh - 100px);
    }
    #agendarNav{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    #agendarNavBottom{
        margin: auto auto 10px;
    }
    #agendarNavTop{
        margin: 10px auto auto;
    }
    .check{
        margin: 10px auto auto;
        width: min-content;
    }
`;



